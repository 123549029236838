import React from 'react'
import { Link, SimpleCell } from '@vkontakte/vkui'

import './CellProfileStats.css'

const Index = ({ data, selectedStat, isMyProfile }) => {

  const { text, info, count, dontShowBtn = false, showModal } = data

  const after = dontShowBtn || ( count === 0 && text !== 'Партнерка' ) || ( !isMyProfile && text === 'Партнерка')
    ? null
    : <Link onClick={ () => selectedStat(showModal, info)}>Показать</Link>

  return (
    <SimpleCell className='CellProfileStats__HeaderText' disabled after={after}>
      <span className='CellProfileStats__HeaderText__Text'>{text}</span>
      <span className='CellProfileStats__HeaderText__Count'>{count}</span>
    </SimpleCell>
  )
}

export default Index
