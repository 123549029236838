import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import profileReducer from "./reducers/profile-reducer";
import tutorsReducer from "./reducers/tutors-reducer";
import chatsReducer from "./reducers/chats-reducer";
import viewsReducer from "./reducers/views-reducer";
import mainReducer from "./reducers/main-reducer";
import requestsReducer from "./reducers/requests-reducer";
import thunk from "redux-thunk";
import thunkMidleware from "redux-thunk"
import globalReducer from "./reducers/global-reducer";

let reducers = combineReducers({
  viewsReducer,
  chatsReducer,
  mainReducer,
  profileReducer,
  tutorsReducer,
  requestsReducer,
  globalReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers,composeEnhancers(applyMiddleware(thunk, thunkMidleware)))

export default store