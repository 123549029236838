import React from 'react'
import { Tabbar, TabbarItem } from '@vkontakte/vkui'

import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../redux/reducers/views-reducer";
import VIEWS from "../panels/panels";
import {getCurrentUserRole} from "../redux/selectors/profile-selectors";
import {getMyTabs} from "../redux/selectors/view-selectors";

function MyTabBar ({ activeStory }) {

  const dispatch = useDispatch()

  const currentUserRole = useSelector(getCurrentUserRole)

  const myTabs = useSelector(getMyTabs)

  return (
    myTabs && <Tabbar>
      {myTabs.filter(tab => tab.role.includes(currentUserRole)).map(tab => (
        <TabbarItem key={tab.name} data-story={tab.name} onClick={() => dispatch(setActivePanel(VIEWS.EPIC.ID,tab.name))}
                    selected={activeStory === tab.name}>
          {tab.icon}
        </TabbarItem>
      ))}
    </Tabbar>
  )
}

export default MyTabBar
