const createProfileItems = (items, profile) => {
    return items.map( (item) => {
      switch(item.text) {
        case 'Проконсультировал':  return { ...item, count: profile.consultations }
        case 'Категории':  return profile.categories && { ...item, info: profile.categories, count: profile.categories.length}
        case 'Отзывы':  return profile.reviews && { ...item, info: profile.reviews, count: profile.reviews.length}
        case 'Партнерка':  return profile.affiliate && { ...item, info: profile.affiliate, count: profile.affiliate.invitedUsers.length}
        case 'Примеры работ':  return profile.portfolio && { ...item, info: profile.portfolio, count: profile.portfolio.length}
        case 'Видеоуроки':  return profile.video && { ...item, info: profile.video, count: profile.video.length}
        case 'Заявки':  return profile.requests && { ...item, info: profile.requests, count: profile.requests.length}
        default: break
      }
    }
  )
}


export default createProfileItems