import VIEWS from "../../panels/panels"
import {Spinner} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";
import React from "react";
import Icon28HomeOutline from "@vkontakte/icons/dist/28/home_outline";
import ROLES from "../../roles";
import Icon28UsersOutline from "@vkontakte/icons/dist/28/users_outline";
import Icon28TargetOutline from "@vkontakte/icons/dist/28/target_outline";
import Icon28MessageOutline from "@vkontakte/icons/dist/28/message_outline";
import Icon28UserCircleOutline from "@vkontakte/icons/dist/28/user_circle_outline";

export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL'
const SET_HISTORY = 'SET_HISTORY'

const SET_MAIN_HISTORY = 'SET_MAIN_HISTORY'
const DELETE_LAST_HISTORY = 'DELETE_LAST_HISTORY'

const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
const SET_MODAL_HISTORY = 'SET_MODAL_HISTORY'
const SET_MODAL_PAYLOAD = 'SET_MODAL_PAYLOAD'

const SET_POPOUT = "SET_POPOUT"
const SET_PARAMS = "SET_PARAMS"

const SET_VIEW_ID = "SET_VIEW_ID"

const RETURN_MY_TABS = "RETURN_MY_TABS"
const SET_MY_TABS = "SET_MY_TABS"

export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE"

export const SHOW_MESSAGE = "SHOW_MESSAGE"

const tabs = [
  { name: 'main', icon: <Icon28HomeOutline/>, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN] },
  { name: 'relationship', icon: <Icon28UsersOutline/>, role: [ROLES.USER, ROLES.USER_ADMIN] },
  { name: 'requests', icon: <Icon28TargetOutline/>, role: [ROLES.CONSULTANT, ROLES.CONSULTANT_ADMIN] },
  { name: 'chat', icon: <Icon28MessageOutline/>, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN] },
  // { name: 'useful', icon: <Icon28CubeBoxOutline/>, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN] },
  { name: 'profile', icon: <Icon28UserCircleOutline/>, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN] }
]

const initialState = {
  epic: {
    activePanel: '',
    history: [],
  },
  main: {
    activePanel: VIEWS.MAIN.PANELS.HOME,
    history: [VIEWS.MAIN.PANELS.HOME],
  },
  tutors: {
    activePanel: VIEWS.TUTORS.PANELS.CATEGORIES,
    history: [VIEWS.TUTORS.PANELS.CATEGORIES],
  },
  requests: {
    activePanel: VIEWS.REQUESTS.PANELS.REQUESTS_LIST,
    history: [VIEWS.REQUESTS.PANELS.REQUESTS_LIST],
  },
  chat: {
    activePanel: VIEWS.CHAT.PANELS.DIALOG_LIST,
    history: [VIEWS.CHAT.PANELS.DIALOG_LIST],
  },
  profile: {
    activePanel: VIEWS.PROFILE.PANELS.PROFILE_INFO,
    history: [VIEWS.PROFILE.PANELS.PROFILE_INFO],
  },
  modal: {
    activeModal: null,
    modalHistory: [],
    payload: null
  },
  popout: <Spinner size='small'/>,
  params: {},
  viewId: VIEWS.EPIC.ID,
  newMessage: null,
  myTabs: tabs
}

const viewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PANEL:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          activePanel: action.panel
        }
      }
    case SET_HISTORY:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          history: [...state[action.name].history, action.panel],
        }
      }
    case SET_MAIN_HISTORY:
      return {
        ...state,
        main: {
          ...state.main,
          history: [...state.main.history, action.panel],
        }
      }
    case DELETE_LAST_HISTORY:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          history: [...state[action.name].history.slice(0,-1)],
        }
      }
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          activeModal: action.activeModal
        }
      }
    case SET_MODAL_HISTORY:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalHistory: action.modalHistory
        }
      }
    case SET_MODAL_PAYLOAD:
      return {
        ...state,
        modal: {
          ...state.modal,
          payload: action.payload
        }
      }
    case SET_POPOUT:
      return { ...state, popout: action.popout }
    case SET_PARAMS:
      return { ...state, params: action.params }
    case SET_VIEW_ID:
      return { ...state, viewId: action.viewId }
    case SET_NEW_MESSAGE:
      return { ...state, newMessage: action.newMessage }
    case RETURN_MY_TABS:
      return { ...state, myTabs: tabs }
    case SET_MY_TABS:
      return { ...state, myTabs: action.myTabs }
    default:
      return state
  }
}

export const setActivePanel = (name, panel) => ({ type: SET_ACTIVE_PANEL, name, panel })
export const setHistory = (name, panel) => ({type: SET_HISTORY, name, panel})
export const deleteLastPanelOfHistory = (name) => ({type: DELETE_LAST_HISTORY, name})

export const setActiveModal = (activeModal) => ({ type: SET_ACTIVE_MODAL, activeModal })
export const setModalHistory = (modalHistory) => ({ type: SET_MODAL_HISTORY, modalHistory })
export const setModalPayload = (payload) => ({ type: SET_MODAL_PAYLOAD, payload })

export const setPopout = (popout) => ({ type: SET_POPOUT, popout })

export const setParams = (params) => ({ type: SET_PARAMS, params })

export const setViewId = (viewId) => ({ type: SET_VIEW_ID, viewId })

export const setMyTabs = (myTabs) => ({ type: SET_MY_TABS, myTabs })

export const returnMyTabs = () => ({ type: RETURN_MY_TABS })

export const setNewMessage = (newMessage) => ({ type: SET_NEW_MESSAGE, newMessage })

export const goToPanel = (name, panel) => (dispatch) => {
  dispatch(setActivePanel(name, panel))
  dispatch(setHistory(name, panel))
}

export const goBackPanel = (name, panel, length) => (dispatch) => {
  if (length === 1) {
    bridge.send('VKWebAppDisableSwipeBack')
  } else if (length > 1) {
    dispatch(setActivePanel(name, panel))
    dispatch(deleteLastPanelOfHistory(name))
  }
}

export const showMessage = () => {
  return {
    type: SHOW_MESSAGE,
  }
}

export const openModalThunk = (modalName, data, modalHistory) => (dispatch) => {

  modalName = modalName || null

  let history = modalHistory ? [...modalHistory] : []

  if (modalName === null) {
    history = []
  } else if (!modalHistory.includes(modalName)) {
    history = modalHistory.splice(0, modalHistory.indexOf(modalName) + 1)
  } else {
    modalHistory.push(modalName)
  }

  if (data !== null) {
    dispatch(setModalPayload(data))
  }

  dispatch(setActiveModal(modalName))
  dispatch(setModalHistory(history))
}


export default viewsReducer