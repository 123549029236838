import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline'

import './TermsCooperation.css'

const Index = ({ id, onClose }) => {
  return (
    <ModalCard
      id={id}
      onClose={onClose}
      icon={<Icon28InfoOutline width={56} height={56} />}
      actions={[
        <Button key={'ok'} mode='primary' onClick={() => onClose()}>Понятно</Button>
      ]}
    >
      <div className='TermsCooperation-Title'>
        Условия сотрудничества
      </div>
      <div className='TermsCooperation-Text'>
        Запрещено обмениваться личными контактам
        ( ссылками, номерами телефона и т.п. ), оплата
        производится на карту непосредственно консультанту,
        в целях контроля качества - администрация в праве просмотреть данный диалог.
      </div>
    </ModalCard>
  )
}

export default Index
