import React from 'react'
import { Banner, Button } from '@vkontakte/vkui'

import './BannerItem.css'
import VIEWS from "../../panels/panels";
import {goToPanel, setActivePanel} from "../../redux/reducers/views-reducer";
import {useDispatch, useSelector} from "react-redux";
import ROLES from "../../roles";
import {getCurrentUserRole} from "../../redux/selectors/profile-selectors";

const colors = {
  blue: '#67A5EB',
  pink: '#E6457A',
  aquamarine: '#63B9BA',
  slateblue: '#5965B3'
}
const defaultBanners = [
  {
    id: 1,
    type: 'Default',
    role: ROLES.ALL,
    header: 'Оставьте отзыв',
    subheader: `Кажется вы можете оставить отзыв о пользвателе, это супер полезно и мы будем этому рады`,
    asideMode: { title: 'expand', views: { epic: VIEWS.MAIN.ID, panel: VIEWS.MAIN.PANELS.ALL_FEEDBACK } },
  },
  {
    id: 2,
    type: 'Color',
    role: [ROLES.USER, ROLES.USER_ADMIN],
    views: {
      epic: VIEWS.EPIC.PANELS.RELATIONSHIP,
    },
    header: 'Помощь студентам',
    subheader: `Консультации и помощь по различным предметам от топовых ребят из нашего сервиса`,
    actions: 'Перейти'
  },
]

const Index = (props) => {
  const { id, size = 'm', color = null } = props

  const dispatch = useDispatch()

  const currentUserRole = useSelector(getCurrentUserRole)

  const { views, type, header, subheader, image = null, actions, role, asideMode } = defaultBanners.find(banner => banner.id === id)

  const asideModeGo = () => {
    asideMode.views.panel && dispatch(goToPanel(asideMode.views.epic, asideMode.views.panel))
  }

  return (
    role.includes(currentUserRole) && <Banner
      style={{ marginTop: 0, marginBottom: 0 }}
      id={id}
      className={`BannerItem_type${type}`}
      mode={type !== 'Default' ? 'image' : 'tint'}
      size={size}
      header={header}
      subheader={subheader}
      asideMode={asideMode && asideMode.title}
      onClick={() => asideMode && asideModeGo(asideMode.views)}
      background={
        <div style={{ background: image !== null
            ? `no-repeat center/cover url(/img/main/banner/${image})`
            : type === 'Color' && color !== null
              ? colors[color]
              : null }}
        />
      }
      actions={actions &&
        <Button
          className='BannerButton'
          mode={type !== 'Default' ? 'overlay_primary' : 'primary'}
          size={'s'}
          onClick={() => views.epic ? dispatch(setActivePanel(VIEWS.EPIC.ID, views.epic)) : dispatch(goToPanel(VIEWS.MAIN.ID, views.panel))}>
          {actions}
        </Button>}

    />
  )
}

export default Index
