import 'core-js/features/map'
import 'core-js/features/set'

import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from "react-redux";

import store from "./redux/store";
import App from './App'
import "@vkontakte/vkui/dist/vkui.css";

import bridge from '@vkontakte/vk-bridge'
import {AdaptivityProvider, AppRoot, ConfigProvider, SplitCol, SplitLayout} from "@vkontakte/vkui";

// Init VK  Mini App
bridge.send('VKWebAppInit', {})

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <AdaptivityProvider>
        <AppRoot>
          <SplitLayout>
            <SplitCol>
              <App />
            </SplitCol>
          </SplitLayout>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>

  </Provider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV === 'development') {
  import('./eruda').then(({ default: eruda }) => {}) //runtime download
}