import React from 'react'
import { Panel, CardScroll, Card, PanelHeader, Header, Group } from '@vkontakte/vkui'

// import BannerItem from '../../../components/BannerItem'
import VideoBanner from  '../../../components/VideoBanner'
// import VIEWS from '../../panels'

const Index = ({ id, header, changePanel }) => {

  return (
    <Panel id={id}>
      <PanelHeader>{header}</PanelHeader>

      {/*<Group>*/}
      {/*  <Header subtitle='Проверь себя'>*/}
      {/*    ТЕСТЫ*/}
      {/*  </Header>*/}

      {/*  <CardScroll className='CardBanner'>*/}
      {/*    <Card size='s'>*/}
      {/*      <BannerItem id={2} color='blue' goBanner={() => changePanel(VIEWS.USEFUL.PANELS.TEST)} />*/}
      {/*    </Card>*/}

      {/*    <Card size='s'>*/}
      {/*      <BannerItem id={3} color='pink' goBanner={() => changePanel(VIEWS.USEFUL.PANELS.TEST)} />*/}
      {/*    </Card>*/}
      {/*  </CardScroll>*/}
      {/*</Group>*/}

      <Group>
        <Header subtitle='Бесплатные уроки для тебя'>
          ВИДЕОУРОКИ
        </Header>

        <CardScroll className='CardBanner'>
          <Card size='s'>
            <VideoBanner id={4} url='2Mr1uIAh4jg' />
          </Card>

          <Card size='s'>
            <VideoBanner id={5} url='bLBfYBnBvXc' />
          </Card>
        </CardScroll>
      </Group>

      {/*<Group>*/}
      {/*  <Header subtitle='От математики до сопромата'>*/}
      {/*    КАЛЬКУЛЯТОРЫ*/}
      {/*  </Header>*/}

      {/*  <CardScroll className='CardBanner'>*/}
      {/*    <Card size='s'>*/}
      {/*      <BannerItem id={6} color='aquamarine' goBanner={() => changePanel(VIEWS.USEFUL.PANELS.CALC)} />*/}
      {/*    </Card>*/}

      {/*    <Card size='s'>*/}
      {/*      <BannerItem id={7} color='slateblue' goBanner={() => changePanel(VIEWS.USEFUL.PANELS.CALC)} />*/}
      {/*    </Card>*/}
      {/*  </CardScroll>*/}
      {/*</Group>*/}
    </Panel>
  )
}

export default Index
