import React  from 'react'
import { ModalPageHeader, ANDROID, PanelHeaderButton, IOS, platform } from '@vkontakte/vkui'

import Icon24Cancel  from '@vkontakte/icons/dist/24/cancel'
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss'

const osName = platform()

const ModalHeader = ({ title, modalBack }) => (
  <ModalPageHeader
    left={osName === ANDROID && <PanelHeaderButton onClick={() => modalBack()}><Icon24Cancel/></PanelHeaderButton>}
    right={osName === IOS && <PanelHeaderButton onClick={() => modalBack()}><Icon24Dismiss /></PanelHeaderButton>}
  >
    {title}
  </ModalPageHeader>
)

export default ModalHeader
