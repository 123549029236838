import React from 'react'

import {Button, Panel, Div, PanelHeader, PanelHeaderBack} from '@vkontakte/vkui'
import HeaderPanel from "../../../components/HeaderPanel";
import { Icon28StoryAddOutline } from '@vkontakte/icons';

import bridge from "@vkontakte/vk-bridge";


import './TestResult.css'
import VIEWS from "../../panels";
import {useDispatch} from "react-redux";
import {goToPanel} from "../../../redux/reducers/views-reducer";
import {APP_ID} from "../../../api/api";

const TestResult = ({ id, header}) => {

  const addHistory = () => {
    bridge.send("VKWebAppShowStoryBox",
    //     {
    //   "background_type": "image",
    //   "url": "https://sun9-65.userapi.com/c850136/v850136098/1b77eb/0YK6suXkY24.jpg",
    // },
    {
      background_type: 'image',
      url: "https://sun9-21.userapi.com/impg/iQw3_vaU3_sVYFox29eWlKUINkylRMikvGq_CA/Us3Zgi6TiLg.jpg?size=1623x2160&quality=96&sign=8e201c837251ac43d845b8a2075fbf62&type=album",
      locked: true,
      stickers: [
        {
          sticker_type: 'renderable',
          sticker: {
            can_delete: false,
            content_type: 'image',
            url: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Dialog.png',
            clickable_zones: [
              {
                action_type: 'link',
                action: {
                  link: `https://vk.com/app${APP_ID}`,
                  tooltip_text_key: 'tooltip_open_default',
                },
                clickable_area: [
                  {
                    x: -3000,
                    y: -3000,
                  },
                  {
                    x: 3000,
                    y: -3000,
                  },
                  {
                    x: 3000,
                    y: 3000,
                  },
                  {
                    x: -3000,
                    y: 3000,
                  },
                ],
              },
            ],
          },
        },
      ],
    }
    );
  }

  const dispatch = useDispatch()

  return (
    <Panel id={id}>

      <PanelHeader left={<PanelHeaderBack onClick={() => dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.HOME))}/>}>
        {header}
      </PanelHeader>
      <Div className={'TestResult__info'}>
        <Div>
          19/20
        </Div>
        <Div>
          У вас отличный результат
        </Div>
        <Div>
          <Button onClick={addHistory} before={<Icon28StoryAddOutline width={24} height={24}/>} size="m">Поделиться в сторис</Button>
        </Div>
      </Div>

    </Panel>
  )
}

export default TestResult
