import React, {useEffect, useState} from 'react'
import {Div, FormLayout, Cell, Select} from '@vkontakte/vkui'

import Api, {BASE_URL} from '../../../api/api'
import moment from 'moment'
import AudioTrack from "../../../components/AudioTrack/AudioTrack";

import Icon24Document from '@vkontakte/icons/dist/24/document';
import AvatarOfLetter from "../../../components/AvatarOfLetter/AvatarOfLetter";
import showNativeGallery from "../../../lib/bridgeUtilits/showNativeGallery";
import Icon24Copy from "@vkontakte/icons/dist/24/copy";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../../redux/selectors/profile-selectors";
import ROLES from "../../../roles";
import bridgeCopyText from "../../../lib/bridgeUtilits/bridgeCopyText";

const DialogMessageItem = ({ message, currentUserId, chatProfileUser, chatProfileUserId }) => {

  const { id, datetime, text, files, sticker, type } = message

  const dispatch = useDispatch()

  const isCurrentUserMessage = currentUserId === message.sendUserId

  const [ srcAvatar, setSrcAvatar ] = useState('')

  useEffect(() => {
    chatProfileUser.avatar && setSrcAvatar(Api.avatars.getUrlByImgName(chatProfileUser.avatar.img))
  },[])

  const messageClasses = `MessageItem ${isCurrentUserMessage ? 'Me' : ''}`

  const filterImg = ( array ) => {
    return array.split(', ').filter(file => ['jpg', 'png', 'jpeg', 'JPG'].includes(file.split('.')[1]))
  }

  const filterDoc = ( array ) => {
    return array.split(', ').filter(file => {
      return !['jpg', 'png', 'jpeg', 'JPG'].includes(file.split('.')[1])
    })
  }

  return (
    <div className={messageClasses}>
      { !isCurrentUserMessage && (
        <div className='MessageAvatar'>
          <AvatarOfLetter id={chatProfileUserId} srcAvatar={srcAvatar} name={chatProfileUser.nickname} size={32}/>
        </div>
      )}

      <div className='MessageBody'>

        { text && type === 'text' && <div className='MessageText'>{text}</div> }
        { text && type === 'paid' && <Bill chatProfileUser={chatProfileUser} sum={text} isCurrentUserMessage={isCurrentUserMessage} dispatch={dispatch}/> }
        { sticker && <img className='MessageSticker' key={sticker} src={`https://vk.com/sticker/1-${sticker}-512b`} alt={sticker}/> }

        { files && files.includes('audio.webm') && <AudioTrack id={id} file={files}/>}

        { files && filterDoc(files).length !== 0 && !files.includes('audio.webm') && filterDoc(files).map( (file, i) => <FileDocument key={i} file={file}/>) }
        { files && filterImg(files).length !== 0 && !files.includes('audio.webm') && filterImg(files).map( (image, i) => <FileImage key={i} image={image}/>) }

        { ((files && files.includes('audio.webm')) || (!files && text)) && <MessageDate type={'MessageDate'} datetime={datetime}/> }
        { ((files && ( filterImg(files).length !== 0)) || sticker) && <MessageDate type={'MessageDateImg'} datetime={datetime}/> }
        { files && filterDoc(files).length !== 0 && !files.includes('audio.webm') && filterImg(files).length === 0 && <MessageDate type={'MessageDateDoctype'} datetime={datetime}/> }

      </div>
    </div>
  )
}

const MessageDate = ({type, datetime}) => {
  return (
    <div className={type}>{moment(datetime).format('LT')}</div>
  )
}

const Bill = ({chatProfileUser, sum, isCurrentUserMessage, dispatch}) => {

  const currentUser = useSelector(getCurrentUser)

  const methodPaid = chatProfileUser.role === ROLES.CONSULTANT ? chatProfileUser.methodPaid : currentUser.methodPaid
  const [ firstMethodPay, setFirstMethodPay ] = useState(methodPaid[0])

  const changeMethodPay = (e) => {
    setFirstMethodPay({name: methodPaid.filter( i => i.number === e.target.value)[0].name, number: e.target.value})
  }

  return (
    <div className='MessageBill'>Счет к оплате: {sum} ₽
      {
        !isCurrentUserMessage ? <Cell after={<Icon24Copy fill={'var(--text_link)'} onClick={() => bridgeCopyText(firstMethodPay.number, dispatch)}/>}>
          <FormLayout>
            <Select top={firstMethodPay.name} value={firstMethodPay.number} placeholder="Выберите способ оплаты" onChange={e => changeMethodPay(e)}>
              { methodPaid && methodPaid.map( (card, i) => <option value={card.number} key={i}>{card.number}</option>)}
            </Select>
          </FormLayout>
        </Cell> : <Div>Способы оплаты отправлены заказчику</Div>
      }
    </div>
  )
}

const FileDocument = ({file}) => {
  const fileName = file.slice(file.indexOf("/") + 1,file.lastIndexOf(".")).slice(0,15)
  const fileType = file.substr(file.lastIndexOf("."))
  return (
    <button>
      <a href={`${BASE_URL}/file/${file}`} target='_blank' rel='noopener noreferrer'>
        <Icon24Document width={20} height={20} fill={'var(--accent)'}/>
        <span className='MessageTextFile'>{fileName + fileType}</span>
      </a>
    </button>
  )
}

const FileImage = ({image}) => {
  return (
    <img onClick={() => { showNativeGallery([`${BASE_URL}/file/${image}`])} }
         src={`${BASE_URL}/file/${image}`}
         alt={image}/>
  )
}

export default DialogMessageItem
