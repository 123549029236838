import React, { useState }  from 'react'
import YouTube from 'react-youtube'

import BannerItem from '../BannerItem'
import './VideoBanner.css'

const Index = ({ id, url }) => {
  const [target, setTarget] = useState(null)

  const opts = {
    height: '350',
    width: '350',
    playerVars: {
      autoplay: 0,
    },
  }

  const _onReady = (event) => {
    setTarget(event.target)
  }

  const playVideo = () => {
    target.playVideo()
  }

  return (
    <>
      <BannerItem id={id} goBanner={playVideo} />
      <YouTube className='VideoBanner_Youtube' videoId={url} opts={opts} onReady={_onReady} />
    </>
  )
}

export default Index
