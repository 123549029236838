import React, {useEffect, useState} from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'

import './CreateChat.css'
import {useDispatch, useSelector} from "react-redux"
import bridge from "@vkontakte/vk-bridge"
import {setIsShowModalProfile, setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import AvatarOfLetter from "../../../components/AvatarOfLetter/AvatarOfLetter";
import Api from "../../../api/api";
import {getCurrentUserId, getCurrentUserVkId} from "../../../redux/selectors/profile-selectors";

const Index = ({ id, onClose, data }) => {

  const { profile } = data

  const currentUserId = useSelector(getCurrentUserId)
  const currentUserVkId = useSelector(getCurrentUserVkId)

  const [ srcAvatar, setSrcAvatar ] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
      setSrcAvatar(Api.avatars.getUrlByImgName(profile.avatar.img))
  },[profile, currentUserId, currentUserVkId])

  const onCloseLocal = () => {
    onClose()
    dispatch(setIsShowModalProfile(id))
  }

  const createChat = () => {
    onCloseLocal()
    Api.dialogs.createChat(currentUserId, profile.id).then( rMain => {

      const idChat = rMain.data.success ? +rMain.data.success.idGroup : null
      const idTunnel = rMain.data.success ? +rMain.data.success.idTunnel : null

      idChat && bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": idChat, "key": "dBuBKe1kFcdemzB"}).then(r => {
        Api.dialogs.sendMessageInOffice(currentUserId, idTunnel,'Начните чат').then(response => {
            r.result && dispatch(setSnackbar(<MySnackbar message={'Нажмите чтобы перейти в чат'} link={`https://vk.com/im?sel=-${idChat}`}/>))
          })
        Api.dialogs.sendMessageInOffice(profile.id, idTunnel,'👔 Заказчик зашел в чат. Начните диалог')
      }).catch(r => {
          dispatch(setSnackbar(<MySnackbar message={'Мы не сможем с вами связаться если вы не разрешите отправку сообщений'}/>))
        })
      !idChat && dispatch(setSnackbar(<MySnackbar message={JSON.stringify(rMain.data)}/>))
    }).catch( r => dispatch(setSnackbar(<MySnackbar message={'Что-то пошло не так, попробуйте еще раз или напишите админу'}/>)))

  }

  return (
    <ModalCard
      id={id}
      onClose={onCloseLocal}
      header={profile.nickname}
      subheader={<span>Консультант/репетитор</span>}
      icon={<AvatarOfLetter id={profile.id} srcAvatar={srcAvatar} name={profile.nickname} size={56} online={profile.online}/>}
      actions={[
        <Button key={'cancel'} mode='secondary' onClick={() => onCloseLocal()}>Отмена</Button>,
        <Button key={'createChat'} mode='primary' onClick={() => createChat()}>Связаться</Button>
      ]}
    >
      <div className='ModalCardCreateChat-Title'>
        {/*Чтобы связаться с консультантом, вам нужно разрешить отправку сообщений, чтобы мы могли вам написать*/}
      </div>
      <div className='ModalCardCreateChat-Text'>
        Нужно будет разрешить отправку сообщений, чтобы мы могли вам написать
      </div>
    </ModalCard>
  )
}

export default Index
