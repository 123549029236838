import bridge from "@vkontakte/vk-bridge";

const showNativeGallery = (arraySrc, index = null) => {

  bridge
    .send("VKWebAppShowImages", {
      images: arraySrc,
      start_index: index
    }).catch(response => {
      arraySrc.forEach((url) => {
        window.open(url)
      })
    })
}

export default showNativeGallery