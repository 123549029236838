import React from "react";
import MyMiniSpinner from "../../components/MyMiniSpinner/MyMiniSpinner";
import Api from "../../api/api";

const SET_SELECT_REQUEST = "SET_SELECT_REQUEST"
const SET_POPOUT_REQUESTS = "SET_POPOUT_REQUESTS"
const SET_TASKS = "SET_TASKS";
const SET_NEW_TASKS = "SET_NEW_TASKS";

let initialState = {
  tasks: [],
  selectRequest: null,
  requestsPagePopout: <MyMiniSpinner/>
}


const tutorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS:
      return { ...state, tasks: [...state.tasks , ...action.tasks] }
    case SET_NEW_TASKS:
      return { ...state, tasks: [...action.tasks, ...state.tasks] }
    case SET_SELECT_REQUEST:
      return { ...state, selectRequest: action.selectRequest }
    case SET_POPOUT_REQUESTS:
      return { ...state, requestsPagePopout: action.popout }
    default:
      return state
  }
}

export const setSelectRequest = (selectRequest) => ({ type: SET_SELECT_REQUEST, selectRequest })

export const setPopoutRequests = (popout) => ({
  type: SET_POPOUT_REQUESTS,
  popout
})


export const setTasks = (tasks) => ({
  type: SET_TASKS,
  tasks
})

export const setNewTasks = (tasks) => ({
  type: SET_NEW_TASKS,
  tasks
})

export const getTasks = (id, offset, count, tasks, setPopout) => (dispatch) => {
  Api.users.getExecutorTasks(id, offset, count).then(response => {
    if (tasks.length === 0) {
      response.data.data && dispatch(setTasks(convertRequestTasks(response.data.data)))
    }
    dispatch(setPopoutRequests(null))
  })
}

export const loadUpTasks = (id, count, tasks) => (dispatch) => {
  Api.users.getExecutorTasks(id, tasks.length, count).then(response => {
    dispatch(setTasks(convertRequestTasks(response.data.data)))
  })
}

const convertRequestTasks = (tasks) => {
  return tasks && tasks.map( task => {
    return {
      id: task.id,
      userId: task.idUser,
      category: {
        categoryId: task.idCategory,
        name: task.category && task.category.name,
      },
      desc: task.desc,
      taskFiles: task.task_file && task.task_file.map( file => {{ return { fileName: file.fileName, fileSize: file.fileSize } }}),
      timeLimit: task.timeLimit,
      datetime: task.date,
    }
  })
}

export default tutorsReducer
