const VIEWS = {
  // TODO убрать после проверки интерфейса
  ROLE: {
    ID: 'role',
    PANELS: {
      SELECT_ROLE: 'select-role'
    }
  },
  INTRO: {
    ID: 'intro',
    PANELS: {
      SLIDER: 'slider'
    }
  },
  EPIC: {
    ID: 'epic',
    PANELS: {
      MAIN: 'main',
      RELATIONSHIP: 'relationship',
      CHAT: 'chat',
      USEFUL: 'useful',
      PROFILE: 'profile',
      // CONSULTANT
      REQUESTS: 'requests',
      PROSTOTEST: 'prostotest'
    }
  },
  MAIN: {
    ID: 'main',
    PANELS: {
      ABOUT_BONUS_SYSTEM: 'about-bonus-system',
      PROFILE: 'profile',
      FEEDBACK: 'feedback',
      ALL_FEEDBACK: 'all-feedback',
      HOME: 'home',
      TEST: 'test',
      TESTS: 'tests',
      TEST_RESULT: 'test-result',
      VIDEO_GALLERY: 'video-gallery',
      FILES_GALLERY: 'files-gallery',
      CALCULATORS: 'calculators',
      CALCULATORS_PAGE: 'calculators-page',
      CALCULATOR_PAGE: 'calculator-page',
      VIDEO_LIST: 'video-list',
      VIDEO_PAGE: 'video-page',
      COMMENTS_LIST: 'comments-list',
      CREATE_TEST: 'create-test',
      ADD_QUESTIONS_TO_TEST: 'add-questions-to-test'
    }
  },
  TUTORS: {
    ID: 'tutors',
    PANELS: {
      CATEGORIES: 'categories',
      SUB_CATEGORIES: 'sub-categories',
      EXECUTORS: 'executors',
      PROFILE: 'profile',
      PUBLISH_REQUEST: 'publish-request'
    },
  },
  CHAT: {
    ID: 'chat',
    PANELS: {
      DIALOG_LIST: 'dialog-list',
      DIALOG: 'dialog',
      FEEDBACK: 'feedback',
      PROFILE: 'profile',
    }
  },
  USEFUL: {
    ID: 'useful',
    PANELS: {
      MAIN: 'useful-main',
      TEST: 'useful-test',
      CALC: 'useful-calc'
    }
  },
  PROFILE: {
    ID: 'profile',
    PANELS: {
      PROFILE_INFO: 'profile-info',
      COMMISSION: 'commission',
      SETTINGS: 'profile-settings',
      SELECT_CITY: 'profile-select-city',
      PROFILE_INFO_REVIEW: 'profile-info-review',
      PROGRESS: 'profile-progress',
    }
  },
  // CONSULTANT
  REQUESTS: {
    ID: 'requests',
    PANELS: {
      REQUESTS_LIST: 'requests-list',
      REQUEST_PAGE: 'request-page',
      PROFILE: 'profile'
    }
  },
  // ADMINISTRATOR
  ADMINISTRATOR: {
    ID: 'admin',
    PANELS: {
      MAIN: 'main',
      USERS: 'users',
      CATEGORIES: 'admin-categories',
      SUB_CATEGORIES: 'admin-sub-categories',
      TESTS: 'tests',
      CREATE_TEST: 'create-test',
      PREVIEW_TEST: 'preview-test'
    }
  },
  // engineering works
  ENGINEERING_WORKS: {
    ID: 'engineering-works',
    PANELS: {
      MAIN: 'warning',
    }
  },

}

export default VIEWS
