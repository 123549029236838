import React, {Fragment, useEffect, useRef, useState} from 'react'
import {WriteBar, WriteBarIcon, List, SimpleCell, File, Div, Separator} from '@vkontakte/vkui'

import PreviewImageList from './PreviewImageList'
import {useDispatch, useSelector} from "react-redux"
import {setSnackbar} from "../../../../redux/reducers/profile-reducer"
import {MySnackbar} from "../../../../components/MySnackbar"
import Icon28MoneyCircleOutline from '@vkontakte/icons/dist/28/money_circle_outline'
import Icon28DocumentOutline from '@vkontakte/icons/dist/28/document_outline'
import ACTIONS from "../../actionsChat";
import {getCurrentUserRole} from "../../../../redux/selectors/profile-selectors";
import ROLES from "../../../../roles";
import getReadableFileSize from "../../../../lib/getReadableFileSize";
import Icon24Dismiss from "@vkontakte/icons/dist/24/dismiss";
import trimString from "../../../../lib/trimString";
import { Icon28AttachOutline } from '@vkontakte/icons';

const Index = ({ sendMessage, sendImages, showModal, sendBill }) => {

  const dispatch = useDispatch()

  const [textMessage, setTextMessage] = useState('')
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const fileInput  = useRef(null)

  const filesLimit = 10

  const currentUserRole = useSelector(getCurrentUserRole)

  const attachmentsCount = 0;
  const [isAttachmentsShown, setIsAttachmentsShown] = useState(false);
  const fixedLayoutInnerElRef = useRef();
  const [bottomPadding, setBottomPadding] = useState(0);

  const onChangeText = (e) => setTextMessage(e.target.value)

  const attachFiles = () => {
    if (images.length + files.length + Object.values(fileInput.current.files).length <= filesLimit) {
      const files = Object.values(fileInput.current.files)

      for (const file of files) {
        const reader = new FileReader()
        if (file && file.type.match('image.*')) {
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            setImages(images => [...images, reader.result])
          }
        } else if (file) {
          reader.readAsDataURL(file)
          reader.fileName = file.name
          reader.fileSize = file.size

          reader.onloadend = (readerEvt) => {
            const name = readerEvt.target.fileName
            const size = readerEvt.target.fileSize
            setFiles(files => [...files, { 'fileName': name, 'fileSize': size, 'file': reader.result } ])
          }
        }
      }
    } else {
      dispatch(setSnackbar(<MySnackbar message={`Не более ${filesLimit} фалов за раз`}/>))
    }
  }

  const deleteImage = (imageIndex) => {
    setImages(images.filter((x, index) => index !== imageIndex))
  }

  const deleteFiles = (fileIndex) => {
    setFiles(files.filter((x, index) => index !== fileIndex))
  }

  const handleSendMessage = () => {
    if (textMessage.trim().length > 0)  {
      sendMessage(textMessage)
      setTextMessage('')
    }
    if (images.length > 0 ) {
      sendImages(images)
      setImages([])
    }
    if (files.length > 0 ) {
      sendImages(files.map(file => file.file))
      setFiles([])
    }
    setIsAttachmentsShown(false)
  }

  const updateBottomPadding = () => {
    const el = fixedLayoutInnerElRef.current;
    if (el) {
      const height = el.offsetHeight;
      if (height !== bottomPadding) {
        setBottomPadding(height);
      }
    }
  };

  useEffect(() => {
    updateBottomPadding()
  }, [isAttachmentsShown])

  const showSendBill = () => {
    showModal(ACTIONS.ISSUE_INVOICE, {func: sendBill})
    setIsAttachmentsShown(false)
  }

  const fileFormats = 'image/*,.pdf,.doc,.docx,.xlsx,.XLS,.dwg,.cdw,.frw,.djvu'

  return (
    <div ref={fixedLayoutInnerElRef}>

      { isAttachmentsShown &&
        <div className='PreviewImageList'>
          <List>

            { currentUserRole === ROLES.CONSULTANT && <SimpleCell before={<Icon28MoneyCircleOutline/>} onClick={showSendBill}>Выставить счет</SimpleCell> }

            <SimpleCell before={<Icon28DocumentOutline/>}>
              <File getRef={fileInput}
                    mode='secondary'
                    controlSize='m'
                    accept={fileFormats}
                    onChange={attachFiles}
                    multiple>
                    Прикрепить файлы
              </File>
            </SimpleCell>

            { ( images.length > 0 || files.length > 0 ) && <Separator  /> }

            { images.length > 0 && (
              <SimpleCell>
                <div className='InputWrapper'>
                    <PreviewImageList images={images} deleteImage={deleteImage}/>
                </div>
              </SimpleCell>
            ) }

            { files.length > 0 && (
              <Div>
                { files.map((file,i) => {
                  return <SimpleCell
                    key={i}
                    description={getReadableFileSize(file.fileSize) || 'Размер неизвестен'}
                    before={<Icon28AttachOutline />}
                    after={<Icon24Dismiss width={18} height={18} onClick={() => deleteFiles(i)} />}>
                    { trimString(file.fileName, 16) }
                  </SimpleCell>
                })}
              </Div>
            )}

          </List>
        </div>
      }

      <WriteBar
        value={textMessage}
        placeholder='Сообщение'
        onChange={(e) => onChangeText(e)}
        before={<WriteBarIcon mode="attach"
                        onClick={() => setIsAttachmentsShown(!isAttachmentsShown)}
                        count={isAttachmentsShown ? null : attachmentsCount}/>}
        after={<Fragment>
                { (textMessage.length > 0 || images.length > 0 || files.length > 0) &&
                <WriteBarIcon mode="send" onClick={handleSendMessage}/> }
              </Fragment>}/>
    </div>
  )
}

export default Index
