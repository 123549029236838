import React from 'react'
import {
  Panel,
  Group,
} from '@vkontakte/vkui'

import ProfileItem from '../components/ProfileItem'
import CellProfileStats from '../../../components/CellProfileStats'

import './ProfileInfo.css'
import VIEWS from "../../panels";
import {useSelector} from "react-redux";
import {
  getCurrentUserProfileInfo,
  getProfileSnackbar
} from "../../../redux/selectors/profile-selectors";
import createProfileItems from "../../../lib/createProfileItems";
import GroupProfileProgress from "../../../components/GroupProfileProgress/GroupProfileProgress";
import HeaderPanel from "../../../components/HeaderPanel";
import GroupProfileTests from "../Tests/GroupProfileTests";

const Index = ({ id, header, profile, goTo, showModal, isMyProfile = false, backTo, panelBack }) => {

  const snackbar = useSelector(getProfileSnackbar)

  const profileInfoItems = createProfileItems(useSelector(getCurrentUserProfileInfo), profile)

  return (
    <Panel id={id}>
      <HeaderPanel isMyProfile={isMyProfile} goTo={goTo} panel={VIEWS.PROFILE.PANELS.PROFILE_INFO} backTo={backTo} panelBack={panelBack}>
        {header}
      </HeaderPanel>

      <Group>

        { profile.id &&
        <>
          <ProfileItem profile={profile} goTo={goTo} showModal={showModal} isCurrentUserProfile={isMyProfile}/>

          <GroupProfileProgress profile={profile} showModal={showModal} isMyProfile={isMyProfile} goTo={goTo}/>

          {/*<GroupProfileTests profile={profile} isMyProfile={isMyProfile} goTo={goTo}/>*/}

          <Group mode='plain'>
            {profileInfoItems.map((data, i) =>
              data && (profile.role === data.role || !data.role) &&
              <CellProfileStats key={i} data={data} selectedStat={showModal} isMyProfile={isMyProfile}/>)}
          </Group>

        </> }

        {snackbar}

      </Group>

    </Panel>
  )
}

export default Index
