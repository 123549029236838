import React, {useState} from 'react'
import {Button, Input, ModalCard} from '@vkontakte/vkui'

const Index = (props) => {
  const { id, onClose, value } = props

  const [ number, setNumber ] = useState(10)

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      header='Введите число'
      actions={[
        <Button key={'ok'} mode='primary' onClick={() => {
          value.func(number)
          onClose()
        }}>Готово</Button>
      ]}
    >
      <Input defaultValue={number} onChange={e => setNumber(e.target.value)}/>
    </ModalCard>
  )
}

export default Index
