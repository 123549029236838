import React, {useEffect} from "react"
import bridge from "@vkontakte/vk-bridge"
import {useDispatch} from "react-redux";
import {setNotification, setScheme} from "../redux/reducers/global-reducer";

function useSubscribeScheme() {
  const dispatch = useDispatch()

  useEffect(() => {
    bridge.subscribe(e => {
      switch (e.detail.type) {
        case 'VKWebAppUpdateConfig':
          dispatch(setScheme(e.detail.data.scheme))

          const schemeAttribute = document.createAttribute('scheme');
          schemeAttribute.value = e.detail.data.scheme ? e.detail.data.scheme : 'client_light';
          document.body.attributes.setNamedItem(schemeAttribute);
          break
        case 'VKWebAppDenyNotificationsResult':
          dispatch(setNotification(false))
          break
        case 'VKWebAppAllowNotificationsResult':
          dispatch(setNotification(true))
          break
        default:
          break
      }
    })
  },[])
}

export default useSubscribeScheme