const SET_NOTIFICATION = "SET_NOTIFICATION";
const SET_SCHEME = "SET_SCHEME";

let initialState = {
  scheme: 'bright_light',
  notification: false,
}

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: return { ...state, notification: action.notification }
    case SET_SCHEME: return { ...state, scheme: action.scheme }
    default:
      return state;
  }
};

export const setNotification = (notification) => ({ type: SET_NOTIFICATION, notification })
export const setScheme = (scheme) => ({ type: SET_SCHEME, scheme })


export default globalReducer;