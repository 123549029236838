import React, {useEffect, useState} from 'react'
import 'moment/locale/ru'
import { Button, Card, Div } from '@vkontakte/vkui'

import './VideoCard.css'
import {useDispatch, useSelector} from "react-redux";
import {goToPanel} from "../../redux/reducers/views-reducer";
import VIEWS from "../../panels/panels";
import {setSelectedUser} from "../../redux/reducers/main-reducer";
import Api from "../../api/api";
import {
  convertUserInfoProperties,
  getFullProfileUserData,
  MAIN_PROFILE_USER
} from "../../redux/reducers/profile-reducer";
import ROLES from "../../roles";

const Index = ({ video, openCard, selectedVideo = false }) => {

  const { id, name, description, youTubeVideo } = video

  const selectedPlaylist = useSelector( state => state.mainReducer.selectedPlaylist)
  const category = useSelector( state => state.mainReducer.playlist[selectedPlaylist].info.category)
  const author = useSelector( state => state.mainReducer.playlist[selectedPlaylist].info.author)

  const dispatch = useDispatch()
  const [ user, setUser ] = useState(null)

  const handleUser = (id) => {
    Api.users.getByUserGbgId(id).then((response) => {
      setUser(convertUserInfoProperties(response.data.data))
    })
  }

  useEffect(() => {
    if (user && user.role === ROLES.CONSULTANT) {
      dispatch(getFullProfileUserData(user.id, MAIN_PROFILE_USER, true))
      dispatch(setSelectedUser(user.id))
      dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.PROFILE))
    }
  }, [user])

  return (
    <Card mode='shadow'>
        <div className='RequestCard'>
          { selectedVideo
            ? <div className='RequestCard__videoWrapper'><iframe className='RequestCard__video' src={`https://www.youtube.com/embed/${youTubeVideo}`} title={id}/></div>
            : <div className='RequestCard__videoWrapper' onClick={openCard}><div className='RequestCard__imageVideo' style={{backgroundImage: `url(https://img.youtube.com/vi/${youTubeVideo}/sddefault.jpg)`}}/> </div>}
          <div className='RequestCard__content'>
            <div className='RequestCard__date'>
              {category.name ? category.name : 'Без названия'}
            </div>
            <div className='RequestCard__title' onClick={openCard}>{name ? name : 'Без названия'}</div>
            <div className='RequestCard__text' onClick={openCard}>{description}</div>
            <Button mode='tertiary' onClick={() => handleUser(author.id)}>{author.name}</Button>
          </div>
          <div className='RequestCard__button'>
          </div>
        </div>
    </Card>
  )
}

export default Index
