import {ScreenSpinner} from "@vkontakte/vkui";
import React from "react";
import ROLES from "../../roles";

const SET_EXECUTORS = "SET_EXECUTORS"

const SET_CATEGORISES = "SET_CATEGORISES"
const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY"
const SET_SUB_CATEGORISES = "SET_SUB_CATEGORISES"
const SET_SELECTED_SUB_CATEGORISES = "SET_SELECTED_SUB_CATEGORISES"

const SET_SELECTED_EXECUTOR = "SET_SELECTED_EXECUTOR"
const SET_SELECTED_EXECUTOR_INFO = "SET_SELECTED_EXECUTOR_INFO"

const SET_SELECTED_TIMING = "SET_SELECTED_TIMING"
const SET_POPOUT = "SET_POPOUT"

let initialState = {
  categories : {
    categories: [],
    selectedCategory: null
  },
  subCategories: {
    subCategories: [],
    selectedSubCategory: null
  },
  executors: [],
  selectedExecutor: {
    affiliate: {
      amount: 0,
      sumNotDoneBill: 0,
      user_account_history: [],
      user_invited: []
    },
    reviews: [],
    categories: [],
    portfolio: [],
    video: [],
  },
  selectedTiming: null,
  popout: <ScreenSpinner size='large' />
}


const tutorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORISES:
      return { ...state, categories: {...state.categories, categories: action.categories} }
    case SET_EXECUTORS:
      return { ...state, executors: action.executors }
    case SET_SELECTED_CATEGORY:
      return { ...state, categories: { ...state.categories, selectedCategory: action.selectedCategory } }
    case SET_SUB_CATEGORISES:
      return { ...state, subCategories: { ...state.subCategories, subCategories: action.subCategories } }
    case SET_SELECTED_SUB_CATEGORISES:
      return { ...state, subCategories: { ...state.subCategories, selectedSubCategory: action.selectedSubCategory } }
    case SET_SELECTED_TIMING:
      return { ...state, selectedTiming: action.selectedTiming }
    case SET_SELECTED_EXECUTOR:
      return { ...state, selectedExecutor: { ...state.selectedExecutor, ...action.selectedExecutor } }
    case SET_SELECTED_EXECUTOR_INFO:
      return { ...state, selectedExecutor: { ...state.selectedExecutor, [action.name] : action.selectedExecutorInfo } }
    case SET_POPOUT:
      return { ...state, popout: action.popout }
    default:
      return state
  }
}

export const setCategories = (categories) => ({ type: SET_CATEGORISES, categories })

export const setSelectedCategory = (selectedCategory) => ({ type: SET_SELECTED_CATEGORY, selectedCategory })

export const setSubCategories = (subCategories) => ({ type: SET_SUB_CATEGORISES, subCategories })

export const setSelectedSubCategory = (selectedSubCategory) => ({ type: SET_SELECTED_SUB_CATEGORISES, selectedSubCategory })

export const setSelectedTiming = (selectedTiming) => ({ type: SET_SELECTED_TIMING, selectedTiming })

export const setExecutors = (executors) => ({ type: SET_EXECUTORS, executors })

export const setSelectedExecutor = (selectedExecutor) => ({ type: SET_SELECTED_EXECUTOR, selectedExecutor })

export const setPopout = (popout) => ({ type: SET_POPOUT, popout })

export const convertTutorsExecutors = (executors) => {
  return executors && executors.map( user => {
    return {
      id: +user.idUser,
      nickname: user.name,
      about: user.about,
      avatar: {
        img: user.img_name
      },
      city: user.city,
      consultations: +user.taskDone,
      rate: +user.rate,
      online: user.online === 1,
      role: ROLES.CONSULTANT,
      top: user.top === 'y',
    }
  })
}

export default tutorsReducer
