import React from 'react'
import PreviewImage from './PreviewImage'
import { HorizontalScroll } from '@vkontakte/vkui'


const PreviewImageList = ({ images, deleteImage }) => (
  <HorizontalScroll>
    <div className='PreviewImageList'>
      {images.map((image, index) => (
        <PreviewImage key={index} image={image} index={index} deleteImage={deleteImage} />
      ))}
    </div>
  </HorizontalScroll>
)

export default PreviewImageList
