import React  from 'react'
import { FormLayout, ModalPage, SelectMimicry, Placeholder } from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
import MODALS from '../../modals'

const Index = (props) => {
  const { id, onClose, category, showModalPage } = props
  // const [nickName, setNickName] = useState('Не выбрано')
  // const [datePublish, setDatePublish] = useState('')
  // const [activeTab, setActiveTab] = useState('urgently')

  // const onChangeNickName = (value) => setNickName(value)
  // const onChangeDatePublish = (value) => setDatePublish(value)

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Фильтры' modalBack={onClose} /> )}>
      <FormLayout>
        {/*<Input type='text' defaultValue={nickName} onChange={e => onChangeNickName(e.target.value)} name='name' top='Никнейм' />*/}

        {/*<Select value={datePublish} top='Дата' placeholder='Выберите дату' onChange={e => onChangeDatePublish(e.target.value)}>*/}
        {/*  <option value='m'>14 мая</option>*/}
        {/*  <option value='f'>15 мая</option>*/}
        {/*</Select>*/}

        <SelectMimicry
          top='Категория'
          placeholder='Выберите категорию'
          onClick={() => showModalPage(MODALS.PAGE.CATEGORIES)}
        >
          {category && category.name}
        </SelectMimicry>

        <Placeholder
          // icon={<Icon56MentionOutline />}
        >
           Выберите категорию, по которой отобразить заявки
        </Placeholder>

        {/*<CustomTabs>*/}
        {/*  <CustomTabsItem name='urgently' title='Срочно' activeTab={activeTab} handleSetActiveTab={setActiveTab} />*/}
        {/*  <CustomTabsItem name='notUrgent' title='Не срочно' activeTab={activeTab} handleSetActiveTab={setActiveTab} />*/}
        {/*</CustomTabs>*/}
      </FormLayout>
    </ModalPage>
  )
}

export default Index
