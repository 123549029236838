import React, {useEffect} from 'react'
import { View, ScreenSpinner } from '@vkontakte/vkui'

import VIEWS from '../panels'
import Categories from './Categories'
import Executors from './Executors'
import Profile from '../Profile/ProfileInfo'
import PublishRequest from './PublishRequest'

import Api from '../../api/api'
import {useDispatch, useSelector} from "react-redux";
import {
  convertTutorsExecutors,
  setCategories,
  setExecutors, setPopout,
  setSelectedCategory,
  setSelectedSubCategory,
  setSubCategories
} from "../../redux/reducers/tutors-reducer";
import {goBackPanel, goToPanel} from "../../redux/reducers/views-reducer";
import ROLES from "../../roles";
import {
  getProfileUserData,
  setUserData,
  setUserRole, TUTORS_PROFILE_USER
} from "../../redux/reducers/profile-reducer";
import {getTutorsCategories, getTutorsReducer, getTutorsSubCategories} from "../../redux/selectors/tutors-selectors";
import {getTutorsViews} from "../../redux/selectors/view-selectors";
import {getTutorsProfileUser} from "../../redux/selectors/profile-selectors";

const Index = ({ id, showModal }) => {

  const dispatch = useDispatch()

  const { activePanel, history } = useSelector(getTutorsViews)
  const { categories, selectedCategory } = useSelector(getTutorsCategories)
  const { subCategories, selectedSubCategory } = useSelector(getTutorsSubCategories)
  const { executors, popout } = useSelector(getTutorsReducer)

  const tutorsProfileUser = useSelector(getTutorsProfileUser)

  const goTo = (panel) => dispatch(goToPanel(VIEWS.TUTORS.ID, panel))

  const goBack = () => {
    dispatch(goBackPanel(VIEWS.TUTORS.ID, history[history.length - 2], history.length))
  }

  const selectCategory = (categoryId) => {
    if (selectedCategory === null || selectedCategory.id !== categoryId) {
      dispatch(setSelectedCategory(categories.find(category => category.id === categoryId)))
      dispatch(setPopout(<ScreenSpinner size='large' />))
    }
    dispatch(goToPanel(VIEWS.TUTORS.ID, VIEWS.TUTORS.PANELS.SUB_CATEGORIES))
  }

  const selectSubCategory = (categoryId) => {
    if (selectedSubCategory === null || selectedSubCategory.id !== categoryId) {
      dispatch(setSelectedSubCategory(subCategories.find(category => category.id === categoryId)))
      dispatch(setPopout(<ScreenSpinner size='large' />))
    }
    dispatch(goToPanel(VIEWS.TUTORS.ID, VIEWS.TUTORS.PANELS.EXECUTORS))
  }

  useEffect(() => {
    Api.categories.getByParentId(0, (response) => {
      dispatch(setCategories(response))
      dispatch(setPopout(null))
    })
  }, [])

  useEffect(() => {
    if (selectedCategory !== null) {
      Api.categories.getByParentId(selectedCategory.id, (subCategories) => {
        dispatch(setSubCategories(subCategories))
        dispatch(setPopout(null))
      })
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selectedSubCategory !== null) {
      Api.executors.getByCategoryId(selectedSubCategory.id, (response) => {
        dispatch(setExecutors(convertTutorsExecutors(response)))
        dispatch(setPopout(null))
      })
    }
  }, [selectedSubCategory])

  const selectExecutor = (panel, executor) => {
    dispatch(setUserData(TUTORS_PROFILE_USER, executor))
    dispatch(setUserRole(TUTORS_PROFILE_USER, ROLES.CONSULTANT))
    dispatch(getProfileUserData(executor.id, TUTORS_PROFILE_USER,true))
    dispatch(goToPanel(VIEWS.TUTORS.ID, panel))
  }

  return (
      <View id={id} activePanel={activePanel} popout={popout} onSwipeBack={goBack} history={history}>
        <Categories id={VIEWS.TUTORS.PANELS.CATEGORIES}
                    selectCategory={selectCategory}
                    categories={categories}
                    header='Направления'
                    withoutButtonBack
        />

        <Categories id={VIEWS.TUTORS.PANELS.SUB_CATEGORIES}
                    selectCategory={selectSubCategory}
                    categories={subCategories}
                    header={selectedCategory !== null ? selectedCategory.name : 'Подкатегории'}
                    backTo={goTo} />

        <Executors id={VIEWS.TUTORS.PANELS.EXECUTORS}
                   executors={executors}
                   header={selectedSubCategory !== null ? selectedSubCategory.name : 'Помощники'}
                   selectExecutor={selectExecutor}
                   backTo={goTo}
                   publishTo={goTo} />

        <Profile id={VIEWS.TUTORS.PANELS.PROFILE}
                 profile={tutorsProfileUser}
                 showModal={showModal}
                 header='Профиль'
                 panelBack={VIEWS.TUTORS.PANELS.EXECUTORS}
                 backTo={goTo}
        />

        <PublishRequest id={VIEWS.TUTORS.PANELS.PUBLISH_REQUEST}
                        categories={subCategories}
                        header='Заявка'
                        category={selectedSubCategory !== null ? selectedSubCategory : 'Категория'}
                        defaultValue={selectedSubCategory !== null ? selectedSubCategory.name : 'Категория'}
                        backTo={goTo}
                        showModalPage={showModal} />
      </View>
  )
}

export default Index
