import VIEWS from "../../panels/panels";
import ROLES from "../../roles";
import MODALS from "../../modals/modals";

const SET_SELECTED_VIDEO = "SET_SELECTED_VIDEO";
const SET_SELECTED_USER = "SET_SELECTED_USER";
const SET_SELECTED_PLAYLIST = "SET_SELECTED_PLAYLIST";
const SET_SELECTED_CATEGORY_FILE = "SET_SELECTED_CATEGORY_FILE";
const SET_SELECTED_CATEGORY_CALCULATORS = "SET_SELECTED_CATEGORY_CALCULATORS";
const SET_SELECTED_CALCULATOR = "SET_SELECTED_CALCULATOR";
const SET_SELECTED_TEST = "SET_SELECTED_TEST";

let initialState = {
  playlist : [
    {
      id: 1,
      info: {
        name: 'Курс по сопромату',
        category: { id: 3, name: 'Сопротивление материалов'},
        author: { id: 423, name: 'Александр'} },
      videos: [
        { id: 1, name: 'Определение опорных реакций в балке', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '0AQuxE0ZoT4' },
        { id: 2, name: 'Построение эпюр в балке', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ixLKX1Nd95M' },
        { id: 3, name: 'Определение опорных реакций в раме', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'E_9bUNDsTfo' },
        { id: 4, name: 'Построение эпюр в раме', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '0_7F2JQaHW4' },
        { id: 5, name: 'Кривой брус', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'P7nx4HL9tR8' },
        { id: 6, name: 'Определение характеристик сечения', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'P0yvNmzTWxg' },
        { id: 7, name: 'Подбор сечения при изгибе', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'Lux_YFrMe0o' },
        { id: 8, name: 'Ферма ( мву, риттера )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'oDXegiL0YOU' },
        { id: 9, name: 'Стержни на растяжение-сжатие', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ZbJu4qORYl4' },
        { id: 10, name: 'Подбор сечения при растяжении-сжатии', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'EslF8OfKNX0' },
        { id: 11, name: 'Кручение', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'gCXFV5zE40s' },
        { id: 12, name: 'Подбор сечения при кручении', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '6qxI2NOJJqQ' },
        { id: 13, name: 'Метод начальных параметров', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'j6-PwYd6vU8' },
        { id: 14, name: 'Интеграл Мора', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'zPfLpX96AAw' },
        { id: 15, name: 'Правило Верещагина', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'r0sOdSuMObE' },
        { id: 16, name: 'Формула Симпсона', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'TCKCXhsB8S8' },
        { id: 17, name: 'Ломаный брус', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'p8ECUWkdxkc' },
        { id: 18, name: 'Подбор сечения при изгибе и кручении', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'kAR-7NwkYVE' },
        { id: 19, name: 'Определение перемещений в ломаном брусе', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'xjZdGogeJlk' },
        { id: 20, name: 'Сжатая стойка', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'y4ExKGuFMEI' },
        { id: 21, name: 'Внецентренное сжатие', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'PriE8vsCRQ8' },
        { id: 22, name: 'Косой изгиб', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'uCqnkM378ew' },
        { id: 23, name: 'Кручение с изгибом', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'hwT6VImwhzk' },
        { id: 24, name: 'Динамическая нагрузка ( удар )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'qJWA4JjG-r0' },
        { id: 25, name: 'Статически неопределимая балка ( метод сил )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'rp1wqqWOB_Y' },
        { id: 26, name: 'Статически неопределимая рама ( метод сил )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'GDV0QpG4Jlg' },
        { id: 27, name: 'Статически неопределимый стержень ( растяжение-сжатие )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'NuBQuPReVek' },
        { id: 28, name: 'Статически неопределимый стержень ( кручение )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'y1YQYaILN-k' },
        { id: 29, name: 'Жесткий брус', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'xpWpvAjzKSA' },
        { id: 30, name: 'Статически неопределимая балка ( уравнение трех моментов )', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '8UwPgqKJNkc' }
      ]
    },
    {
      id: 2,
      info: {
        name: 'Дифференциальные уравнения',
        category: { id: 4, name: 'Дифференциальные уравнения'},
        author: { id: 1942, name: 'Егор'} },
      videos: [
        { id: 1, name: 'Однородные дифференциальные уравнения 1 порядка', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'AlL9sMq_Yb0' },
        { id: 2, name: 'Дифференциальные уравнения с разделяющимися переменными и приводящиеся к ним', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '-nkkZ5sT5Mo' },
        { id: 3, name: 'Дифференциальные уравнения с разделяющимися переменными', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ZpUOOEkwnWc' },
        { id: 4, name: 'Дифференциальные уравнения 1-го порядка, приводящиеся к однородным (теория)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'hEoden8ywLg' },
        { id: 5, name: 'Дифференциальные уравнения 1-го порядка, приводящиеся к однородным (практика)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'DvfcjvTLc_k' },
        { id: 6, name: 'Линейные дифференциальные уравнения (Метод вариации)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'MjhEkVKYU1s' },
        { id: 7, name: 'Линейные дифференциальные уравнения (Метод Бернулли)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'eCQDogxLEHA' },
        { id: 8, name: 'Уравнения Бернулли. Дифференциальны уравнения', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'sCs-jsDD6MQ' },
        { id: 9, name: 'Уравнения Риккатти. Дифференциальны уравнения', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '8CvPHAiezsw' },
        { id: 10, name: 'Метод интегрируемых комбинаций', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'RW3dJeS1IYY' },
        { id: 11, name: 'Дифференциальные уравнения в полных дифференциалах (часть 1)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'PC47ZuLG36c' },
        { id: 12, name: 'Дифференциальные уравнения в полных дифференциалах (часть 2)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'M2dJvL3jh-Y' },
      ]
    },
    {
      id: 3,
      info: {
        name: 'Теоретическая механика',
        category: { id: 8, name: 'Теоретическая механика'},
        author: { id: 233, name: 'Владимир'} },
      videos: [
        { id: 1, name: 'Определение опорных реакции в пространственной конструкции', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'C6ks8K3PN3c' },
        { id: 2, name: 'Кинематика точки в плоскости', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '4yi6xAXnHiQ' },
        { id: 3, name: 'Кинематика вращательного движения. Термех', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '-Zs3ObINcGg' },
        { id: 4, name: 'Плоско-параллельное движение. Определение скоростей', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'VLTObs2iTzY' },
        { id: 5, name: 'Определение ускорений при плоско-параллельном движении', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'H8pY_a8AQd4' },
        { id: 6, name: 'Cложное движение точки', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ym0UdMtIgYs' },
      ]
    },
    {
      id: 4,
      info: {
        name: 'Мат анализу',
        category: { id: 243, name: 'Мат анализ'},
        author: { id: 1943, name: 'Наталья'} },
      videos: [
        { id: 1, name: 'Тригонометрическая и показательная форма комплексного числа', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'oRXRfMyDxpU' },
        { id: 2, name: 'Комплексные числа и операции над ними', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'vv3UF5voNoo' },
      ]
    },
    {
      id: 5,
      info: {
        name: 'Линейная алгебра',
        category: { id: 4, name: 'Линейная алгебра'},
        author: { id: 1943, name: 'Наталья'} },
      videos: [
        { id: 1, name: 'Решение СЛАУ методом Крамера', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'azL0Fsk_Wlc' },
        { id: 2, name: 'Определитель. Матрица. Способы вычисления определителя', description: 'Короткое описание, которое я напишу позже', youTubeVideo: '8MUvHIRsge0' },
        { id: 3, name: 'Перемножение матриц', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'UCQyEAx-g-g' },
      ]
    },
    {
      id: 6,
      info: {
        name: 'Строительная механика',
        category: { id: 5, name: 'Строительная механика'},
        author: { id: 233, name: 'Владимир'} },
      videos: [
        { id: 1, name: 'Построение линий влияния для опорных реакции. Понятие линий влияния', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'SXis8Zu8K8M' },
        { id: 2, name: 'Линия влияния моментов и поперечных сил в балке', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'dzG_JzihMWA' },
        { id: 3, name: 'Расчет усилий в сечении от постоянной нагрузки по линии влияния', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ChWfbWI2lBk' },
        { id: 4, name: 'Определение усилий в ферме. Метод сечений', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'Kr95ThI8JyQ' },
        { id: 5, name: 'Линии влияния в ферме', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'c9dROy5zGk8' },
        { id: 6, name: 'Расчет рамы методом перемещений. Строймех', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'QORdn2Z5YKg' },
      ]
    },
    {
      id: 7,
      info: {
        name: 'Теория механизмов и машин',
        category: { id: 29, name: 'Теория механизмов и машин'},
        author: { id: 192, name: 'Максим'} },
      videos: [
        { id: 1, name: 'Построение плана скоростей', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'lXFLV_DncGw' },
        { id: 2, name: 'Построение плана ускорений', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'UE7WneUTJzM' },
        { id: 3, name: 'Метод замкнутых векторных контуров. Механизм 1-го класса', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'fN67shWMBxY' },
        { id: 4, name: 'Метод замкнутых векторных контуров. Группа ВВП', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'WYO6_yOtznk' },
        { id: 5, name: 'Метод замкнутых векторных контуров. Группа ВПВ', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'ZFggAKZni7Y' },
        { id: 6, name: 'Метод замкнутых векторных контуров. Группа ВПП (синусный механизм)', description: 'Короткое описание, которое я напишу позже', youTubeVideo: 'AzcQ4Q8qTL0' },
      ]
    },
  ],
  items: [
    {id: 46483038,
    title: 'Термех',
    comments: [
      {
        "id": 26,
        "from_id": -199760602,
        "date": 1603699390,
        "text": "Термех"
      },
      {
        "id": 27,
        "from_id": -199760602,
        "date": 1603699405,
        "text": "Полезные методички",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571582895,
              "owner_id": 86065323,
              "title": "Плоская система. Термех.pdf",
              "size": 572881,
              "ext": "pdf",
              "date": 1603699400,
              "type": 1,
              "url": "https://psv4.userapi.com/c856232/u86065323/docs/d7/733718ec5d16/Ploskaya_sistema_Termekh.pdf?extra=eSYK2YWIRv8L0X6FC0RU1zZqH5Q9qLY-VT8RvefdXDu1o350OMhnW8MTx0x624q5sO6vwH8HK4FtzRhnZARaKM3GYPruzDcrM4SR-U5NzUWknmR6QCBusK2RpPQlsLe6MGXtfayL0PDz7risozSmMw",
              "access_key": "8725b224ed08470a65"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582896,
              "owner_id": 86065323,
              "title": "ТерМех.pdf",
              "size": 569635,
              "ext": "pdf",
              "date": 1603699400,
              "type": 1,
              "url": "https://psv4.userapi.com/c856232/u86065323/docs/d9/fbdd53ba1b1b/TerMekh.pdf?extra=oLhLdwzR2i8KjgS8lEfgCcCVyR-z5QGZB65k8k-29pXid5N4lAqNcd3hMAgQDfxRX5VSh0VsUf4V6hGQbh9as-tdOfY_GNmfwv7c1WtgE6SaULhS2ZmaHJYIC8MZ-gWob5lhC83dnYPlpOpHjzbbsA",
              "access_key": "66e20c59d2207f1aca"
            }
          }
        ]
      },
      {
        "id": 28,
        "from_id": -199760602,
        "date": 1603699432,
        "text": "Определение усилий в ферме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 566514711,
              "owner_id": 86065323,
              "title": "IMG_8629.JPG",
              "size": 3386303,
              "ext": "jpg",
              "date": 1600513786,
              "type": 4,
              "url": "https://vk.com/doc86065323_566514711?hash=4258a50f4eece7db02&dl=GA:1603816334:f30d1735c2a06c2c40&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-51.userapi.com/c856220/u86065323/d14/-3/m_c551dafe44.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-5.userapi.com/c856220/u86065323/d14/-3/s_c551dafe44.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-37.userapi.com/c856220/u86065323/d14/-3/x_c551dafe44.jpg",
                      "width": 604,
                      "height": 604,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-29.userapi.com/c856220/u86065323/d14/-3/y_c551dafe44.jpg",
                      "width": 807,
                      "height": 807,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-3.userapi.com/c856220/u86065323/d14/-3/z_c551dafe44.jpg",
                      "width": 1280,
                      "height": 1280,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/c856220/u86065323/d14/-3/o_c551dafe44.jpg",
                      "width": 1920,
                      "height": 1920,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/impc/c856220/u86065323/d14/-3/o_c551dafe44.jpg?size=288x288&quality=90&sign=b0f8d5be9b0101811d11aa3524ec206d&c_uniq_tag=QxuMCfgUuSPhQpZ3oiuEasGMqqxmEFpg4PsLiGFPlss",
                      "width": 288,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/impc/c856220/u86065323/d14/-3/o_c551dafe44.jpg?size=192x192&quality=90&sign=8caeb8a4aae11b7f2cb9c8ffd9a95f9a&c_uniq_tag=HOf66w-V-iYXBXsz9aUZ6_dIySq-UD6kJA1B_c_nqjg",
                      "width": 192,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "1c2067088be8b1a8be"
            }
          }
        ]
      },
      {
        "id": 50,
        "from_id": -199760602,
        "date": 1603815848,
        "text": "Тема: Применение основных теорем динамики к исследованию движения материальной точки",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794625,
              "owner_id": 86065323,
              "title": "cq95V41HWWM.jpg",
              "size": 885669,
              "ext": "jpg",
              "date": 1603815845,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794625?hash=84c8bfe05ba4d8237f&dl=GA:1603816334:9850169df960a525ba&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-14.userapi.com/c856420/u86065323/d15/-3/m_b65c7baaff.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-47.userapi.com/c856420/u86065323/d15/-3/s_b65c7baaff.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-3.userapi.com/c856420/u86065323/d15/-3/x_b65c7baaff.jpg",
                      "width": 604,
                      "height": 581,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-2.userapi.com/c856420/u86065323/d15/-3/y_b65c7baaff.jpg",
                      "width": 807,
                      "height": 776,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-10.userapi.com/c856420/u86065323/d15/-3/z_b65c7baaff.jpg",
                      "width": 1280,
                      "height": 1232,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-34.userapi.com/c856420/u86065323/d15/-3/o_b65c7baaff.jpg",
                      "width": 2245,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-34.userapi.com/impc/c856420/u86065323/d15/-3/o_b65c7baaff.jpg?size=288x277&quality=90&sign=f514d65785cf143f041e331289c5e935&c_uniq_tag=Z_Pgpxwt2iSBSBE-s1Z8HWyvSLRX6LRLXWqu7mZlEo0",
                      "width": 288,
                      "height": 277,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-34.userapi.com/impc/c856420/u86065323/d15/-3/o_b65c7baaff.jpg?size=192x185&quality=90&sign=b88c477fd985f13fc01c1bc202d1c30f&c_uniq_tag=rw-lvtr2dCqP2c-5hHVUy1KkiITGnhXiAsD19dD8tRA",
                      "width": 192,
                      "height": 185,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "5ed209609706ed6ea4"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794629,
              "owner_id": 86065323,
              "title": "JK-RaJIWv90.jpg",
              "size": 875328,
              "ext": "jpg",
              "date": 1603815846,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794629?hash=9d3ac81eb3fc0515d8&dl=GA:1603816334:bd01a112ed040344da&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-48.userapi.com/c856420/u86065323/d6/-3/m_44ec36f707.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-74.userapi.com/c856420/u86065323/d6/-3/s_44ec36f707.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-12.userapi.com/c856420/u86065323/d6/-3/x_44ec36f707.jpg",
                      "width": 604,
                      "height": 622,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-68.userapi.com/c856420/u86065323/d6/-3/y_44ec36f707.jpg",
                      "width": 807,
                      "height": 831,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-24.userapi.com/c856420/u86065323/d6/-3/z_44ec36f707.jpg",
                      "width": 1280,
                      "height": 1318,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/c856420/u86065323/d6/-3/o_44ec36f707.jpg",
                      "width": 2097,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/impc/c856420/u86065323/d6/-3/o_44ec36f707.jpg?size=280x288&quality=90&sign=a5afdd3dceb683c562ece4c8e0229d31&c_uniq_tag=oCBAzMb5kCBqihusIAHNB0YdDLTXhN2IcoXjAgHJ5w0",
                      "width": 280,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/impc/c856420/u86065323/d6/-3/o_44ec36f707.jpg?size=186x192&quality=90&sign=357e1c8c5ab448d7d6994dd969cde03a&c_uniq_tag=8G6RpC8v-NDrgKAi1VZel4furHDAwC1km3lSfmUmUq8",
                      "width": 186,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "9206b0edc68e7c6398"
            }
          }
        ]
      },
      {
        "id": 51,
        "from_id": -199760602,
        "date": 1603815866,
        "text": "Тема: Принцип возможных перемещений (ПВП)",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794692,
              "owner_id": 86065323,
              "title": "xdxt76hvHd4.jpg",
              "size": 772957,
              "ext": "jpg",
              "date": 1603815865,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794692?hash=9fe68694696f4f3170&dl=GA:1603816334:9ca18ec95be1d048da&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-11.userapi.com/c856216/u86065323/d17/-3/m_e6769b8f62.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-48.userapi.com/c856216/u86065323/d17/-3/s_e6769b8f62.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-7.userapi.com/c856216/u86065323/d17/-3/x_e6769b8f62.jpg",
                      "width": 604,
                      "height": 799,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-50.userapi.com/c856216/u86065323/d17/-3/y_e6769b8f62.jpg",
                      "width": 807,
                      "height": 1067,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/c856216/u86065323/d17/-3/z_e6769b8f62.jpg",
                      "width": 1280,
                      "height": 1693,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/c856216/u86065323/d17/-3/o_e6769b8f62.jpg",
                      "width": 1633,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/impc/c856216/u86065323/d17/-3/o_e6769b8f62.jpg?size=218x288&quality=90&sign=a82a7ae6a95da7247339e8aa9bd02b11&c_uniq_tag=-dN09e_6opaxGW_zmMsF8Hms8tfSrRRNNRZa_HYE-iI",
                      "width": 218,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/impc/c856216/u86065323/d17/-3/o_e6769b8f62.jpg?size=145x192&quality=90&sign=d5c4a0c93349b41b2395c0ea965e6556&c_uniq_tag=aa5BZnLg5wtVMYJzLpFKRTLO2jPx1a2mjgr-_Aj3pto",
                      "width": 145,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "0de4711cf0074351db"
            }
          }
        ]
      },
      {
        "id": 52,
        "from_id": -199760602,
        "date": 1603815915,
        "text": "Тема: Анализ движения кривошипно-шатунного механизма",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794824,
              "owner_id": 86065323,
              "title": "80ggnwCJYZM.jpg",
              "size": 902422,
              "ext": "jpg",
              "date": 1603815912,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794824?hash=e3d374f1e3a2cfdf5e&dl=GA:1603816334:15889b540039daf111&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-32.userapi.com/c856424/u86065323/d16/-3/m_6308d7ff8f.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-25.userapi.com/c856424/u86065323/d16/-3/s_6308d7ff8f.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-63.userapi.com/c856424/u86065323/d16/-3/x_6308d7ff8f.jpg",
                      "width": 604,
                      "height": 687,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-24.userapi.com/c856424/u86065323/d16/-3/y_6308d7ff8f.jpg",
                      "width": 807,
                      "height": 918,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/c856424/u86065323/d16/-3/z_6308d7ff8f.jpg",
                      "width": 1280,
                      "height": 1456,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-44.userapi.com/c856424/u86065323/d16/-3/o_6308d7ff8f.jpg",
                      "width": 1899,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-44.userapi.com/impc/c856424/u86065323/d16/-3/o_6308d7ff8f.jpg?size=253x288&quality=90&sign=345a6f17e1ee98faa5e5fd463c31a2d3&c_uniq_tag=rHxWeepANSLiZ7Gwb9dGHXFCItwMz9jrUJRIV7bGIUI",
                      "width": 253,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-44.userapi.com/impc/c856424/u86065323/d16/-3/o_6308d7ff8f.jpg?size=169x192&quality=90&sign=7d5ef72c024698e500de1ee8a45d3f1f&c_uniq_tag=Kx8UexsIwj55Mb886ZQ3uScGjhAlnZy91reFYTRvs2U",
                      "width": 169,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "5baf35d64cae7c36d3"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794837,
              "owner_id": 86065323,
              "title": "FdqtR7PTe6I.jpg",
              "size": 891777,
              "ext": "jpg",
              "date": 1603815914,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794837?hash=b679a1e0eb6e9e37e6&dl=GA:1603816334:044f922ac91e40666f&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-12.userapi.com/c856424/u86065323/d2/-3/m_bcfb38101e.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-22.userapi.com/c856424/u86065323/d2/-3/s_bcfb38101e.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-41.userapi.com/c856424/u86065323/d2/-3/x_bcfb38101e.jpg",
                      "width": 604,
                      "height": 768,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-19.userapi.com/c856424/u86065323/d2/-3/y_bcfb38101e.jpg",
                      "width": 807,
                      "height": 1027,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/c856424/u86065323/d2/-3/z_bcfb38101e.jpg",
                      "width": 1280,
                      "height": 1628,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/c856424/u86065323/d2/-3/o_bcfb38101e.jpg",
                      "width": 1698,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/impc/c856424/u86065323/d2/-3/o_bcfb38101e.jpg?size=226x288&quality=90&sign=9bd80a0a7f69a1cadaf23a8861b755d1&c_uniq_tag=hJQ8u8AaoRuHy-05Fs1agOCCCjD5i7jIzMaGGQ43yR8",
                      "width": 226,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/impc/c856424/u86065323/d2/-3/o_bcfb38101e.jpg?size=151x192&quality=90&sign=b2bd7077c894e61fabe213f229894957&c_uniq_tag=OBdE-WkL-XbsE-N4oCC_M8Tq9xMA8JfOKt-43I1HrRU",
                      "width": 151,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "ff25da64a9c2611257"
            }
          }
        ]
      },
      {
        "id": 53,
        "from_id": -199760602,
        "date": 1603815943,
        "text": "Тема: Определение реакции связей составной конструкции",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794927,
              "owner_id": 86065323,
              "title": "gXMfXUAWETU.jpg",
              "size": 796005,
              "ext": "jpg",
              "date": 1603815940,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794927?hash=a7e8d6ec55d5081e9c&dl=GA:1603816334:58b5ef2ae4ec37cdfb&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-57.userapi.com/c856436/u86065323/d16/-3/m_861856f763.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856436/u86065323/d16/-3/s_861856f763.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-60.userapi.com/c856436/u86065323/d16/-3/x_861856f763.jpg",
                      "width": 604,
                      "height": 838,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-60.userapi.com/c856436/u86065323/d16/-3/y_861856f763.jpg",
                      "width": 807,
                      "height": 1120,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-65.userapi.com/c856436/u86065323/d16/-3/z_861856f763.jpg",
                      "width": 1280,
                      "height": 1776,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/c856436/u86065323/d16/-3/o_861856f763.jpg",
                      "width": 1557,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/impc/c856436/u86065323/d16/-3/o_861856f763.jpg?size=208x288&quality=90&sign=f7ef2b1e92b5ff0fbb0a02c710704c74&c_uniq_tag=ABIFRI_qdf_3-wSzeK5_LC5WYMXZ1qgLq9D3t2lY9fI",
                      "width": 208,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-62.userapi.com/impc/c856436/u86065323/d16/-3/o_861856f763.jpg?size=138x192&quality=90&sign=58617e7e2e32ae30f8d42f7b2b540ac7&c_uniq_tag=aXam1SYVYD0T-DWSno0EYxkXiINN3B0NxCzDQB8-28Y",
                      "width": 138,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "ca98dc5d317f92c097"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794931,
              "owner_id": 86065323,
              "title": "hHtT7wbyUQU.jpg",
              "size": 953366,
              "ext": "jpg",
              "date": 1603815942,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794931?hash=bfcd42416d08ea6173&dl=GA:1603816334:2f8d47f3b94719e45a&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-27.userapi.com/c856436/u86065323/d1/-3/m_5414dc0797.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-17.userapi.com/c856436/u86065323/d1/-3/s_5414dc0797.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-15.userapi.com/c856436/u86065323/d1/-3/x_5414dc0797.jpg",
                      "width": 604,
                      "height": 600,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-74.userapi.com/c856436/u86065323/d1/-3/y_5414dc0797.jpg",
                      "width": 807,
                      "height": 802,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/c856436/u86065323/d1/-3/z_5414dc0797.jpg",
                      "width": 1280,
                      "height": 1272,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-73.userapi.com/c856436/u86065323/d1/-3/o_5414dc0797.jpg",
                      "width": 2173,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-73.userapi.com/impc/c856436/u86065323/d1/-3/o_5414dc0797.jpg?size=288x286&quality=90&sign=c71dc7b5f94bbec5c47f425a81adf53b&c_uniq_tag=Ldq4naaunaraRqDdMtMIQjF2-mxd0Vz9D38Wrfi2_G4",
                      "width": 288,
                      "height": 286,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-73.userapi.com/impc/c856436/u86065323/d1/-3/o_5414dc0797.jpg?size=192x191&quality=90&sign=feffcafd7b48f1baa4f13abc077abdba&c_uniq_tag=EInqTFza-0TT6J3iVyR7I7t7-gD2n074oFlST6Ji9fg",
                      "width": 192,
                      "height": 191,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "9dd052339ef6db8e7b"
            }
          }
        ]
      }
    ]
  },
    {id: 46483012,
    title: 'Математика',
    comments: [
      {
        "id": 12,
        "from_id": -199760602,
        "date": 1603698900,
        "text": "Математика"
      },
      {
        "id": 23,
        "from_id": -199760602,
        "date": 1603699305,
        "text": "Методичка по математике",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571582698,
              "owner_id": 86065323,
              "title": "Математика.pdf",
              "size": 485740,
              "ext": "pdf",
              "date": 1603699298,
              "type": 1,
              "url": "https://psv4.userapi.com/c856416/u86065323/docs/d2/053214cbe193/Matematika.pdf?extra=SDFs-hV7Z4_RKWcZnYGhlizaNiouozpcEDuiyRO-ONwVQX1L5OrUU7ynPjGveKnAPuoSc7oYSo671VVQNhg4SaoPhERh1n0bfGXOx5UEoACYhcCR7969IFt6tIhGyQGmFl_NgrzkzUlJx11viAr5Lw",
              "access_key": "a615c4b3c20a720888"
            }
          }
        ]
      },
      {
        "id": 24,
        "from_id": -199760602,
        "date": 1603699339,
        "text": "Таблица производных",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571582767,
              "owner_id": 86065323,
              "title": "unnamed.png",
              "size": 21350,
              "ext": "png",
              "date": 1603699333,
              "type": 4,
              "url": "https://vk.com/doc86065323_571582767?hash=3aaaa99dfbe0411032&dl=GA:1603816772:c09d94f3735548c406&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-21.userapi.com/c856328/u86065323/d18/-3/m_60336fbc87.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856328/u86065323/d18/-3/s_60336fbc87.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-16.userapi.com/c856328/u86065323/d18/-3/x_60336fbc87.jpg",
                      "width": 604,
                      "height": 615,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-41.userapi.com/c856328/u86065323/d18/-3/o_60336fbc87.jpg",
                      "width": 487,
                      "height": 496,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-16.userapi.com/impc/c856328/u86065323/d18/-3/x_60336fbc87.jpg?size=283x288&quality=90&sign=014925adb6b412701d013895c97f4c9b&c_uniq_tag=TlzDTgv3RuMd4LMllvJkFQFskKO_2PU1QCsXn_6x-iQ",
                      "width": 283,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-16.userapi.com/impc/c856328/u86065323/d18/-3/x_60336fbc87.jpg?size=189x192&quality=90&sign=55227d381ed3d999af22fca46c40d19c&c_uniq_tag=39-cgm20STR4nzevTnSf5i0Fmbdw9iNxmjLFHLb-tDw",
                      "width": 189,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "b3d62e8128964e0899"
            }
          }
        ]
      },
      {
        "id": 32,
        "from_id": -199760602,
        "date": 1603725140,
        "text": "Формулы и свойства логарифмов\n\nДля любых a > 0, a ≠ 1 и b > 0, x > 0, y > 0 выполняются следующие свойства логарифмов.",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571797068,
              "owner_id": 86065323,
              "title": "7xQ0qLU6nxs-2.jpg",
              "size": 260933,
              "ext": "jpg",
              "date": 1603816655,
              "type": 4,
              "url": "https://vk.com/doc86065323_571797068?hash=1620159eca539faff8&dl=GA:1603816772:e4c6cf814a16558863&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-34.userapi.com/c856220/u86065323/d13/-3/m_0db7b7c352.jpg",
                      "width": 130,
                      "height": 87,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-50.userapi.com/c856220/u86065323/d13/-3/s_0db7b7c352.jpg",
                      "width": 100,
                      "height": 67,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-25.userapi.com/c856220/u86065323/d13/-3/x_0db7b7c352.jpg",
                      "width": 604,
                      "height": 401,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-13.userapi.com/c856220/u86065323/d13/-3/y_0db7b7c352.jpg",
                      "width": 807,
                      "height": 535,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-54.userapi.com/c856220/u86065323/d13/-3/z_0db7b7c352.jpg",
                      "width": 1280,
                      "height": 849,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/c856220/u86065323/d13/-3/o_0db7b7c352.jpg",
                      "width": 1474,
                      "height": 978,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/impc/c856220/u86065323/d13/-3/o_0db7b7c352.jpg?size=288x191&quality=90&sign=1398390921ed4037a0be01ad5ca8d0e1&c_uniq_tag=Agi0qqO_mg5qea3AW_3oQVAOX2fmH3hAW8H960OxhwY",
                      "width": 288,
                      "height": 191,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/impc/c856220/u86065323/d13/-3/o_0db7b7c352.jpg?size=192x127&quality=90&sign=223cbc008ca27fa5d914f4ad8ee862e1&c_uniq_tag=F0j5HHdoO-sIcQSkA3p6EshEmbGVa1AhSQXgxfiuPBw",
                      "width": 192,
                      "height": 127,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "a61da992e12717584b"
            }
          }
        ]
      },
      {
        "id": 34,
        "from_id": -199760602,
        "date": 1603726045,
        "text": "Векторная алгебра\n\nОсновные понятия, свойства и способы вычисления",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 570788594,
              "owner_id": 253956668,
              "title": "Вектроная алгебра.docx",
              "size": 30558,
              "ext": "docx",
              "date": 1603725991,
              "type": 1,
              "url": "https://vk.com/doc253956668_570788594?hash=201ec8ef1067621492&dl=b7ce2676105bbdc7c5",
              "access_key": "9094c07c88c7f14e4b"
            }
          }
        ]
      },
      {
        "id": 35,
        "from_id": -199760602,
        "date": 1603726284,
        "text": "Аналитическая геометрия\n\nОсновные определения, формулы, параметрические уравнения",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 570789515,
              "owner_id": 253956668,
              "title": "Аналитическая геометрия.docx",
              "size": 55890,
              "ext": "docx",
              "date": 1603726282,
              "type": 1,
              "url": "https://vk.com/doc253956668_570789515?hash=79422d697bf4a8a1c4&dl=c52645d896b7f5035a",
              "access_key": "f0017ff80a72c7fbd6"
            }
          }
        ]
      }
    ]
  },
    {id: 46483026,
    title: 'Сопромат',
    comments: [
      {
        "id": 14,
        "from_id": -199760602,
        "date": 1603699059,
        "text": "Сопромат"
      },
      {
        "id": 19,
        "from_id": -199760602,
        "date": 1603699161,
        "text": "Полезные методички",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571582312,
              "owner_id": 86065323,
              "title": "Алексейцев. Определение внутренних усилий методом сечений.pdf",
              "size": 509016,
              "ext": "pdf",
              "date": 1603699125,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582312?hash=319a1c9b3b97e91f3e&dl=b5cb52cf00946444db",
              "access_key": "8cfe9e384c23a82493"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582315,
              "owner_id": 86065323,
              "title": "Алексейцев. Плоский изгиб.pdf",
              "size": 1772253,
              "ext": "pdf",
              "date": 1603699126,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582315?hash=9c27d8d1c6ec0c986e&dl=49704cb3c5c3e19f3c",
              "access_key": "98f5939f2045cae435"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582341,
              "owner_id": 86065323,
              "title": "Данилов. Определение внутренних усилий.pdf",
              "size": 644089,
              "ext": "pdf",
              "date": 1603699142,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582341?hash=2ae51e01de71954b24&dl=dd6950bcee4e6d1651",
              "access_key": "7fa3e98bff13be72cc"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582343,
              "owner_id": 86065323,
              "title": "Данилов. Определение перемещений в стержневых системах.pdf",
              "size": 1118580,
              "ext": "pdf",
              "date": 1603699143,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582343?hash=0b8c0e517d65e98dd1&dl=e5352c5e4ef6116770",
              "access_key": "96b91231366b03cb21"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582348,
              "owner_id": 86065323,
              "title": "Данилов. Плоский прямой изгиб. Расчет на прочность.pdf",
              "size": 1765652,
              "ext": "pdf",
              "date": 1603699144,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582348?hash=497a06446bde336f01&dl=f878c2f7be7f013c75",
              "access_key": "11886cf899aa923919"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582349,
              "owner_id": 86065323,
              "title": "Данилов. Подбор сечения сжатой стойки.pdf",
              "size": 250400,
              "ext": "pdf",
              "date": 1603699144,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582349?hash=1850b67517f4c95bba&dl=7801f993d4d8fdaf5d",
              "access_key": "91acc84caa885cf69a"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571582350,
              "owner_id": 86065323,
              "title": "Данилов. Расчет на прочность при растяжении (сжатии).pdf",
              "size": 741857,
              "ext": "pdf",
              "date": 1603699145,
              "type": 1,
              "url": "https://vk.com/doc86065323_571582350?hash=2134b49b56191ee9be&dl=125a9ce57bceb8515f",
              "access_key": "83aa22d9bb66358c86"
            }
          }
        ]
      },
      {
        "id": 20,
        "from_id": -199760602,
        "date": 1603699216,
        "text": "Построение эпюр в балке",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571477193,
              "owner_id": 86065323,
              "title": "IMG_9544.JPG",
              "size": 705995,
              "ext": "jpg",
              "date": 1603624414,
              "type": 4,
              "url": "https://vk.com/doc86065323_571477193?hash=4a0c75bb9d0df844c4&dl=GA:1603816276:4e01280ba19c2aaf2c&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-20.userapi.com/c856432/u86065323/d11/-3/m_1199f59e19.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-18.userapi.com/c856432/u86065323/d11/-3/s_1199f59e19.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856432/u86065323/d11/-3/x_1199f59e19.jpg",
                      "width": 604,
                      "height": 1208,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856432/u86065323/d11/-3/y_1199f59e19.jpg",
                      "width": 807,
                      "height": 1614,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856432/u86065323/d11/-3/z_1199f59e19.jpg",
                      "width": 1280,
                      "height": 2561,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856432/u86065323/d11/-3/o_1199f59e19.jpg",
                      "width": 2048,
                      "height": 4097,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856432/u86065323/d11/-3/o_1199f59e19.jpg?size=144x288&quality=90&sign=134229e475cd74e1b40f3e045fe05af0&c_uniq_tag=VYbotFJTFyGP5D4Z6m2bJd51v-_OlhHQX_tNZ7Te0YE",
                      "width": 144,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856432/u86065323/d11/-3/o_1199f59e19.jpg?size=96x192&quality=90&sign=6ff46bbdf0fbc6435e79e0281ec46b14&c_uniq_tag=NHMXibiqhOXC61qPD1L6XZt-ZegOhe8wZelTVUAL6M8",
                      "width": 96,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "d0f2c59fe01d6691f0"
            }
          }
        ]
      },
      {
        "id": 21,
        "from_id": -199760602,
        "date": 1603699230,
        "text": "Построение эпюр в раме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571202639,
              "owner_id": 86065323,
              "title": "IMG_9517.JPG",
              "size": 541846,
              "ext": "jpg",
              "date": 1603445201,
              "type": 4,
              "url": "https://vk.com/doc86065323_571202639?hash=413594fef45ef6af3b&dl=GA:1603816276:88f0a1041f2364592e&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-27.userapi.com/c856536/u86065323/d18/-3/m_5acfec768d.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-45.userapi.com/c856536/u86065323/d18/-3/s_5acfec768d.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/c856536/u86065323/d18/-3/x_5acfec768d.jpg",
                      "width": 604,
                      "height": 1007,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/c856536/u86065323/d18/-3/y_5acfec768d.jpg",
                      "width": 807,
                      "height": 1346,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-15.userapi.com/c856536/u86065323/d18/-3/z_5acfec768d.jpg",
                      "width": 1280,
                      "height": 2134,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/c856536/u86065323/d18/-3/o_5acfec768d.jpg",
                      "width": 2056,
                      "height": 3428,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/impc/c856536/u86065323/d18/-3/o_5acfec768d.jpg?size=173x288&quality=90&sign=b9153b010bd87c0ad42529973d8e629d&c_uniq_tag=LM42470vjNR6-xTKfr73nfYNSGnMIYqzMAR8wzDYOag",
                      "width": 173,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/impc/c856536/u86065323/d18/-3/o_5acfec768d.jpg?size=115x192&quality=90&sign=d691cc200146f5fb1ddfbff9b20a31a5&c_uniq_tag=cFT3aAqDy92x9CUGLssggCXMJD2UFAo_AsSmFasMuio",
                      "width": 115,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "05fba8477edf9b1cfe"
            }
          }
        ]
      },
      {
        "id": 22,
        "from_id": -199760602,
        "date": 1603699275,
        "text": "Определение усилий в ферме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 566514711,
              "owner_id": 86065323,
              "title": "IMG_8629.JPG",
              "size": 3386303,
              "ext": "jpg",
              "date": 1600513786,
              "type": 4,
              "url": "https://vk.com/doc86065323_566514711?hash=4258a50f4eece7db02&dl=GA:1603816276:0a5c1e1139a94fa3bb&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-51.userapi.com/c856220/u86065323/d14/-3/m_c551dafe44.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-5.userapi.com/c856220/u86065323/d14/-3/s_c551dafe44.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-37.userapi.com/c856220/u86065323/d14/-3/x_c551dafe44.jpg",
                      "width": 604,
                      "height": 604,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-29.userapi.com/c856220/u86065323/d14/-3/y_c551dafe44.jpg",
                      "width": 807,
                      "height": 807,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-3.userapi.com/c856220/u86065323/d14/-3/z_c551dafe44.jpg",
                      "width": 1280,
                      "height": 1280,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/c856220/u86065323/d14/-3/o_c551dafe44.jpg",
                      "width": 1920,
                      "height": 1920,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/impc/c856220/u86065323/d14/-3/o_c551dafe44.jpg?size=288x288&quality=90&sign=b0f8d5be9b0101811d11aa3524ec206d&c_uniq_tag=QxuMCfgUuSPhQpZ3oiuEasGMqqxmEFpg4PsLiGFPlss",
                      "width": 288,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-42.userapi.com/impc/c856220/u86065323/d14/-3/o_c551dafe44.jpg?size=192x192&quality=90&sign=8caeb8a4aae11b7f2cb9c8ffd9a95f9a&c_uniq_tag=HOf66w-V-iYXBXsz9aUZ6_dIySq-UD6kJA1B_c_nqjg",
                      "width": 192,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "1c2067088be8b1a8be"
            }
          }
        ]
      },
      {
        "id": 38,
        "from_id": -199760602,
        "date": 1603814556,
        "text": "Еще полезные методички",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571790872,
              "owner_id": 86065323,
              "title": "Конев. Расчет статически неопределимых систем методом.pdf",
              "size": 305062,
              "ext": "pdf",
              "date": 1603814526,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790872?hash=dc81873d4a428042a8&dl=df4f5818de3c46762c",
              "access_key": "de71e888e3407e09db"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571790874,
              "owner_id": 86065323,
              "title": "Ломаный брус.pdf",
              "size": 1013989,
              "ext": "pdf",
              "date": 1603814526,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790874?hash=4c8d4ff01d589a35c2&dl=75fbfbff0732933844",
              "access_key": "7dce703f9d5dc987f3"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571790876,
              "owner_id": 86065323,
              "title": "Москаленко2017.pdf",
              "size": 1082495,
              "ext": "pdf",
              "date": 1603814527,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790876?hash=520236fe84974924bf&dl=9efcce42b8a6d5aedb",
              "access_key": "ea5f7559956fe45e20"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571790877,
              "owner_id": 86065323,
              "title": "Спектр. Расчет ломаного бруса.pdf",
              "size": 484214,
              "ext": "pdf",
              "date": 1603814527,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790877?hash=b662b271a012893fa5&dl=310f989ef896bb3593",
              "access_key": "70474ec9af3137c45e"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571790907,
              "owner_id": 86065323,
              "title": "Хорошая методичка по сопромату.pdf",
              "size": 6343394,
              "ext": "pdf",
              "date": 1603814538,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790907?hash=5607078bdbe9d49d7a&dl=aa88325deb737c0bd3",
              "access_key": "e9df0eae96c9abb072"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571790934,
              "owner_id": 86065323,
              "title": "МоскваБаумона.docx",
              "size": 8176342,
              "ext": "docx",
              "date": 1603814547,
              "type": 1,
              "url": "https://vk.com/doc86065323_571790934?hash=7d20ab47ea7ac1f9f5&dl=315bdff784797c8277",
              "access_key": "835a38c9cd12d9897a"
            }
          }
        ]
      },
      {
        "id": 40,
        "from_id": -199760602,
        "date": 1603814763,
        "text": "Определение опорных реакций в балке",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571791552,
              "owner_id": 86065323,
              "title": "538d8b9e329fc2f92701d39f03045d0b.jpg",
              "size": 390050,
              "ext": "jpg",
              "date": 1603814752,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791552?hash=6bbefc3e890ccd96d3&dl=GA:1603816276:5ba51f3646b5fc1387&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-46.userapi.com/c856332/u86065323/d12/-3/m_109e084db2.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-6.userapi.com/c856332/u86065323/d12/-3/s_109e084db2.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-48.userapi.com/c856332/u86065323/d12/-3/x_109e084db2.jpg",
                      "width": 604,
                      "height": 944,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-40.userapi.com/c856332/u86065323/d12/-3/y_109e084db2.jpg",
                      "width": 807,
                      "height": 1261,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/c856332/u86065323/d12/-3/z_109e084db2.jpg",
                      "width": 1280,
                      "height": 2000,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-68.userapi.com/c856332/u86065323/d12/-3/o_109e084db2.jpg",
                      "width": 2048,
                      "height": 3200,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-68.userapi.com/impc/c856332/u86065323/d12/-3/o_109e084db2.jpg?size=184x288&quality=90&sign=0a61ee9dc98117f55ef42170cc35b0a9&c_uniq_tag=rG4l2eIzEEhpFwu9hHgeYsfQWZ4zLT3heLmlAHGrmDo",
                      "width": 184,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-68.userapi.com/impc/c856332/u86065323/d12/-3/o_109e084db2.jpg?size=123x192&quality=90&sign=cffe04155b08af71fc350542f38cd243&c_uniq_tag=IjmuqiWCFJj2XILvq4-cBUo5dVUaUn3YKmfKV0BV8OQ",
                      "width": 123,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "79e142cc98d6406c89"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791558,
              "owner_id": 86065323,
              "title": "478749a3691f4d6267597b50f7ded3a4.jpg",
              "size": 353738,
              "ext": "jpg",
              "date": 1603814754,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791558?hash=5a53fdd26e34ed0666&dl=GA:1603816276:31f422a836aa6e9219&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-71.userapi.com/c856332/u86065323/d16/-3/m_34eb6af1b1.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-75.userapi.com/c856332/u86065323/d16/-3/s_34eb6af1b1.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-58.userapi.com/c856332/u86065323/d16/-3/x_34eb6af1b1.jpg",
                      "width": 604,
                      "height": 806,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-69.userapi.com/c856332/u86065323/d16/-3/y_34eb6af1b1.jpg",
                      "width": 807,
                      "height": 1077,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-24.userapi.com/c856332/u86065323/d16/-3/z_34eb6af1b1.jpg",
                      "width": 1280,
                      "height": 1708,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-38.userapi.com/c856332/u86065323/d16/-3/o_34eb6af1b1.jpg",
                      "width": 2048,
                      "height": 2732,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-38.userapi.com/impc/c856332/u86065323/d16/-3/o_34eb6af1b1.jpg?size=216x288&quality=90&sign=0194a6f71ca6372b869af935d3aa82aa&c_uniq_tag=9PTBPXHbsUYw6gz5y2WiaOETDIF7Kn2xns66zr4qUyA",
                      "width": 216,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-38.userapi.com/impc/c856332/u86065323/d16/-3/o_34eb6af1b1.jpg?size=144x192&quality=90&sign=0176a4ba768b8510f2aa9332259a9ba5&c_uniq_tag=rD_En-NlOzBLBXad_zLDRbYYlG0L4I1Nwhu9VIfFfGg",
                      "width": 144,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "0552becb357c0b41c4"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791562,
              "owner_id": 86065323,
              "title": "bd2aea66145ec22ce2be78b2a2e39632.jpg",
              "size": 372489,
              "ext": "jpg",
              "date": 1603814756,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791562?hash=9525cb481951159f83&dl=GA:1603816276:a5d182b4577d7767ed&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-76.userapi.com/c856332/u86065323/d15/-3/m_e6eb3b0304.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-73.userapi.com/c856332/u86065323/d15/-3/s_e6eb3b0304.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-7.userapi.com/c856332/u86065323/d15/-3/x_e6eb3b0304.jpg",
                      "width": 604,
                      "height": 806,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/c856332/u86065323/d15/-3/y_e6eb3b0304.jpg",
                      "width": 807,
                      "height": 1077,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-19.userapi.com/c856332/u86065323/d15/-3/z_e6eb3b0304.jpg",
                      "width": 1280,
                      "height": 1708,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/c856332/u86065323/d15/-3/o_e6eb3b0304.jpg",
                      "width": 2048,
                      "height": 2732,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/impc/c856332/u86065323/d15/-3/o_e6eb3b0304.jpg?size=216x288&quality=90&sign=8e10d11d4b06689bb1a07eb4b44cfb9e&c_uniq_tag=cOAxBdoSyrBKxqh15eeMZBFbbTraM_hn8McPgAi70Zw",
                      "width": 216,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/impc/c856332/u86065323/d15/-3/o_e6eb3b0304.jpg?size=144x192&quality=90&sign=1b435e758669639ee369a20eb8860658&c_uniq_tag=6hwm3RaQXucGNLwKfJBjoc-rNhbzf4CJz3-q_hV2Qvo",
                      "width": 144,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "3755d60237d772cdb0"
            }
          }
        ]
      },
      {
        "id": 41,
        "from_id": -199760602,
        "date": 1603814800,
        "text": "Построение эпюр в балке",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571791662,
              "owner_id": 86065323,
              "title": "50cc44a665c5ee13b2bfd51c8e5f0c8a.JPG",
              "size": 470033,
              "ext": "jpg",
              "date": 1603814795,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791662?hash=b1be50eefb494dc822&dl=GA:1603816276:63eab41695464a2443&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-72.userapi.com/c856420/u86065323/d15/-3/m_2a64df7456.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-32.userapi.com/c856420/u86065323/d15/-3/s_2a64df7456.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-63.userapi.com/c856420/u86065323/d15/-3/x_2a64df7456.jpg",
                      "width": 604,
                      "height": 853,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-73.userapi.com/c856420/u86065323/d15/-3/y_2a64df7456.jpg",
                      "width": 807,
                      "height": 1140,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-67.userapi.com/c856420/u86065323/d15/-3/z_2a64df7456.jpg",
                      "width": 1280,
                      "height": 1808,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/c856420/u86065323/d15/-3/o_2a64df7456.jpg",
                      "width": 1746,
                      "height": 2466,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/impc/c856420/u86065323/d15/-3/o_2a64df7456.jpg?size=204x288&quality=90&sign=86fff2c3be6390a968de6e9e7207317c&c_uniq_tag=rCJt1bQjWXS3qSKFt2GEhA2XnNHre4bpYpsKS6sN6U0",
                      "width": 204,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/impc/c856420/u86065323/d15/-3/o_2a64df7456.jpg?size=136x192&quality=90&sign=0f11cb2fba968e1cfbc0173d627fa239&c_uniq_tag=p1mgGSR22BzofIC2qVNqxysFK2LcneV92PYedgRmN8w",
                      "width": 136,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "f35eec3c95b09f30c9"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791665,
              "owner_id": 86065323,
              "title": "c8e43ad7a1f1428463ae5f16974bbfdc.JPG",
              "size": 472118,
              "ext": "jpg",
              "date": 1603814797,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791665?hash=a7880580cd34abc746&dl=GA:1603816276:71c1ac3f663a9e498d&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-17.userapi.com/c856420/u86065323/d5/-3/m_5bbc88441a.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-36.userapi.com/c856420/u86065323/d5/-3/s_5bbc88441a.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-67.userapi.com/c856420/u86065323/d5/-3/x_5bbc88441a.jpg",
                      "width": 604,
                      "height": 853,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-14.userapi.com/c856420/u86065323/d5/-3/y_5bbc88441a.jpg",
                      "width": 807,
                      "height": 1140,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/c856420/u86065323/d5/-3/z_5bbc88441a.jpg",
                      "width": 1280,
                      "height": 1808,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-64.userapi.com/c856420/u86065323/d5/-3/o_5bbc88441a.jpg",
                      "width": 1746,
                      "height": 2466,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-64.userapi.com/impc/c856420/u86065323/d5/-3/o_5bbc88441a.jpg?size=204x288&quality=90&sign=116a9dab44275c147e43f565e6f90057&c_uniq_tag=cztit_6O5oYLGya0RKWz6ySwnap85gvP8MCWH-G6yb8",
                      "width": 204,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-64.userapi.com/impc/c856420/u86065323/d5/-3/o_5bbc88441a.jpg?size=136x192&quality=90&sign=1e90c61b122616069dd674c433da1798&c_uniq_tag=AWUFsKfw-6tkflMMBQyQv_stSBA5ow9-mTxvF_G_DCw",
                      "width": 136,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "168fab0cab7033881e"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791670,
              "owner_id": 86065323,
              "title": "e872f0e23829fdb4dd23dc18f9420a8b.JPG",
              "size": 479868,
              "ext": "jpg",
              "date": 1603814799,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791670?hash=2d2ceb03d73f371500&dl=GA:1603816276:26b472fe187eced8ca&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-42.userapi.com/c856420/u86065323/d12/-3/m_0a4f87fca9.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-54.userapi.com/c856420/u86065323/d12/-3/s_0a4f87fca9.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-46.userapi.com/c856420/u86065323/d12/-3/x_0a4f87fca9.jpg",
                      "width": 604,
                      "height": 853,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/c856420/u86065323/d12/-3/y_0a4f87fca9.jpg",
                      "width": 807,
                      "height": 1140,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-65.userapi.com/c856420/u86065323/d12/-3/z_0a4f87fca9.jpg",
                      "width": 1280,
                      "height": 1808,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/c856420/u86065323/d12/-3/o_0a4f87fca9.jpg",
                      "width": 1746,
                      "height": 2466,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856420/u86065323/d12/-3/o_0a4f87fca9.jpg?size=204x288&quality=90&sign=d86786782a447830205842a3982380f7&c_uniq_tag=zCalQGZoLa_S4BlgyHcvNbMwrNdYZbEhskK-wH4S6LU",
                      "width": 204,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856420/u86065323/d12/-3/o_0a4f87fca9.jpg?size=136x192&quality=90&sign=e9296e09f910eeb9c2de4b3e7d95d2b9&c_uniq_tag=g66zaS-5-YJVe0zWBrT_q9oHA4kz5eN9cJ5eVwjYGLA",
                      "width": 136,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "1061454c62450a959a"
            }
          }
        ]
      },
      {
        "id": 42,
        "from_id": -199760602,
        "date": 1603814832,
        "text": "Определение опорных реакций в раме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571791727,
              "owner_id": 86065323,
              "title": "16276259971ef4c0265257a9aa1f32d4.JPG",
              "size": 481890,
              "ext": "jpg",
              "date": 1603814826,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791727?hash=49c9f8bc448da6e018&dl=GA:1603816276:0d9fe6ac909f4db162&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-62.userapi.com/c856332/u86065323/d14/-3/m_38e5e137c0.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-47.userapi.com/c856332/u86065323/d14/-3/s_38e5e137c0.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-72.userapi.com/c856332/u86065323/d14/-3/x_38e5e137c0.jpg",
                      "width": 604,
                      "height": 820,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-71.userapi.com/c856332/u86065323/d14/-3/y_38e5e137c0.jpg",
                      "width": 807,
                      "height": 1095,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-65.userapi.com/c856332/u86065323/d14/-3/z_38e5e137c0.jpg",
                      "width": 1280,
                      "height": 1738,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/c856332/u86065323/d14/-3/o_38e5e137c0.jpg",
                      "width": 2048,
                      "height": 2780,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/impc/c856332/u86065323/d14/-3/o_38e5e137c0.jpg?size=212x288&quality=90&sign=50bbc5c910886a959ebacca1d3b0a66e&c_uniq_tag=OdJcj5w7ywri7-_xmZMnL-sDuAOPED9dik5ZAOFrW2s",
                      "width": 212,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-66.userapi.com/impc/c856332/u86065323/d14/-3/o_38e5e137c0.jpg?size=141x192&quality=90&sign=863e777753d866f6c6a759c1300df12c&c_uniq_tag=rQ7De517QDDSoXuIppS32nG0aho2TYrXzo8kgHLTcQQ",
                      "width": 141,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "7b2c5ba1e8047163b8"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791734,
              "owner_id": 86065323,
              "title": "7153ceabefba193b502ca154764bcef6.JPG",
              "size": 368373,
              "ext": "jpg",
              "date": 1603814828,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791734?hash=cc96896c026beb1a35&dl=GA:1603816276:cc3788ca04efea34da&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-28.userapi.com/c856332/u86065323/d8/-3/m_54922603e4.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856332/u86065323/d8/-3/s_54922603e4.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-22.userapi.com/c856332/u86065323/d8/-3/x_54922603e4.jpg",
                      "width": 604,
                      "height": 806,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-22.userapi.com/c856332/u86065323/d8/-3/y_54922603e4.jpg",
                      "width": 807,
                      "height": 1077,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856332/u86065323/d8/-3/z_54922603e4.jpg",
                      "width": 1280,
                      "height": 1708,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-43.userapi.com/c856332/u86065323/d8/-3/o_54922603e4.jpg",
                      "width": 2048,
                      "height": 2732,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-43.userapi.com/impc/c856332/u86065323/d8/-3/o_54922603e4.jpg?size=216x288&quality=90&sign=14ab1d02c521753ce73be9623c948509&c_uniq_tag=k8daZa3T4HGBCtV_5-XDWPyNZV3ud8ELaJNbTcJh9V8",
                      "width": 216,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-43.userapi.com/impc/c856332/u86065323/d8/-3/o_54922603e4.jpg?size=144x192&quality=90&sign=6ec025a39ed130b928e9e408c87117cc&c_uniq_tag=7aYSqwldQRvQJLhEE7uAvSc_wUepu5783rFLBme8B_o",
                      "width": 144,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "e8eaba8584e676eee0"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791741,
              "owner_id": 86065323,
              "title": "c730d7c44636e231797e43edb87e7f01.JPG",
              "size": 488726,
              "ext": "jpg",
              "date": 1603814830,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791741?hash=eaa6df5d64f00ec8ce&dl=GA:1603816276:4a423a9b0a8852ded8&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-33.userapi.com/c856332/u86065323/d3/-3/m_6d63764964.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/c856332/u86065323/d3/-3/s_6d63764964.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-19.userapi.com/c856332/u86065323/d3/-3/x_6d63764964.jpg",
                      "width": 604,
                      "height": 897,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-69.userapi.com/c856332/u86065323/d3/-3/y_6d63764964.jpg",
                      "width": 807,
                      "height": 1199,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856332/u86065323/d3/-3/z_6d63764964.jpg",
                      "width": 1280,
                      "height": 1901,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-9.userapi.com/c856332/u86065323/d3/-3/o_6d63764964.jpg",
                      "width": 2048,
                      "height": 3042,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-9.userapi.com/impc/c856332/u86065323/d3/-3/o_6d63764964.jpg?size=194x288&quality=90&sign=c5dc0256e352a1297acf972467a451ce&c_uniq_tag=HPjaebkoea2gX3WR5zHYKdCGSUHTX96JhY8O7fQubmQ",
                      "width": 194,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-9.userapi.com/impc/c856332/u86065323/d3/-3/o_6d63764964.jpg?size=129x192&quality=90&sign=45af19d94cd6bdd349550d86a286399d&c_uniq_tag=6bzV9MSrGmZ1wLRBdzwWXkxILpqb-n9eYB-uYq1Ntd4",
                      "width": 129,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "bb5b3a194339552773"
            }
          }
        ]
      },
      {
        "id": 43,
        "from_id": -199760602,
        "date": 1603814870,
        "text": "Построение эпюр в раме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571791848,
              "owner_id": 86065323,
              "title": "6caabea6d25cd6a98154c61fae67b88f-2.jpg",
              "size": 481590,
              "ext": "jpg",
              "date": 1603814866,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791848?hash=9ea80b0fd44ac6e0d7&dl=GA:1603816276:d3e3a84869620cfbe3&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-16.userapi.com/c856328/u86065323/d17/-3/m_4bd2b165a6.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-28.userapi.com/c856328/u86065323/d17/-3/s_4bd2b165a6.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-11.userapi.com/c856328/u86065323/d17/-3/x_4bd2b165a6.jpg",
                      "width": 604,
                      "height": 1602,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-13.userapi.com/c856328/u86065323/d17/-3/y_4bd2b165a6.jpg",
                      "width": 807,
                      "height": 2140,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/c856328/u86065323/d17/-3/z_4bd2b165a6.jpg",
                      "width": 1280,
                      "height": 3395,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-10.userapi.com/c856328/u86065323/d17/-3/o_4bd2b165a6.jpg",
                      "width": 1714,
                      "height": 4546,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-10.userapi.com/impc/c856328/u86065323/d17/-3/o_4bd2b165a6.jpg?size=109x288&quality=90&sign=a31163618914a85aaaaec5c834f40b1a&c_uniq_tag=WLpHsuFqvUKdzKvT7H7v3NxKOZ2e5p6eRy6X0yFYXKA",
                      "width": 109,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-10.userapi.com/impc/c856328/u86065323/d17/-3/o_4bd2b165a6.jpg?size=72x192&quality=90&sign=ef5b5121c43a29cabaf97d84466e9788&c_uniq_tag=Uy5oIQXUVMUyDwi3zvP9meWt82FbFsk7v1YcVH9OP-0",
                      "width": 72,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "0beac57359ce30862c"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571791862,
              "owner_id": 86065323,
              "title": "a37fd8e4d508c4d67b46335ea16a2fc4.jpg",
              "size": 469589,
              "ext": "jpg",
              "date": 1603814868,
              "type": 4,
              "url": "https://vk.com/doc86065323_571791862?hash=059836ee002366d1d8&dl=GA:1603816276:38f5eb024af369ef26&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-16.userapi.com/c856328/u86065323/d16/-3/m_9d3abbe3bd.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-29.userapi.com/c856328/u86065323/d16/-3/s_9d3abbe3bd.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-2.userapi.com/c856328/u86065323/d16/-3/x_9d3abbe3bd.jpg",
                      "width": 604,
                      "height": 1210,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856328/u86065323/d16/-3/y_9d3abbe3bd.jpg",
                      "width": 807,
                      "height": 1617,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-58.userapi.com/c856328/u86065323/d16/-3/z_9d3abbe3bd.jpg",
                      "width": 1280,
                      "height": 2564,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-11.userapi.com/c856328/u86065323/d16/-3/o_9d3abbe3bd.jpg",
                      "width": 1711,
                      "height": 3428,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-11.userapi.com/impc/c856328/u86065323/d16/-3/o_9d3abbe3bd.jpg?size=144x288&quality=90&sign=584e828a64fa69cefd999eab56f20aa7&c_uniq_tag=GG5qNvt6RlcYnu2piUjubBXL8dIEE6CCjBOnZkNdPJ0",
                      "width": 144,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-11.userapi.com/impc/c856328/u86065323/d16/-3/o_9d3abbe3bd.jpg?size=96x192&quality=90&sign=b7e5f30157499a3b4371b96f676c258b&c_uniq_tag=W23BuvB5ML7txry34OKkcpPV5YNa4ybwhPsni8aAjVs",
                      "width": 96,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "d8c14ee8d30ff68ab5"
            }
          }
        ]
      }
    ]
  },
    {id: 46483028,
    title: 'Физика',
    comments: [
      {
        "id": 18,
        "from_id": -199760602,
        "date": 1603699082,
        "text": "Физика"
      },
      {
        "id": 33,
        "from_id": -199760602,
        "date": 1603725579,
        "text": "Пример лабораторной работы на тему: \n\"Определение длины световой волны с помощью дифракционной решетки\"",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571797193,
              "owner_id": 86065323,
              "title": "D72zuoh-ito.jpg",
              "size": 146328,
              "ext": "jpg",
              "date": 1603816700,
              "type": 4,
              "url": "https://vk.com/doc86065323_571797193?hash=e00a640f74451e9613&dl=GA:1603816788:d1931baa124cbc8ac6&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-52.userapi.com/c856436/u86065323/d5/-3/m_f191362377.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-21.userapi.com/c856436/u86065323/d5/-3/s_f191362377.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-44.userapi.com/c856436/u86065323/d5/-3/x_f191362377.jpg",
                      "width": 604,
                      "height": 1068,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856436/u86065323/d5/-3/y_f191362377.jpg",
                      "width": 807,
                      "height": 1426,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-29.userapi.com/c856436/u86065323/d5/-3/o_f191362377.jpg",
                      "width": 611,
                      "height": 1080,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/impc/c856436/u86065323/d5/-3/y_f191362377.jpg?size=163x288&quality=90&sign=86605ca20617c8fd3b20df227b6d7fbb&c_uniq_tag=QGhhqGugGdU6gpsrxcmIZok_191fNIWshPD8sfrYBCc",
                      "width": 163,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/impc/c856436/u86065323/d5/-3/y_f191362377.jpg?size=109x192&quality=90&sign=d4009dd2cee301d16f574e5c99d5b6d6&c_uniq_tag=5_mZ9vDfHL-TMexe8h27aFoPBZ6yeagi1Xkhjq1tOGM",
                      "width": 109,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "34e594a91cedcb3923"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571797195,
              "owner_id": 86065323,
              "title": "SYrWsbsQCLs.jpg",
              "size": 137358,
              "ext": "jpg",
              "date": 1603816701,
              "type": 4,
              "url": "https://vk.com/doc86065323_571797195?hash=4ef7d46a97538b95a4&dl=GA:1603816788:7b488ab2ca92be961f&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-13.userapi.com/c856436/u86065323/d18/-3/m_cb6294b529.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-25.userapi.com/c856436/u86065323/d18/-3/s_cb6294b529.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856436/u86065323/d18/-3/x_cb6294b529.jpg",
                      "width": 604,
                      "height": 1068,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856436/u86065323/d18/-3/y_cb6294b529.jpg",
                      "width": 807,
                      "height": 1426,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-5.userapi.com/c856436/u86065323/d18/-3/o_cb6294b529.jpg",
                      "width": 611,
                      "height": 1080,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/impc/c856436/u86065323/d18/-3/y_cb6294b529.jpg?size=163x288&quality=90&sign=8617f1de509cfbf83534cbc9fffa9447&c_uniq_tag=HPxG7BFMqA_H69jZ0KJ4LpnEz2Z0P4AMKjbx9uYfLOg",
                      "width": 163,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/impc/c856436/u86065323/d18/-3/y_cb6294b529.jpg?size=109x192&quality=90&sign=ee328c408c2283e3ac188a7e36c11feb&c_uniq_tag=nCd_Q0lsCmrSbKRadvbjhaSdcd2dxGl2gsMinmUIx3k",
                      "width": 109,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "4b27065a03b9dbab85"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571797196,
              "owner_id": 86065323,
              "title": "3b7Rde1FJTc.jpg",
              "size": 127817,
              "ext": "jpg",
              "date": 1603816701,
              "type": 4,
              "url": "https://vk.com/doc86065323_571797196?hash=201f48ac510a88310d&dl=GA:1603816788:cda26d9c1172975ce0&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-28.userapi.com/c856436/u86065323/d3/-3/m_48dff679f1.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-71.userapi.com/c856436/u86065323/d3/-3/s_48dff679f1.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856436/u86065323/d3/-3/x_48dff679f1.jpg",
                      "width": 604,
                      "height": 1068,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856436/u86065323/d3/-3/y_48dff679f1.jpg",
                      "width": 807,
                      "height": 1426,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-28.userapi.com/c856436/u86065323/d3/-3/o_48dff679f1.jpg",
                      "width": 611,
                      "height": 1080,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856436/u86065323/d3/-3/y_48dff679f1.jpg?size=163x288&quality=90&sign=184167d801bfc3f082c49dc55ddcf17e&c_uniq_tag=m7d7fDdT4bSB-JKIKdEn-i65yuQI3-R76WfXqk1aweo",
                      "width": 163,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856436/u86065323/d3/-3/y_48dff679f1.jpg?size=109x192&quality=90&sign=0291dd10207671de572068fab615a6ed&c_uniq_tag=pEunFvfzgEUx03_arUOMTPNlvoT2U97XEFRF2CAWvZo",
                      "width": 109,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "fd72a062d89792eff0"
            }
          }
        ]
      },
      {
        "id": 36,
        "from_id": -199760602,
        "date": 1603726828,
        "text": "Здесь представлены самые основные формулы по физике. \n\nРазделы: кинематика, динамика, электростатика, квантовая физика, ядерная физика и другие.",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571797268,
              "owner_id": 86065323,
              "title": "T6N-CVWs1ak.jpg",
              "size": 178774,
              "ext": "jpg",
              "date": 1603816722,
              "type": 4,
              "url": "https://vk.com/doc86065323_571797268?hash=e788fbb81aa1a5ef1a&dl=GA:1603816788:d7fe9a3a035b139f59&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-64.userapi.com/c856336/u86065323/d12/-3/m_4ae8344fbd.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-19.userapi.com/c856336/u86065323/d12/-3/s_4ae8344fbd.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856336/u86065323/d12/-3/x_4ae8344fbd.jpg",
                      "width": 604,
                      "height": 493,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-7.userapi.com/c856336/u86065323/d12/-3/y_4ae8344fbd.jpg",
                      "width": 807,
                      "height": 659,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/c856336/u86065323/d12/-3/z_4ae8344fbd.jpg",
                      "width": 1280,
                      "height": 1045,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-16.userapi.com/c856336/u86065323/d12/-3/o_4ae8344fbd.jpg",
                      "width": 1058,
                      "height": 864,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/impc/c856336/u86065323/d12/-3/z_4ae8344fbd.jpg?size=288x235&quality=90&sign=20ec792f17e7fdd26dd676666e4b0033&c_uniq_tag=p18zfIP-UNAUO3WSB5ILe70Vnm-UKp60e5dHnmNDYFg",
                      "width": 288,
                      "height": 235,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-30.userapi.com/impc/c856336/u86065323/d12/-3/z_4ae8344fbd.jpg?size=192x157&quality=90&sign=e5dace1ff36f1743b1b2a16a20055909&c_uniq_tag=vMeSfUpHV6MX9A4J1tDCY3ZWGchvKZoQkjSAP5JZ4Tw",
                      "width": 192,
                      "height": 157,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "01751c3c3a3a1b0469"
            }
          }
        ]
      },
      {
        "id": 37,
        "from_id": -199760602,
        "date": 1603726937,
        "text": "Динамика, основные законы кинематики, основы МКТ и ТДТ, электростатика, элементы теории относительности, энергия\nПредназначен для студентов 1 курса.",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 570791395,
              "owner_id": 253956668,
              "title": "Физика.doc",
              "size": 1284608,
              "ext": "doc",
              "date": 1603726935,
              "type": 1,
              "url": "https://vk.com/doc253956668_570791395?hash=346e46c5c7b30b234c&dl=af6feec02232be3be0",
              "access_key": "cc4fcb6cbe37c790db"
            }
          }
        ]
      }
    ]
  },
    {id: 46495862,
    title: 'Строймех',
    comments: [
      {
        "id": 45,
        "from_id": -199760602,
        "date": 1603815130,
        "text": "Строймех"
      },
      {
        "id": 46,
        "from_id": -199760602,
        "date": 1603815590,
        "text": "Тема: Расчет неразрезной балки с помощью уравнения трех моментов. Построение объемлющей эпюры",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571793577,
              "owner_id": 86065323,
              "title": "XLn4-zbqqNw.jpg",
              "size": 878391,
              "ext": "jpg",
              "date": 1603815477,
              "type": 4,
              "url": "https://vk.com/doc86065323_571793577?hash=34d433531bc46ae4f3&dl=GA:1603816022:b08ba66d8f13c2902c&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-58.userapi.com/c856324/u86065323/d7/-3/m_f76d4607e1.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-15.userapi.com/c856324/u86065323/d7/-3/s_f76d4607e1.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-69.userapi.com/c856324/u86065323/d7/-3/x_f76d4607e1.jpg",
                      "width": 604,
                      "height": 683,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-64.userapi.com/c856324/u86065323/d7/-3/y_f76d4607e1.jpg",
                      "width": 807,
                      "height": 912,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-4.userapi.com/c856324/u86065323/d7/-3/z_f76d4607e1.jpg",
                      "width": 1280,
                      "height": 1447,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856324/u86065323/d7/-3/o_f76d4607e1.jpg",
                      "width": 1911,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856324/u86065323/d7/-3/o_f76d4607e1.jpg?size=255x288&quality=90&sign=57cadce4296f873fac93edb4249cdbec&c_uniq_tag=ca8d_1Cgg7j1wg51GQbpYWhTpodN6fB_JWUEG2D0yg8",
                      "width": 255,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/impc/c856324/u86065323/d7/-3/o_f76d4607e1.jpg?size=170x192&quality=90&sign=b19fe4b4d4703d4a352b603a2aa911d7&c_uniq_tag=3f5m8iJGXws57sR6igdXHlSAQyOM3hImgXos73MN27U",
                      "width": 170,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "a6aa3336520ac2946f"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571793590,
              "owner_id": 86065323,
              "title": "aBlNih5L6c0.jpg",
              "size": 870408,
              "ext": "jpg",
              "date": 1603815481,
              "type": 4,
              "url": "https://vk.com/doc86065323_571793590?hash=136743b7525dff09c8&dl=GA:1603816022:80594747c6971b720a&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-76.userapi.com/c856324/u86065323/d8/-3/m_a91d649ebf.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856324/u86065323/d8/-3/s_a91d649ebf.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-48.userapi.com/c856324/u86065323/d8/-3/x_a91d649ebf.jpg",
                      "width": 604,
                      "height": 606,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-5.userapi.com/c856324/u86065323/d8/-3/y_a91d649ebf.jpg",
                      "width": 807,
                      "height": 810,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-63.userapi.com/c856324/u86065323/d8/-3/z_a91d649ebf.jpg",
                      "width": 1280,
                      "height": 1285,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-37.userapi.com/c856324/u86065323/d8/-3/o_a91d649ebf.jpg",
                      "width": 2152,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-37.userapi.com/impc/c856324/u86065323/d8/-3/o_a91d649ebf.jpg?size=287x288&quality=90&sign=8bdc1d015b84efbe7f709bfd1352f1cd&c_uniq_tag=0Qn09svvM8DzkPVaZbwS5okkSRdPiH4E7lnFbn4S06w",
                      "width": 287,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-37.userapi.com/impc/c856324/u86065323/d8/-3/o_a91d649ebf.jpg?size=191x192&quality=90&sign=30117d573c1c480782b7d5ff9a63375d&c_uniq_tag=m4aQhsmESYEUSlB9f4PDf1C7aRvTG3gBvpe0iJk0TzA",
                      "width": 191,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "3572fde21dd40f86d0"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571793596,
              "owner_id": 86065323,
              "title": "VwmSrmgXiGQ.jpg",
              "size": 859130,
              "ext": "jpg",
              "date": 1603815483,
              "type": 4,
              "url": "https://vk.com/doc86065323_571793596?hash=05cb073b6404582734&dl=GA:1603816022:95e5990f44c5f85eb4&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-26.userapi.com/c856324/u86065323/d13/-3/m_5c75c32a9a.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-19.userapi.com/c856324/u86065323/d13/-3/s_5c75c32a9a.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-43.userapi.com/c856324/u86065323/d13/-3/x_5c75c32a9a.jpg",
                      "width": 604,
                      "height": 841,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-11.userapi.com/c856324/u86065323/d13/-3/y_5c75c32a9a.jpg",
                      "width": 807,
                      "height": 1124,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-46.userapi.com/c856324/u86065323/d13/-3/z_5c75c32a9a.jpg",
                      "width": 1280,
                      "height": 1783,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-28.userapi.com/c856324/u86065323/d13/-3/o_5c75c32a9a.jpg",
                      "width": 1551,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-28.userapi.com/impc/c856324/u86065323/d13/-3/o_5c75c32a9a.jpg?size=207x288&quality=90&sign=e79d9a429c9f4bcdc8c28a69d497fd32&c_uniq_tag=Cqh9a3wnoegvDUXii9EjWiLSG2w4pZrM2zaFNXa7B4E",
                      "width": 207,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-28.userapi.com/impc/c856324/u86065323/d13/-3/o_5c75c32a9a.jpg?size=138x192&quality=90&sign=7f365f6be6d60d0489111802d8852a2e&c_uniq_tag=AuGDbgK11ZEiSycAF5N0iuomVMAVVLfKOr9TShzbz24",
                      "width": 138,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "3130d883a0356ccf49"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571793604,
              "owner_id": 86065323,
              "title": "CGmmeSXdDWY.jpg",
              "size": 794684,
              "ext": "jpg",
              "date": 1603815485,
              "type": 4,
              "url": "https://vk.com/doc86065323_571793604?hash=886f7ba2903cd16169&dl=GA:1603816022:a350f2381b61d2c0a6&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-70.userapi.com/c856324/u86065323/d5/-3/m_8ca5151bcf.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856324/u86065323/d5/-3/s_8ca5151bcf.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-54.userapi.com/c856324/u86065323/d5/-3/x_8ca5151bcf.jpg",
                      "width": 604,
                      "height": 741,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-39.userapi.com/c856324/u86065323/d5/-3/y_8ca5151bcf.jpg",
                      "width": 807,
                      "height": 990,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-3.userapi.com/c856324/u86065323/d5/-3/z_8ca5151bcf.jpg",
                      "width": 1280,
                      "height": 1570,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-45.userapi.com/c856324/u86065323/d5/-3/o_8ca5151bcf.jpg",
                      "width": 1761,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-45.userapi.com/impc/c856324/u86065323/d5/-3/o_8ca5151bcf.jpg?size=235x288&quality=90&sign=9b5d1c5180ff4fc1baa22fa6cb96e773&c_uniq_tag=eagoR_-44G9kNiKn8TUgYbepX0q_Ror_yX_3bT_60rs",
                      "width": 235,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-45.userapi.com/impc/c856324/u86065323/d5/-3/o_8ca5151bcf.jpg?size=157x192&quality=90&sign=29f0f64916f26d658b0d860412f2e35e&c_uniq_tag=W9YQLVkOT-e9IMzmsHHxlcEsmKphUtU1YvqWglcD_vU",
                      "width": 157,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "50bdfe945aee65f7cd"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571793581,
              "owner_id": 86065323,
              "title": "meJoWL_gjEE.jpg",
              "size": 867871,
              "ext": "jpg",
              "date": 1603815479,
              "type": 4,
              "url": "https://vk.com/doc86065323_571793581?hash=a7ac285329359948ed&dl=GA:1603816022:a700f5214f4d546c1f&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-76.userapi.com/c856324/u86065323/d14/-3/m_74dff88738.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-2.userapi.com/c856324/u86065323/d14/-3/s_74dff88738.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-23.userapi.com/c856324/u86065323/d14/-3/x_74dff88738.jpg",
                      "width": 604,
                      "height": 645,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-22.userapi.com/c856324/u86065323/d14/-3/y_74dff88738.jpg",
                      "width": 807,
                      "height": 861,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-45.userapi.com/c856324/u86065323/d14/-3/z_74dff88738.jpg",
                      "width": 1280,
                      "height": 1366,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-27.userapi.com/c856324/u86065323/d14/-3/o_74dff88738.jpg",
                      "width": 2024,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-27.userapi.com/impc/c856324/u86065323/d14/-3/o_74dff88738.jpg?size=270x288&quality=90&sign=f4196696edfcd3f00d804ed4b05eb849&c_uniq_tag=FCFMGLFwm_iAhCCFWiKWewPZWujWeLKKxnDG4Bajw6E",
                      "width": 270,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-27.userapi.com/impc/c856324/u86065323/d14/-3/o_74dff88738.jpg?size=180x192&quality=90&sign=422f67a7328caec8608a8ae25e042d0c&c_uniq_tag=bkGukrIIl5CLdz2P3FdICx4rfuPcSY3Nd5G7oJslBkc",
                      "width": 180,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "454c86f73ab5db0788"
            }
          }
        ]
      },
      {
        "id": 47,
        "from_id": -199760602,
        "date": 1603815725,
        "text": "Тема: Расчет фермы",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794256,
              "owner_id": 86065323,
              "title": "shndJyT3kCM.jpg",
              "size": 697989,
              "ext": "jpg",
              "date": 1603815722,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794256?hash=38bc80e0ebd0f6a92c&dl=GA:1603816022:0e69e93cb393a9e8a1&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-13.userapi.com/c856424/u86065323/d2/-3/m_184f656392.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-12.userapi.com/c856424/u86065323/d2/-3/s_184f656392.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/c856424/u86065323/d2/-3/x_184f656392.jpg",
                      "width": 604,
                      "height": 986,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-67.userapi.com/c856424/u86065323/d2/-3/y_184f656392.jpg",
                      "width": 807,
                      "height": 1318,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-69.userapi.com/c856424/u86065323/d2/-3/z_184f656392.jpg",
                      "width": 1280,
                      "height": 2090,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/c856424/u86065323/d2/-3/o_184f656392.jpg",
                      "width": 1323,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856424/u86065323/d2/-3/o_184f656392.jpg?size=176x288&quality=90&sign=f22037dd31e0a6df7291f165e0ca0c23&c_uniq_tag=Q0_3jzUvb68mlYZO3TCW4-iAH_B3w7HgXXdnlY5foyc",
                      "width": 176,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856424/u86065323/d2/-3/o_184f656392.jpg?size=118x192&quality=90&sign=12d2c92ae5cedfc25e35d87407ebf333&c_uniq_tag=AnvEeiLBHS_pkWeIzRD4UVer7mdudcFlMU409Y-WNYo",
                      "width": 118,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "8baa4d146ae204024f"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794261,
              "owner_id": 86065323,
              "title": "9nzLnqdVe7Q.jpg",
              "size": 807215,
              "ext": "jpg",
              "date": 1603815723,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794261?hash=b7717834e511e3f54a&dl=GA:1603816022:8e649c646507a4aaca&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-36.userapi.com/c856424/u86065323/d11/-3/m_4562a36e13.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-8.userapi.com/c856424/u86065323/d11/-3/s_4562a36e13.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-72.userapi.com/c856424/u86065323/d11/-3/x_4562a36e13.jpg",
                      "width": 604,
                      "height": 939,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-61.userapi.com/c856424/u86065323/d11/-3/y_4562a36e13.jpg",
                      "width": 807,
                      "height": 1254,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-53.userapi.com/c856424/u86065323/d11/-3/z_4562a36e13.jpg",
                      "width": 1280,
                      "height": 1989,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/c856424/u86065323/d11/-3/o_4562a36e13.jpg",
                      "width": 1390,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/impc/c856424/u86065323/d11/-3/o_4562a36e13.jpg?size=185x288&quality=90&sign=41b649c67baaf78efd0e6d42ac4dd10a&c_uniq_tag=dGDt19V0mK983uvriQRERpRWQgG296jwQYOVGCQPsIA",
                      "width": 185,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/impc/c856424/u86065323/d11/-3/o_4562a36e13.jpg?size=124x192&quality=90&sign=517b77abd8c15cd9ec39f435f38a7123&c_uniq_tag=7viKW1qEAWyqGRBAp9aabb3FGlOsEQQWlJTP07Jowhs",
                      "width": 124,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "ddb666e165efd1666c"
            }
          }
        ]
      },
      {
        "id": 48,
        "from_id": -199760602,
        "date": 1603815773,
        "text": "Тема: Расчет рамы методом перемещений",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794401,
              "owner_id": 86065323,
              "title": "-5Q7rJ3QNmU.jpg",
              "size": 857494,
              "ext": "jpg",
              "date": 1603815768,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794401?hash=42b4e4751961e574ff&dl=GA:1603816022:3bcd753c2a800de023&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-48.userapi.com/c856232/u86065323/d4/-3/m_b022ff4904.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-25.userapi.com/c856232/u86065323/d4/-3/s_b022ff4904.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-14.userapi.com/c856232/u86065323/d4/-3/x_b022ff4904.jpg",
                      "width": 604,
                      "height": 598,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856232/u86065323/d4/-3/y_b022ff4904.jpg",
                      "width": 807,
                      "height": 800,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-64.userapi.com/c856232/u86065323/d4/-3/z_b022ff4904.jpg",
                      "width": 1280,
                      "height": 1268,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/c856232/u86065323/d4/-3/o_b022ff4904.jpg",
                      "width": 2180,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/impc/c856232/u86065323/d4/-3/o_b022ff4904.jpg?size=288x285&quality=90&sign=f6e96cd8fb077412e7ce555523c006e8&c_uniq_tag=nky_-J6RzU1MW8ZIAXRj7QhplzvygJUoT0V5Z_kpQTw",
                      "width": 288,
                      "height": 285,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-20.userapi.com/impc/c856232/u86065323/d4/-3/o_b022ff4904.jpg?size=192x190&quality=90&sign=06971760a4f83c08f014feef5e5b913e&c_uniq_tag=IxK-act0dsGuRYvypl63po-ZQO6NaovJWr4aMfhz1qY",
                      "width": 192,
                      "height": 190,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "071fa355e650f41047"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794404,
              "owner_id": 86065323,
              "title": "tbdU2yMG0FE.jpg",
              "size": 846416,
              "ext": "jpg",
              "date": 1603815770,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794404?hash=179345e3dd3123d24a&dl=GA:1603816022:26d5f6967745fa52f7&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-3.userapi.com/c856232/u86065323/d9/-3/m_bd430b1cee.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-25.userapi.com/c856232/u86065323/d9/-3/s_bd430b1cee.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-12.userapi.com/c856232/u86065323/d9/-3/x_bd430b1cee.jpg",
                      "width": 604,
                      "height": 720,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/c856232/u86065323/d9/-3/y_bd430b1cee.jpg",
                      "width": 807,
                      "height": 961,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-22.userapi.com/c856232/u86065323/d9/-3/z_bd430b1cee.jpg",
                      "width": 1280,
                      "height": 1525,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/c856232/u86065323/d9/-3/o_bd430b1cee.jpg",
                      "width": 1813,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/impc/c856232/u86065323/d9/-3/o_bd430b1cee.jpg?size=242x288&quality=90&sign=b733e6ce5a03648a5497bea383bf9596&c_uniq_tag=6vC2DH6P4PLw99Z8N-blwPJssmdtCEdTzQ2TJgedfs8",
                      "width": 242,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-52.userapi.com/impc/c856232/u86065323/d9/-3/o_bd430b1cee.jpg?size=161x192&quality=90&sign=0358a518d4daea74b632caf87ac65910&c_uniq_tag=kgRQt-1IDlYV5N43pmCnbnLUpHzWnpzIU-qDvNgUWGE",
                      "width": 161,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "2e2cb4d091fe40ea2c"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794407,
              "owner_id": 86065323,
              "title": "5V8HU-z-CO0.jpg",
              "size": 908952,
              "ext": "jpg",
              "date": 1603815772,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794407?hash=94d6f1e7d2d55cbc58&dl=GA:1603816022:094d1cc83b9a4683d5&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-35.userapi.com/c856232/u86065323/d8/-3/m_39d2120f66.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-43.userapi.com/c856232/u86065323/d8/-3/s_39d2120f66.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-47.userapi.com/c856232/u86065323/d8/-3/x_39d2120f66.jpg",
                      "width": 604,
                      "height": 594,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-74.userapi.com/c856232/u86065323/d8/-3/y_39d2120f66.jpg",
                      "width": 807,
                      "height": 794,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-6.userapi.com/c856232/u86065323/d8/-3/z_39d2120f66.jpg",
                      "width": 1280,
                      "height": 1259,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-55.userapi.com/c856232/u86065323/d8/-3/o_39d2120f66.jpg",
                      "width": 2196,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-55.userapi.com/impc/c856232/u86065323/d8/-3/o_39d2120f66.jpg?size=288x283&quality=90&sign=e314ae617864b8b3a2f71e8babdc4416&c_uniq_tag=EQsgmm10tLVNjeOUHp979Uq1eGQh5FWby3mw_GMNbKo",
                      "width": 288,
                      "height": 283,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-55.userapi.com/impc/c856232/u86065323/d8/-3/o_39d2120f66.jpg?size=192x189&quality=90&sign=ac5cb104dde7b96870709b14e5997680&c_uniq_tag=ck8BidN3ws46QmCZ3n1t2RcwguEHOcWskx8Y-yl9JdQ",
                      "width": 192,
                      "height": 189,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "07e2f17ec3199e2d6f"
            }
          }
        ]
      },
      {
        "id": 49,
        "from_id": -199760602,
        "date": 1603815797,
        "text": "Тема: Расчет фермы методом сечений",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571794466,
              "owner_id": 86065323,
              "title": "Lkb9nAZ1YJk.jpg",
              "size": 851932,
              "ext": "jpg",
              "date": 1603815794,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794466?hash=9deef1894bfc545ee2&dl=GA:1603816022:119b91d75cf7fd5b9f&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-5.userapi.com/c856236/u86065323/d14/-3/m_ba80cb1ff9.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-27.userapi.com/c856236/u86065323/d14/-3/s_ba80cb1ff9.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-35.userapi.com/c856236/u86065323/d14/-3/x_ba80cb1ff9.jpg",
                      "width": 604,
                      "height": 684,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-4.userapi.com/c856236/u86065323/d14/-3/y_ba80cb1ff9.jpg",
                      "width": 807,
                      "height": 914,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-27.userapi.com/c856236/u86065323/d14/-3/z_ba80cb1ff9.jpg",
                      "width": 1280,
                      "height": 1450,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/c856236/u86065323/d14/-3/o_ba80cb1ff9.jpg",
                      "width": 1907,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856236/u86065323/d14/-3/o_ba80cb1ff9.jpg?size=254x288&quality=90&sign=970e34f9a84b65b9436fb64db812a75e&c_uniq_tag=H12Scbj7BOPT377oIzoL1WT7kXH59ImBmDsDg0aAT9A",
                      "width": 254,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-31.userapi.com/impc/c856236/u86065323/d14/-3/o_ba80cb1ff9.jpg?size=170x192&quality=90&sign=d991a5018973a7d1333a21067f3fee1c&c_uniq_tag=lbCdavGO0426v0yJxRqoPnw2pBkilK1-lPxF3oUG1Ss",
                      "width": 170,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "d2476042d713510fd3"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571794473,
              "owner_id": 86065323,
              "title": "LcDUaQYI7p4.jpg",
              "size": 769215,
              "ext": "jpg",
              "date": 1603815796,
              "type": 4,
              "url": "https://vk.com/doc86065323_571794473?hash=4f393cf2c269f9de0f&dl=GA:1603816022:dd44cb8e9bd6416a03&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-12.userapi.com/c856236/u86065323/d6/-3/m_d71bab0e8c.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-50.userapi.com/c856236/u86065323/d6/-3/s_d71bab0e8c.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-26.userapi.com/c856236/u86065323/d6/-3/x_d71bab0e8c.jpg",
                      "width": 604,
                      "height": 760,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-44.userapi.com/c856236/u86065323/d6/-3/y_d71bab0e8c.jpg",
                      "width": 807,
                      "height": 1016,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-72.userapi.com/c856236/u86065323/d6/-3/z_d71bab0e8c.jpg",
                      "width": 1280,
                      "height": 1611,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/c856236/u86065323/d6/-3/o_d71bab0e8c.jpg",
                      "width": 1716,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/impc/c856236/u86065323/d6/-3/o_d71bab0e8c.jpg?size=229x288&quality=90&sign=f6d3eae0f4589a45c2fb10d38342b368&c_uniq_tag=C-ZGSfBL2OLOv8KZE2x2hjSpCHYh9Lozek4NVsqUFV0",
                      "width": 229,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-51.userapi.com/impc/c856236/u86065323/d6/-3/o_d71bab0e8c.jpg?size=153x192&quality=90&sign=beac2dfab335736fcaa42e7b312afb00&c_uniq_tag=xTyp5G5f3Baxa8_xl1IyqSfsrBM8zijMqOvqRC-v-mE",
                      "width": 153,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "e76b2c3f8892b62e8a"
            }
          }
        ]
      },
      {
        "id": 54,
        "from_id": -199760602,
        "date": 1603815978,
        "text": "Тема: Определение перемещений в статически определимой раме",
        "attachments": [
          {
            "type": "doc",
            "doc": {
              "id": 571795025,
              "owner_id": 86065323,
              "title": "v0fKnyr718c.jpg",
              "size": 765408,
              "ext": "jpg",
              "date": 1603815975,
              "type": 4,
              "url": "https://vk.com/doc86065323_571795025?hash=be66deaefef5f4d083&dl=GA:1603816022:7f952fb5f0855c131b&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-3.userapi.com/c856328/u86065323/d16/-3/m_183c78e243.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-34.userapi.com/c856328/u86065323/d16/-3/s_183c78e243.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-55.userapi.com/c856328/u86065323/d16/-3/x_183c78e243.jpg",
                      "width": 604,
                      "height": 754,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-4.userapi.com/c856328/u86065323/d16/-3/y_183c78e243.jpg",
                      "width": 807,
                      "height": 1007,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-71.userapi.com/c856328/u86065323/d16/-3/z_183c78e243.jpg",
                      "width": 1280,
                      "height": 1597,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-41.userapi.com/c856328/u86065323/d16/-3/o_183c78e243.jpg",
                      "width": 1731,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-41.userapi.com/impc/c856328/u86065323/d16/-3/o_183c78e243.jpg?size=231x288&quality=90&sign=cb9d00563c4f5fb512f99c3a871da0dc&c_uniq_tag=2brmz1W1pdyfHYyTNUGTSE_E6gvInezjjZ7ujf_5cIg",
                      "width": 231,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-41.userapi.com/impc/c856328/u86065323/d16/-3/o_183c78e243.jpg?size=154x192&quality=90&sign=8b2ee8e13c8db9d8530dbe4174540859&c_uniq_tag=aFAMfBMhLN6Xc2kqTTQAL-kjnqn04bheV-xVqmEn3Xc",
                      "width": 154,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "95bf1402e5f00f5040"
            }
          },
          {
            "type": "doc",
            "doc": {
              "id": 571795030,
              "owner_id": 86065323,
              "title": "MABcGwmy7dM.jpg",
              "size": 844292,
              "ext": "jpg",
              "date": 1603815977,
              "type": 4,
              "url": "https://vk.com/doc86065323_571795030?hash=69409e24585e76fcfb&dl=GA:1603816022:1751ae97efe0625f76&api=1&no_preview=1",
              "preview": {
                "photo": {
                  "sizes": [
                    {
                      "src": "https://sun9-12.userapi.com/c856328/u86065323/d4/-3/m_625b42519a.jpg",
                      "width": 130,
                      "height": 100,
                      "type": "m"
                    },
                    {
                      "src": "https://sun9-29.userapi.com/c856328/u86065323/d4/-3/s_625b42519a.jpg",
                      "width": 100,
                      "height": 75,
                      "type": "s"
                    },
                    {
                      "src": "https://sun9-56.userapi.com/c856328/u86065323/d4/-3/x_625b42519a.jpg",
                      "width": 604,
                      "height": 692,
                      "type": "x"
                    },
                    {
                      "src": "https://sun9-63.userapi.com/c856328/u86065323/d4/-3/y_625b42519a.jpg",
                      "width": 807,
                      "height": 925,
                      "type": "y"
                    },
                    {
                      "src": "https://sun9-17.userapi.com/c856328/u86065323/d4/-3/z_625b42519a.jpg",
                      "width": 1280,
                      "height": 1468,
                      "type": "z"
                    },
                    {
                      "src": "https://sun9-17.userapi.com/c856328/u86065323/d4/-3/o_625b42519a.jpg",
                      "width": 1884,
                      "height": 2160,
                      "type": "o"
                    },
                    {
                      "src": "https://sun9-17.userapi.com/impc/c856328/u86065323/d4/-3/o_625b42519a.jpg?size=251x288&quality=90&sign=4e67148c0b5c4bb8fac40c43587d09e7&c_uniq_tag=hayqE6UcT-nGYg4w7RvIa_gU6pPJi8N7fNjv_ITDs_A",
                      "width": 251,
                      "height": 288,
                      "type": "i"
                    },
                    {
                      "src": "https://sun9-17.userapi.com/impc/c856328/u86065323/d4/-3/o_625b42519a.jpg?size=167x192&quality=90&sign=4a2c34ae1428296cc0cab82ec26c155f&c_uniq_tag=3YErIg15-lnGGmTqWOipLNjzm9vJzwIKjDletojuqi8",
                      "width": 167,
                      "height": 192,
                      "type": "d"
                    }
                  ]
                }
              },
              "access_key": "97b9516027fc11e054"
            }
          }
        ]
      }
    ]
  }],
  calculatorsList: [
    {
      id: 1,
      name: 'Математика',
      calculators: [
        {
          id: 1,
          name: 'Расчет производных',
          link: 'https://www.kontrolnaya-rabota.ru/s/proizvodnaya-funktsii/',
          about: 'Расчет производных',
          images: []
        }
      ]
    },
    {
      id: 2,
      name: 'Сопромат',
      calculators: [
        {
          id: 1,
          name: 'Построение эпюр в балке',
          link: 'https://sopromatguru.ru/',
          about: 'Построение эпюр в балке как статически определимой, так и статически неопределимой. Есть платные расчеты.',
          images: []
        },
        {
          id: 2,
          name: 'Расчет геом характеристик',
          link: 'https://sopromat.ueuo.com/index.php',
          about: 'Расчет прокатного профиля, составных и сложных фигур',
          images: []
        },
        {
          id: 3,
          name: 'Определение усилий в ферме',
          link: 'https://sopromatguru.ru/',
          about: 'Определение усилий в ферме или раме',
          images: []
        }
      ]
    },
  ],
  miniCards: [
    // { id: 1, title: 'Отзывы', image: 'reviews.png', panel: '' },
    // { id: 2, title: 'Работа', image: 'work.png', panel: '' },
    { id: 3, title: 'Уроки', image: 'lessons.png', panel: VIEWS.MAIN.PANELS.VIDEO_GALLERY, role: ROLES.ALL  },
    { id: 4, title: 'Тесты', image: 'tests.png', panel: VIEWS.MAIN.PANELS.TESTS, role: ROLES.ALL },
    { id: 5, title: 'Помощники', image: 'help.png', panel: '', epic: VIEWS.EPIC.PANELS.RELATIONSHIP, role: [ROLES.USER, ROLES.USER_ADMIN] },
    { id: 6, title: 'Партнерка', image: 'affiliate.png', panel: VIEWS.MAIN.PANELS.ABOUT_BONUS_SYSTEM, role: ROLES.ALL },
    { id: 7, title: 'Файлы', image: 'files.png', panel: VIEWS.MAIN.PANELS.FILES_GALLERY, role: ROLES.ALL },
    { id: 8, title: 'Спросить', image: 'ask.png', panel: '', modal: MODALS.CARD.ASK_ADMIN, role: ROLES.ALL },
  ],
  bigCards: [
    {
      id: 1,
      title: 'Видеоуроки',
      image: '/img/main/banner/sopromat.png',
      info: 'Уже более 65 уроков',
      about: 'Полезные видеоматериалы, записанные нашими репетиторами. На данный момент здесь собраны уроки в основном по техническим направлениям, но скоро будут добавлены и другие уроки, следите за обновлениями!',
      panel: VIEWS.MAIN.PANELS.VIDEO_GALLERY
    },
    {
      id: 2,
      title: 'Полезные материалы',
      image: '/img/main/banner/files.jpg',
      info: 'Скоро будет большая база материалов',
      about: 'Шаблоны, примеры работ, методички и многое другое! Если есть чем поделиться - просто напишите админу и мы добавим в наш каталог ваши файлы!',
      panel: VIEWS.MAIN.PANELS.FILES_GALLERY
    },
    // {
    //   id: 3,
    //   title: 'Калькуляторы',
    //   image: '/img/main/banner/calc.jpg',
    //   info: 'Расскажем где найти',
    //   about: 'Собираем базу калькуляторов полезных в учбе',
    //   panel: VIEWS.MAIN.PANELS.CALCULATORS
    // },
  ],
  selectedUser: null,
  selectedCategory: null,
  selectedCategoryCalculators: 0,
  selectedCalculator: 0,
  selectedTest: null,
  selectedPlaylist: null,
  selectedVideo: null,
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_VIDEO:
      return {
        ...state,
        selectedVideo: action.id
      }
    case SET_SELECTED_PLAYLIST:
      return {
        ...state,
        selectedPlaylist: action.id
      }
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.id
      }
    case SET_SELECTED_CATEGORY_FILE:
      return {
        ...state,
        selectedCategory: action.id
      }
    case SET_SELECTED_CATEGORY_CALCULATORS:
      return {
        ...state,
        selectedCategoryCalculators: action.id
      }
    case SET_SELECTED_CALCULATOR:
      return {
        ...state,
        selectedCalculator: action.id
      }
    case SET_SELECTED_TEST:
      return {
        ...state,
        selectedTest: action.id
      }
    default:
      return state;
  }
};

export const setSelectedVideo = (id) => ({
  type: SET_SELECTED_VIDEO,
  id
})

export const setSelectedPlaylist = (id) => ({
  type: SET_SELECTED_PLAYLIST,
  id
})

export const setSelectedUser = (id) => ({
  type: SET_SELECTED_USER,
  id
})

export const setSelectedCategory = (id) => ({
  type: SET_SELECTED_CATEGORY_FILE,
  id
})

export const setSelectedCategoryCalculators = (id) => ({
  type: SET_SELECTED_CATEGORY_CALCULATORS,
  id
})

export const setSelectedCalculator = (id) => ({
  type: SET_SELECTED_CALCULATOR,
  id
})

export const setSelectedTest = (id) => ({
  type: SET_SELECTED_TEST,
  id
})

export default mainReducer;
