import React, { useState }  from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import {Button, FormLayout, ModalPage, Textarea, Div, FormItem} from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
import CustomTabsItem from '../../../components/CustomTabs/CustomTabsItem'
import CustomTabs from '../../../components/CustomTabs'
import './PaymentSettings.css'
import {senMessageToTelegram} from "../../../lib/sendMessageToTelegram";
import {useDispatch} from "react-redux";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import roundBill from "../../../lib/mathUtilits/roundBill";
import Api from "../../../api/api";

const Index = ({ id, onClose, bill }) => {

  const [comment, setComment] = useState('')
  const [activeTab, setActiveTab] = useState('refusal')

  const handleChangeComment = (value) => {
    setComment(value)
  }
  const dispatch = useDispatch()

  const postRequest = () => {
    senMessageToTelegram({ bill: { taskId: bill.taskId, amount: bill.price, type: activeTab, comment } })
    onClose()
    dispatch(setSnackbar(<MySnackbar message={`Запрос на корректировку счета ${bill.taskId} отправлен, ожидайте изменений`}/>))
  }

  const payBill = (id) => {
    Api.profile.payThroughPartnerProgram(id).then( data => {
      onClose()
      data.data.data
        ? dispatch(setSnackbar(<MySnackbar message={`Счет оплачен за счет партнерки`}/>))
        : dispatch(setSnackbar(<MySnackbar message={`Недостаточно средств на балансе партнерки или счет уже был оплачен`}/>))
    })
  }

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title={`Счёт #${bill.taskId}`} modalBack={onClose} /> )}>
      <FormLayout className='PaymentSettings'>
        <div className='PaymentInfo'>
          <div>Сумма: {bill.price} ₽</div>
          <div>Комиссия: {bill.price} ₽ → { roundBill(bill.price * bill.commissionPercent) } ₽</div>
          <div className='PaymentInfo__Date'>Счет выставлен: { moment(bill.datetime).format('LLL') }</div>
        </div>

        <CustomTabs>
          <CustomTabsItem name='refusal' title='Отказ' activeTab={activeTab} handleSetActiveTab={setActiveTab} />
          <CustomTabsItem name='change' title='Изменение' activeTab={activeTab} handleSetActiveTab={setActiveTab} />
          <CustomTabsItem name='error' title='Ошибка' activeTab={activeTab} handleSetActiveTab={setActiveTab} />
        </CustomTabs>
        <FormItem>
          <Textarea defaultValue={comment} onChange={e => handleChangeComment(e.target.value)} name='comment' placeholder='Если изменилась сумма, сообщите новую здесь' />
        </FormItem>
        <div className='PaymentSettings__Button'>
          <Div><Button size='m' stretched mode="commerce" onClick={() => payBill(bill.id)}>Оплатить партнеркой</Button></Div>
          <Div><Button size='m' stretched mode='primary' onClick={postRequest}>Запросить корректировку</Button></Div>
        </div>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
