import React, {useEffect} from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import {Card, CardGrid, Group, Panel, SimpleCell, List, Div, Button, FixedLayout} from '@vkontakte/vkui'
import Icon28DocumentOutline from '@vkontakte/icons/dist/28/document_outline'
import Icon56DownloadOutline from '@vkontakte/icons/dist/56/download_outline';

import HeaderPanel from '../../../components/HeaderPanel'
import './RequestPage.css'

import VIEWS from '../../panels'
import Api, {BASE_URL} from "../../../api/api";
import showNativeGallery from "../../../lib/bridgeUtilits/showNativeGallery";
import {useDispatch, useSelector} from "react-redux";
import {getFullProfileUserData, REQUESTS_PROFILE_USER, setSnackbar} from "../../../redux/reducers/profile-reducer";
import {getProfileSnackbar, getRequestsProfileUser} from "../../../redux/selectors/profile-selectors";
import MODALS from "../../../modals/modals";
import {MySnackbar} from "../../../components/MySnackbar";

const Index = ({ id, backTo, request, goTo, showModal }) => {
  const { userId, desc, datetime, category, timeLimit, taskFiles } = request

  const dispatch = useDispatch()
  const snackbar = useSelector(getProfileSnackbar)

  const requestsProfileUser = useSelector(getRequestsProfileUser)

  useEffect(() => {
    dispatch(getFullProfileUserData(userId, REQUESTS_PROFILE_USER,true))
  },[userId])

  const imagesSrc = taskFiles.map(file => `${BASE_URL}/file/${file.fileName}`)

  const evaluateRequest = (info) => {
    if (info.number > 0 && Number.isInteger(info.number)) {
      Api.users.setPriceToTask(request.id, info.number, info.text).then(data => {
        data.data.data === 'Price sent' && dispatch(setSnackbar(<MySnackbar message={'Стоимость была отправлена'}/>))
      })
    } else {
      dispatch(setSnackbar(<MySnackbar message={'Вы ввели неверную стоимость, используйте только числа'}/>))
    }
  }

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.REQUESTS.PANELS.REQUESTS_LIST} backTo={backTo}>
        {category ? category.name : 'Без названия'}
      </HeaderPanel>

      <List>
        <Group separator='hide'>
          <Div>
            <Card mode='shadow'>
              <div className='RequestPage__Header'>
                <div className='RequestPage__Text'>{requestsProfileUser.nickname} #{userId}</div>
                <div className='RequestPage__Date'>
                  {moment(datetime).format('L') + ' в ' + moment(datetime).format('LT') + (timeLimit ? ' · ' + timeLimit : 'Сроки не указаны')}
                </div>
                <div className='RequestPage__Text'>{desc}</div>
              </div>
            </Card>
          </Div>
        </Group>

        <Group separator='hide' className='RequestPage__Files'>
          {taskFiles
            .filter(file => !['jpg', 'png', 'jpeg'].includes(file.fileName.split('.')[1]))
            .map( (file, i) => (
            <SimpleCell
              key={i}
              description={file.fileSize || 'Размер неизвестен'}
              before={<Icon28DocumentOutline />}
              after={<a href={`${BASE_URL}/file/${file.fileName}`} download target="_blank" rel="noopener noreferrer" ><Icon56DownloadOutline width={28} height={28}/></a>}>
              {file.fileName.split('/')[1]}
            </SimpleCell>
          ))}
          {taskFiles
            .filter(file => ['jpg', 'png', 'jpeg'].includes(file.fileName.split('.')[1]))
            .map((file, i) => (
              <Div key={i} onClick={() => {showNativeGallery(imagesSrc, i)}}>
                <Card size='l' mode='outline' className='RequestPage__Image'>
                    <img alt={file.fileName} src={`${BASE_URL}/file/${file.fileName}`} />
                </Card>
              </Div>
            ))
          }
        </Group>
      </List>

      <FixedLayout vertical='bottom'>
        <Div>
          <Div style={{display: 'flex'}}>
            <Button size="l" stretched style={{ marginRight: 8 }} onClick={() => showModal(MODALS.CARD.CALC_INPUT_NUMBER_WITH_TEXT, {func: evaluateRequest})}>Оценить</Button>
            <Button size="l" stretched mode="secondary" onClick={() => goTo(VIEWS.REQUESTS.PANELS.PROFILE)}>Профиль</Button>
          </Div>
        </Div>
      </FixedLayout>

      {snackbar}

    </Panel>
  )
}

export default Index
