import React, {useState} from 'react'
import {View} from '@vkontakte/vkui'

import Main from './Main/index.js'
import AboutBonusSystem from './AboutBonusSystem'
import GalleryList from './GalleryList'
import CategoriesList from './CategoriesList'
import Profile from '../Profile/ProfileInfo'
import VideoList from './GalleryList/VideoList'
import VideoPage from './GalleryList/VideoPage'
import CommentsList from './CommentsList'
import VIEWS from '../panels'
import {useDispatch, useSelector} from "react-redux";
import {goBackPanel} from "../../redux/reducers/views-reducer";
import {getMainProfileUser} from "../../redux/selectors/profile-selectors";
import {getMainViews} from "../../redux/selectors/view-selectors";
import {
    getCalculatorsList,
    getPlaylist,
    getSelectedCalculator,
    getSelectedCategory,
    getSelectedCategoryCalculators,
    getUsefulFilesItems
} from "../../redux/selectors/main-selectors";
import Feedback from "../Feedback/Feedback";
import AllFeedback from "../AllFeedback/AllFeedback";
import {setSnackbar} from "../../redux/reducers/profile-reducer";
import Tests from "../Tests/Tests";
import Test from "../Tests/Test/Test";
import TestResult from "../Tests/TestResult/TestResult";
import CreateTest from "../Tests/CreateTest/CreateTest";
import AddQuestionsToTest from "../Tests/AddQuestionsToTest/AddQuestionsToTest";

const Index = ({id, showModal}) => {

  const dispatch = useDispatch()

  const { activePanel, history } = useSelector(getMainViews)

  const mainProfileUser = useSelector(getMainProfileUser)

  const playlist = useSelector(getPlaylist)
  const usefulFilesItems = useSelector(getUsefulFilesItems)
  const selectedCategoryFile = useSelector(getSelectedCategory)
  const calculatorsList = useSelector(getCalculatorsList)
  const selectedCategoryCalculators = useSelector(getSelectedCategoryCalculators)
  const selectedCalculator = useSelector(getSelectedCalculator)

  const goBack = () => {
    dispatch(setSnackbar(null))
    dispatch(goBackPanel(VIEWS.MAIN.ID, history[history.length - 2], history.length))
  }

  const [popout, setPopout] = useState()

  return (
      <View id={id} activePanel={activePanel} onSwipeBack={goBack} history={history} popout={popout}
            style={activePanel === VIEWS.MAIN.PANELS.TEST ? {zIndex: 3} : {}}>
        <Main id={VIEWS.MAIN.PANELS.HOME} header='Главная' showModal={showModal}/>

        <AboutBonusSystem id={VIEWS.MAIN.PANELS.ABOUT_BONUS_SYSTEM} header='Партнерка' backTo={goBack} />

        <Tests id={VIEWS.MAIN.PANELS.TESTS} header='Тесты' backTo={goBack} setPopout={setPopout} />
        <Test id={VIEWS.MAIN.PANELS.TEST} header='Тест' backTo={goBack} setPopout={setPopout} showModal={showModal} />
        <TestResult id={VIEWS.MAIN.PANELS.TEST_RESULT} header='Результаты' backTo={goBack} />
        <CreateTest id={VIEWS.MAIN.PANELS.CREATE_TEST} header='Создать тест' backTo={goBack} />
        <AddQuestionsToTest id={VIEWS.MAIN.PANELS.ADD_QUESTIONS_TO_TEST} header='Добавить вопросы' backTo={goBack} />

        <GalleryList id={VIEWS.MAIN.PANELS.VIDEO_GALLERY} header='Видеоуроки' backTo={goBack} list={playlist}/>
        <VideoList id={VIEWS.MAIN.PANELS.VIDEO_LIST} header='Видеоуроки' backTo={goBack} />
        <VideoPage id={VIEWS.MAIN.PANELS.VIDEO_PAGE} header='Видеоуроки' backTo={goBack} />

        <CategoriesList id={VIEWS.MAIN.PANELS.FILES_GALLERY} header='Материалы' backTo={goBack} list={usefulFilesItems}/>
        <CommentsList id={VIEWS.MAIN.PANELS.COMMENTS_LIST} selectedCategory={selectedCategoryFile} backTo={goBack} />

        {/*<CalculatorsList id={VIEWS.MAIN.PANELS.CALCULATORS} header='Калькуляторы' backTo={goBack} list={calculatorsList}/>*/}
        {/*<CalculatorsPage id={VIEWS.MAIN.PANELS.CALCULATORS_PAGE} header='Калькуляторы' backTo={goBack} list={calculatorsList[selectedCategoryCalculators].calculators}/>*/}
        {/*<CalculatorPage id={VIEWS.MAIN.PANELS.CALCULATOR_PAGE} header='Калькулятор' backTo={goBack} calculator={calculatorsList[selectedCategoryCalculators].calculators[selectedCalculator]}/>*/}

        <Profile id={VIEWS.MAIN.PANELS.PROFILE} profile={mainProfileUser} header='Профиль' backTo={goBack} showModal={showModal}/>

        <AllFeedback id={VIEWS.MAIN.PANELS.ALL_FEEDBACK} header='Отзывы' backTo={goBack}/>
        <Feedback id={VIEWS.MAIN.PANELS.FEEDBACK} profile={mainProfileUser} header='Отзыв' backTo={goBack} showModal={showModal}/>

      </View>
  )
}

export default Index
