export const senMessageToTelegram = (props = {}) => {
  const { message, affiliate, bill } = props
  const url = `https://api.telegram.org/bot1196837194:AAG0SnGx0GJ200_5Fpx-LOUmD4DHwNGejHk/sendMessage?chat_id=-1001266033517&text=`

  if (message) {
    fetch(url + message)
  } else if (affiliate) {
    const {sum, fetchedUserId} = affiliate
    const text = `Вывод суммы: ${sum}₽, пользователь: ${fetchedUserId}`

    fetch(url + text)
  } else if (bill) {
    const {taskId, amount, type, comment} = bill
    const nameType = {refusal: 'Отказ', change: 'Изменение суммы', error: 'Ошибочный счет'}

    const commentOfConsultant = comment ? `, Комментарий консультанта: ${comment}` : ''
    const text = `Корректировка по счету: ${taskId}, Сумма: ${amount}, Тип изменения: ${nameType[type]} ${commentOfConsultant}`
    fetch(url + text)
  }
}