import React, {useRef, useState} from 'react'
import {
  Panel,
  FormLayout,
  SelectMimicry,
  Textarea,
  File,
  Div,
  Button,
  Group,
  CardScroll,
  Card,
  CardGrid, SimpleCell
} from '@vkontakte/vkui'
import Icon28DocumentOutline from '@vkontakte/icons/dist/28/document_outline'

import HeaderPanel from '../../../components/HeaderPanel'
import './PublishRequest.css'

import VIEWS from '../../panels'
import MODALS from '../../../modals/modals'
import {useDispatch, useSelector} from "react-redux";
import PreviewImageList from "../../Chat/Dialog/DialogWriteBar/PreviewImageList";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import Icon24Dismiss from "@vkontakte/icons/dist/24/dismiss";
import showNativeGallery from "../../../lib/bridgeUtilits/showNativeGallery";
import Api, {BASE_URL} from "../../../api/api";
import Icon56DownloadOutline from "@vkontakte/icons/dist/56/download_outline";
import Icon28CancelCircleOutline from '@vkontakte/icons/dist/28/cancel_circle_outline';
import ButtonFixedBottom from "../../../components/ButtonFixedBottom";
import getReadableFileSize from "../../../lib/getReadableFileSize";
import {setSelectedTiming} from "../../../redux/reducers/tutors-reducer";
import {goToPanel} from "../../../redux/reducers/views-reducer";

const images = [ { id: 1, url: '' }, { id: 2, url: '' }, ]

const Index = (props) => {
  const { id, header, category, defaultValue, backTo, showModalPage } = props

  const timing = useSelector( state => state.tutorsReducer.selectedTiming)

  const [text, setText] = useState('')
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])

  const fileInput  = useRef(null)

  const filesLimit = 10

  const dispatch = useDispatch()

  const attachFiles = () => {
    if (images.length + Object.values(fileInput.current.files).length <= filesLimit) {
      const files = Object.values(fileInput.current.files)

      for (const file of files) {
        const reader = new FileReader()
        if (file && file.type.match('image.*')) {
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            setImages(images => [...images, reader.result])
          }
        } else {
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            setFiles(files => [...files, {name: file.name, size: file.size, url: reader.result}])
          }
        }
      }
    } else {
      dispatch(setSnackbar(<MySnackbar message={`Не более ${filesLimit} фалов за раз`}/>))
    }
  }

  const deleteImage = (imageIndex) => {
    setImages(images.filter((x, index) => index !== imageIndex))
  }

  const deleteFile = (fileIndex) => {
    setFiles(files.filter((x, index) => index !== fileIndex))
  }

  const sendTask = () => {
    const filesUrl = files.map(file => file.url)
    const media = [...filesUrl, ...images]
    Api.tasks.sendTask(category.id, timing, text, media).then(({data}) => {
      if (data.data === 'Task sended') {
        dispatch(setSelectedTiming(null))
        dispatch(goToPanel(VIEWS.EPIC.ID, VIEWS.EPIC.PANELS.MAIN))
        dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.HOME))
        dispatch(goToPanel(VIEWS.TUTORS.ID, VIEWS.TUTORS.PANELS.CATEGORIES))
        dispatch(setSnackbar(<MySnackbar message={'Ваша заявка опубликована, ожидайте ответа консультантов'}/>))
      }
    })
  }

  return (
    <Panel id={id} className='PublishRequest'>
      <HeaderPanel panel={VIEWS.TUTORS.PANELS.EXECUTORS} backTo={backTo}>
        {header}
      </HeaderPanel>

      <FormLayout>
        <SelectMimicry
          top='Категория'
          placeholder={defaultValue}
          // onClick={() => showModalPage(MODALS.PAGE.CATEGORIES)}
          onClick={() => {}}
        >
          {category.name}
        </SelectMimicry>

        <SelectMimicry
          top='Срок'
          placeholder='Выбрать срок'
          onClick={() => showModalPage(MODALS.PAGE.TIMING)}
        >
          {timing}
        </SelectMimicry>

        <Textarea top='Комментарий' value={text} onChange={e => setText(e.target.value)} placeholder='Оставьте комментарий...' />

        <File className='PublishRequest__FileInput'
              top='Дополнительные материалы'
              getRef={fileInput}
              // accept='image/*,.pdf'
              onChange={attachFiles}
              multiple
              before={<Icon28DocumentOutline width={16} height={16} />} mode='secondary' controlSize='m'>
          ПРИКРЕПИТЬ
        </File>

        <Group separator="hide" className={'PublishRequest__Media'}>
          <CardScroll>
            { images.map((img,i) => <Card key={i} size="m" style={{ overflow: 'hidden' }}>
              <div style={{ width: 96, height: 96, backgroundImage: `url(${img})` }} />
              <Icon24Dismiss className='RemoveImage' width={18} height={18} onClick={() => deleteImage(i)} />
            </Card> )}
          </CardScroll>
          <div className={'PublishImage__FileList'}>
            { files && files
              .map( (file, i) => (
                <SimpleCell key={i}
                            description={getReadableFileSize(file.size) || 'Размер неизвестен'}
                            before={<Icon28DocumentOutline />}
                            after={<Icon28CancelCircleOutline onClick={() => deleteFile(i)} width={28} height={28}/>}
                >
                  {file.name}
                </SimpleCell>
              ))}
          </div>
        </Group>

      </FormLayout>

      {/*<PreviewImageList images={images} deleteImage={deleteImage}/>*/}
      <ButtonFixedBottom text='Опубликовать заявку' handleClick={sendTask} />

    </Panel>
  )
}

export default Index
