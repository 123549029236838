import {useEffect, useRef, useState} from "react";
import getUserByBridgeInfo from "../lib/getUserByBridgeInfo";
import {useDispatch, useSelector} from "react-redux";
import {setPopout} from "../redux/reducers/views-reducer";
import {
  convertUserInfoProperties,
  CURRENT_USER,
  setUserData,
  setUserDataProperties
} from "../redux/reducers/profile-reducer";
import parseQueryString from "../lib/parseQueryString";
import {getCurrentUserId} from "../redux/selectors/profile-selectors";
import bridgeGetUserInfo from "../lib/bridgeUtilits/bridgeGetUserInfo";

function useCurrentUser() {

  const dispatch = useDispatch()
  const [ userBridgeInfo, setUserBridgeInfo ] = useState(null)

  useEffect(() => {
    bridgeGetUserInfo().then( user => {
      setUserBridgeInfo(user)
      dispatch(setUserDataProperties(CURRENT_USER, 'vkId', user.id))
    })
  },[])

  const hashParams = useRef(parseQueryString(window.location.hash))
  const searchParams = useRef(parseQueryString(window.location.search))

  const ref = hashParams.current.ref ? hashParams.current.ref : 0
  const userId = useSelector(getCurrentUserId)

  useEffect(() => {
    !userId && userBridgeInfo && getUserByBridgeInfo(userBridgeInfo, ref, searchParams).then(userGbgInfo => {
        dispatch(setUserData(CURRENT_USER, convertUserInfoProperties(userGbgInfo)))
        dispatch(setPopout(null))
      })
  }, [userId, userBridgeInfo])
}

export default useCurrentUser