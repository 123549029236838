import VIEWS from '../panels'
import MODALS from '../../modals/modals'

const ACTIONS = {
  OPEN_PROFILE: VIEWS.TUTORS.PANELS.PROFILE,
  TERMS_COOPERATION: MODALS.CARD.TERMS_COOPERATION,
  TUTORING: 'tutoring',
  RECOMMENDATION: 'recommendation',
  FEEDBACK: VIEWS.CHAT.PANELS.FEEDBACK,
  SHARE: 'share',
  COPY_LINK: 'copyLink',
  ISSUE_INVOICE: MODALS.CARD.CALC_INPUT_DATA,
}

export default ACTIONS
