import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'
import Icon16Fire from '@vkontakte/icons/dist/16/fire'

import './ProInfo.css'
import {useSelector} from "react-redux";
import MODALS from "../../modals";
import {
  getCurrentUserCommissionPercent,
  getCurrentUserLevel,
  getCurrentUserTop
} from "../../../redux/selectors/profile-selectors";

const Index = ({ id, onClose, showModal }) => {

  const percent = useSelector(getCurrentUserCommissionPercent)
  const isTop = useSelector(getCurrentUserTop)
  const levelExecutor = useSelector(getCurrentUserLevel)
  const status = levelExecutor && levelExecutor.status

  const messageCard = (levelExecutor) => {
    switch (levelExecutor) {
      case 'НОВИЧОК':
        return 'Очень рады, что вы с нами!'
      case 'MIDDLE':
        return 'Ваш уровень уже выше, комиссия стала меньше!'
      case 'ПРОФИ':
        return 'Вы совсем ПРОФИ! Вам больше доверяют клиенты и администраторы!'
      case 'VIP':
        return 'Ваш статус VIP, мы рады что вы дошли до этих высот!'
      default: break
    }
  }

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      icon={ isTop && <Icon16Fire style={{ color: '#f05c44' }} width={56} height={56} />}
      actions={[
        <Button key={'yet'} onClick={() => showModal(MODALS.CARD.PROFILE_PRO_INFO_ABOUT_OF_SYSTEM)} mode='secondary'>Подробнее</Button>,
        <Button key={'ok'} onClick={() => onClose()} mode='primary'>Оки</Button>
      ]}
    >
      <div className='ModalCardProInfo-Title'>
        { status }
      </div>
      <div className='ModalCardProInfo-Text'>
        { <span>{messageCard(status)} <br/> Актуальный процент: { percent ? percent : '?' }%</span> }
      </div>
    </ModalCard>
  )
}

export default Index
