import React from 'react'
import {Root, Spinner} from '@vkontakte/vkui'
import '@vkontakte/vkui/dist/vkui.css'

import VIEWS from './panels/panels'

import Intro from './panels/Intro'
import EpicPanel from './panels/EpicPanel'
import Modals from './modals'

import {useDispatch, useSelector} from "react-redux";
import useCurrentUser from "./useEffects/useCurrentUser";
import {openModalThunk, setActiveModal} from "./redux/reducers/views-reducer";
import useSubscribeScheme from "./useEffects/useSubscribeScheme";
import useNotificationInfo from "./useEffects/useNotificationInfo";
import useParams from "./useEffects/useParams";
import {getCurrentUserId} from "./redux/selectors/profile-selectors";
import {getActiveStory, getActiveView, getModal} from "./redux/selectors/view-selectors";

const App = () => {

  const dispatch = useDispatch()

  useSubscribeScheme()
  useNotificationInfo()
  useParams()

  // useDefaultUser()
  useCurrentUser()

  const currentUserId = useSelector(getCurrentUserId)

  const { activeModal, modalHistory, payload } = useSelector(getModal)
  const activeStory = useSelector(getActiveStory)
  const activeView  = useSelector(getActiveView)

  const modalBack = () => {
    dispatch(setActiveModal(null))
    showModal(modalHistory[modalHistory.length - 2])
  }

  const showModal = (modalName, data = null) => {
    dispatch(openModalThunk(modalName, data, modalHistory))
  }

  const modals = <Modals activeModal={activeModal} data={payload} showModal={showModal} onClose={modalBack}/>

  return (
    currentUserId && activeStory ?
      <Root activeView={activeView} modal={modals}>
        <Intro id={VIEWS.INTRO.ID} />
        <EpicPanel id={VIEWS.EPIC.ID} showModal={showModal} currentUserId={currentUserId} activeStory={activeStory}/>
      </Root> : <Spinner size='small'/>
  )
}

export default App

