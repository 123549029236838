import React, {useEffect, useState, useRef} from 'react'
import {Panel, Group, Header, Cell, Switch, List, Placeholder} from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'

import HeaderPanel from '../../../components/HeaderPanel'
import ChangeData from './ChangeData.js'
import './Settings.css'
import VIEWS from '../../panels'
import {useDispatch, useSelector} from "react-redux";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';
import Icon28AddCircleOutline from '@vkontakte/icons/dist/28/add_circle_outline';
import {getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import {setNotification} from "../../../redux/reducers/global-reducer";
import {getGlobalNotification} from "../../../redux/selectors/global-selectors";

const Index = ({ id, header, profile, backTo, selectCity, showModal }) => {

  const dispatch = useDispatch()

  const snackbar = useSelector(getProfileSnackbar)
  const notification = useSelector(getGlobalNotification)

  const [ supportedAddToHomeScreenInfo, setSupportedAddToHomeScreenInfo ] = useState(false)

  const handleChangeNotify = () => {

    const methodVk = notification ? 'VKWebAppDenyNotifications' : 'VKWebAppAllowNotifications'
    const message = notification ? 'Уведомления отключены' : 'Уведомления включены'

    bridge.send(methodVk).then(response => {
      if (response.result) {
        dispatch(setSnackbar(<MySnackbar message={message}/>))
      }
    })
  }

  const addToPageScreen = () => {
    if (supportedAddToHomeScreenInfo) {
      bridge.send("VKWebAppAddToHomeScreen").then(response => console.log(response.result))
      dispatch(setSnackbar(<MySnackbar message={'Устанавливаем'}/>))
    } else {
      dispatch(setSnackbar(<MySnackbar message={'Добавление на главный экран невозможно на вашей ОС'}/>))
    }
  }

  useEffect(() => {
    bridge.send("VKWebAppAddToHomeScreenInfo").then( response => {
      response.is_feature_supported && setSupportedAddToHomeScreenInfo(true)
    })
  }, [])

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.PROFILE.PANELS.PROFILE_INFO} backTo={backTo}>
        {header}
      </HeaderPanel>

      <Group>
        <List className='SettingsList'>
          <Group
            className='SettingsNotification'
            header={<Header mode='secondary'>Основные настройки</Header>}
            description='Вы будете получать только важные уведомления.'
            separator='hide'
            mode='plain'
          >
            <Cell after={<Switch value={notification} onChange={handleChangeNotify} checked={notification}/>}>
              Уведомления
            </Cell>
          </Group>
          { supportedAddToHomeScreenInfo && <Group mode='plain'
            className='SettingsNotification'
            description='Разместить приложение на рабочем столе телефона.'
            separator='hide'
          >
            <Cell after={<Icon28AddCircleOutline fill={'var(--text_link)'} onClick={() => addToPageScreen()}/>}>
              Добавить на рабочий стол
            </Cell>
          </Group> }

          <ChangeData profile={profile} selectCity={selectCity} backTo={backTo} showModal={showModal}/>

        </List>

        <Placeholder
          icon={<Icon28InfoOutline />}
          className={'SettingsPlaceholder'}
        >
          Версия приложения 1.0
          <br/>
          <br/> Фронтэнд - <a href="https://vk.com/gibelgauz" target='_blank'>Александр Гибельгауз</a>
          <br/> Бэкэнд - <a href="https://vk.com/id187334777" target='_blank'>Антон Сербаев</a>
        </Placeholder>

        { snackbar }
      </Group>

    </Panel>
  )
}

export default Index
