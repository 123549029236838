import React, {useEffect, useState} from 'react'

import {Alert, Card, Div, Group, Panel} from '@vkontakte/vkui'
import {useDispatch, useSelector} from "react-redux";
import {getProfileSnackbar} from "../../redux/selectors/profile-selectors";
import HeaderPanel from "../../components/HeaderPanel";
import {setSelectedTest} from "../../redux/reducers/main-reducer";
import Api, {BASE_URL} from "../../api/api";
import {goToPanel} from "../../redux/reducers/views-reducer";
import VIEWS from "../panels";
import {MySnackbar} from "../../components/MySnackbar";
import {setSnackbar} from "../../redux/reducers/profile-reducer";
import './Tests.css'

const Tests = ({ id, header, backTo, setPopout}) => {

  const snackbar = useSelector(getProfileSnackbar)
  const dispatch = useDispatch()

  const [tests, setTests] = useState([])

  const popoutBegin = (card) => {
    const goToTest = () => {
      dispatch(setSelectedTest(card.id))
      dispatch(goToPanel(VIEWS.MAIN.ID, card.panel))
    }

    return <Alert actions={[{ title: 'Отмена', autoclose: true, mode: 'destructive', action: setPopout(null) },
      { title: 'Начать', autoclose: true, mode: 'default', action: goToTest }]}
                  actionsLayout="horizontal"
                  onClose={() => setPopout(null)}
                  header={"Начало тестирования"}
                  text={`Вы уверены, что хотите начать тест "${card.info}"? Следующая попытка будет доступна через неделю!`}/>
  }

  const func = (card) => {
    setPopout(popoutBegin(card))
  }

  const date = new Date()

  useEffect(() => {
    Api.tests.getCategoriesWithTests().then(({data:categories}) => {
      categories && categories.data && categories.data.map(category => {
        category.id && Api.tests.getTestsByCategory(category.id, 1).then(({data}) => {
          const testsAfterSelector = data.data && data.data.map(test => {

            const ms = test.passedTests && +date - Date.parse(test.passedTests[0].datetime.replace(/-/g, '/'))
            const daysBlockMs = 86400000/144
            // const daysBlockMs = 86400000000/144

            return {
              id: test.idTest,
              title: category.name,
              image: test.img && `${BASE_URL}/file/${test.img}`,
              info: test.testName,
              about: test.about,
              panel: VIEWS.MAIN.PANELS.TEST,
              isShow: test.passedTests ? ms > daysBlockMs : true,
              result: test.passedTests ? (test.passedTests[0].score + ' из ' + test.passedTests[0].testScore) : ''
            }
          })
          testsAfterSelector && setTests(tests => [...tests, ...testsAfterSelector])
        })
      })
    })
  },[])

  return (
    <Panel id={id}>

      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>

      <Group separator='hide'>
        {/*<Div>*/}
        {/*  <Button size='m' stretched onClick={()=>dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.CREATE_TEST))}>Создать тест</Button>*/}
        {/*</Div>*/}
        <Div >
          {
            tests.map(test => {

              const gray = !test.isShow ? {filter: 'grayscale(100%)', opacity: 0.7 } : {opacity: 0.99999}
              const isShowLines = !test.isShow && 'MyTestCard__lines'

              return <div className={'MyTestCard'} key={test.id}>
                <div style={ gray }>
                  <Card size='l' mode='shadow' className='MyCard' >
                    <div className={isShowLines.toString()} onClick={() => dispatch(setSnackbar(<MySnackbar message={'Тест был пройден менее недели назад'}/>))}/>
                    <div className='MyCard__card'  onClick={() => test.isShow ? func(test) : dispatch(setSnackbar(<MySnackbar message={'Тест был пройден менее недели назад'}/>))}>
                      { test.image && <div className='MyCard__image' style={ { backgroundImage: `url(${test.image})` }}/> }
                      <div className='MyCard__content'>
                        <div className='MyCard__date'>
                          {test.info}
                        </div>
                        <div className='MyCard__title'>{test.title}</div>
                        <div className='MyCard__text'>
                          {test.about}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                {
                  test.result
                      ? <div className={'MyTestCard__result'}>
                          Верно: { test.result }
                        </div>
                      : <div className={'MyTestCard__try'}>
                          Не пройдено
                        </div>
                }
              </div>
            })
          }
        </Div>
      </Group>

      {snackbar}
    </Panel>
  )
}

export default Tests
