import React from 'react'
import 'moment/locale/ru'
import {Div, Group, Panel} from '@vkontakte/vkui'

import HeaderPanel from '../../../../components/HeaderPanel'
import classes from './VideoPage.module.css'
import VideoCard from "../../../../components/VideoCard";

import VIEWS from '../../../panels'
import {useSelector} from "react-redux";
import {getSelectedPlaylist, getSelectedVideo} from "../../../../redux/selectors/main-selectors";

const Index = ({ id, backTo }) => {

  const selectedPlaylist = useSelector(getSelectedPlaylist)
  const selectedVideo = useSelector(getSelectedVideo)
  const video = useSelector( state => state.mainReducer.playlist[selectedPlaylist].videos[selectedVideo])

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.REQUESTS.PANELS.VIDEO_PAGE} backTo={backTo}>
        Видеоурок
      </HeaderPanel>

      <Group className={classes.group} >
        <Div className={classes.cardGrid} >
          <VideoCard video={video} selectedVideo={true}/>
        </Div>
      </Group>

    </Panel>
  )
}

export default Index
