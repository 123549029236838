const trimString = (string, linelength) => {
  let newString

  if (string.length < ( linelength + 3 )){
    newString = string
  } else {
    newString = string.slice(0, linelength).trim() + '...'
  }
  return newString
}


export default trimString