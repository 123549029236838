import React from 'react'
import {Group, Panel, Div} from '@vkontakte/vkui'

import HeaderPanel from "../../../components/HeaderPanel";
import Comment from "../../../components/Comment";
import {useSelector} from "react-redux";

const Index = ({id, backTo, selectedCategory }) => {

  const category = useSelector( state => state.mainReducer.items[selectedCategory])
  const comments = useSelector( state => state.mainReducer.items[selectedCategory].comments)

  return (
    <Panel id={id}>
      <HeaderPanel backTo={backTo}>
        { category.title }
      </HeaderPanel>
      <Group>
        <Div>
          {
            comments && comments.map( (comment, i) => i !==0 && <Comment key={i} comment={comment}/>)
          }
        </Div>
      </Group>
    </Panel>
  )
}

export default Index
