import React from 'react'
import { Button, Div, FixedLayout } from '@vkontakte/vkui'

const ButtonFixedBottom = ({ text, mode = 'primary', handleClick }) => (
  <FixedLayout vertical='bottom'>
    <Div>
      <Button size='l'
              mode={mode}
              stretched
              style={mode === 'secondary' ? { background: 'var(--button_muted_background)'} : {}}
              onClick={() => handleClick()}>
        {text}
      </Button>
    </Div>
  </FixedLayout>
)

export default ButtonFixedBottom
