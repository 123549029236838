import React from 'react'
import {Button, ModalCard, UsersStack} from '@vkontakte/vkui'

import './CardTest.css'
import Api from '../../../api/api'
import VIEWS from '../../../panels/panels'

const Index = ({ id, course, onClose, goTest }) => {
  const recommendConsult = course.users.map(x => Api.avatars.getUrlByImgName(x.img_name))

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      header={course.title}
      actions={[
        <Button key={'share'} mode='secondary' onClick={() => {}}>Поделиться</Button>,
        <Button key={'go'} mode='primary' onClick={() => goTest(VIEWS.MAIN.PANELS.TEST)}>Пройти</Button>,
      ]}
    >
      <div className='ModalCardTest-Text'>
        {course.text}
      </div>

      <UsersStack
        photos={recommendConsult}
        size='m'
        count={3}
        layout='vertical'
      >
        {course.users.map(x => x.name).join(', ') }
        {course.users.length > 3 && ` и ещё ${course.users.length - 3} человек`}
      </UsersStack>
    </ModalCard>
  )
}

export default Index
