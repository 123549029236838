import React, {useState} from 'react'
import {View} from '@vkontakte/vkui'

import DialogList from './DialogList'
import Dialog from './Dialog'
import Feedback from './Feedback'
import ActionSheets from './ActionSheets'

import VIEWS from '../panels'
import ACTIONS from './actionsChat'
import {useDispatch, useSelector} from "react-redux";
import Profile from "../Profile/ProfileInfo";
import useDialogsList from "../../useEffects/useDialogsList";
import {goBackPanel, goToPanel, setActivePanel} from "../../redux/reducers/views-reducer";
import Api from "../../api/api";
import {convertDialogList, setDialogsList} from "../../redux/reducers/chats-reducer";
import {
  getChatProfileUser,
  getCurrentUserId,
  getCurrentUserRole,
} from "../../redux/selectors/profile-selectors";
import {getChat} from "../../redux/selectors/view-selectors";
import {getChatPagePopout, getChatReducer} from "../../redux/selectors/chats-selectors";
import shareLink from "../../lib/bridgeUtilits/shareLink";
import showFeedbackPage from "../../lib/showFeedbackPage";

const Index = ({ id, showModal }) => {

  const dispatch = useDispatch()

  const [ dialogsLimit, setDialogsLimit ] = useState(10)
  const [ messagesLimit, setMessagesLimit ] = useState(30)
  const [ popoutActionSheet, setPopoutActionSheet ] = useState(null)

  const currentUserId = useSelector(getCurrentUserId)
  const currentUserRole = useSelector(getCurrentUserRole)

  const chatProfileUser = useSelector(getChatProfileUser)
  const chatPagePopout = useSelector(getChatPagePopout)

  useDialogsList(0, dialogsLimit, currentUserId, chatPagePopout)

  const { activePanel, history } = useSelector(getChat)
  const { dialogsList, listDialogsTemporary } = useSelector(getChatReducer)

  const goTo = (actionId) => {
    switch (actionId) {
      case ACTIONS.OPEN_PROFILE:
        return dispatch(goToPanel(VIEWS.CHAT.ID, actionId))
      case ACTIONS.TERMS_COOPERATION:
        return showModal(actionId)
      case ACTIONS.TUTORING:
        return console.error('------', ACTIONS.TUTORING)
      case ACTIONS.RECOMMENDATION:
        return console.error('------', ACTIONS.RECOMMENDATION)
      case ACTIONS.FEEDBACK:
        showFeedbackPage(chatProfileUser.id,dispatch)
        return {}
      case ACTIONS.SHARE:
        return shareLink('user=' + chatProfileUser.id)
      case ACTIONS.COPY_LINK:
        return {}
      case VIEWS.CHAT.PANELS.DIALOG:
        return dispatch(goToPanel(VIEWS.CHAT.ID, VIEWS.CHAT.PANELS.DIALOG))
      default:
        return null
    }
  }

  const goBack = () => dispatch(goBackPanel(VIEWS.CHAT.ID, history[history.length - 2], history.length))

  const sendBill = (bill) => {
    const sendBill = !!(currentUserId && chatProfileUser.id && bill)
    sendBill && Api.dialogs.sendBill(currentUserId, +chatProfileUser.id, bill)
  }

  const showActionSheet = () => {
    setPopoutActionSheet(<ActionSheets userRole={currentUserRole} setPopout={setPopoutActionSheet} goTo={goTo}/>)
  }

  const loadUpDialogs = () => {
    setDialogsLimit(dialogsLimit => dialogsLimit + 10)
    Api.users.getDialogsList(currentUserId, 0, dialogsLimit + 10).then((response) => {
      response.data.data && dispatch(setDialogsList(convertDialogList(response.data.data)))
    })
  }

  return (
    <View id={id} popout={popoutActionSheet} activePanel={activePanel} onSwipeBack={goBack} history={history}
          style={activePanel === VIEWS.CHAT.PANELS.DIALOG ? {zIndex: 3} : {}}>
      <DialogList id={VIEWS.CHAT.PANELS.DIALOG_LIST}
                  header='Сообщения'
                  dialogList={dialogsList}
                  listDialogsTemporary={listDialogsTemporary}
                  loadUpDialogs={loadUpDialogs}
                  chatPagePopout={chatPagePopout}
                  goToPanel={goTo}
      />

      <Dialog id={VIEWS.CHAT.PANELS.DIALOG}
              chatProfileUser={chatProfileUser}
              backTo={goBack}
              actionSheet={showActionSheet}
              messagesLimit={messagesLimit}
              setMessagesLimit={setMessagesLimit}
              showModal={showModal}
              sendBill={sendBill}
      />

      <Feedback id={VIEWS.CHAT.PANELS.FEEDBACK} header='Отзыв' backTo={goBack}/>
      <Profile id={VIEWS.CHAT.PANELS.PROFILE} header='Профиль' backTo={goBack} showModal={showModal}
               profile={chatProfileUser}/>
    </View>
  )
}

export default Index
