import React, { useState } from 'react'
import { Banner, Group, SimpleCell, Button, Link, ModalPage, List } from '@vkontakte/vkui'

import Icon24ShareOutline from '@vkontakte/icons/dist/24/share_outline'
import AffiliateHistoryItem from './AffiliateHistoryItem.js'
import AffiliateUserItem from './AffiliateUserItem.js'
import ModalHeader from '../../../components/ModalHeader'
import CustomTabsItem from '../../../components/CustomTabs/CustomTabsItem'
import CustomTabs from '../../../components/CustomTabs'
import './Affiliate.css'
import VIEWS from "../../../panels/panels";
import {useDispatch, useSelector} from "react-redux";
import {senMessageToTelegram} from "../../../lib/sendMessageToTelegram";
import {goToPanel, setActivePanel} from "../../../redux/reducers/views-reducer";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import shareLink from "../../../lib/bridgeUtilits/shareLink";
import {getCurrentUserId} from "../../../redux/selectors/profile-selectors";

const Index = ({ id, onClose, affiliate }) => {
  const { balance, frozen, invitedUsers, userHistory } = affiliate

  const [activeTab, setActiveTab] = useState('invited')

  const fetchedUserId = useSelector(getCurrentUserId)

  const dispatch = useDispatch()

  const handleClick = (sum) => {
    senMessageToTelegram({ affiliate: {sum, fetchedUserId }})
    onClose()
    dispatch(setSnackbar(<MySnackbar message={`Запрос на вывод средств отправлен, ожидайте`}/>))
  }

  const goToPanelBonusSystem = () => {
    dispatch(setActivePanel(VIEWS.EPIC.ID, VIEWS.EPIC.PANELS.MAIN))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.ABOUT_BONUS_SYSTEM))
    onClose()
  }
  
  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Партнерка' modalBack={onClose} /> )} >
      <Group mode='plain' style={{ paddingBottom: '20px' }}  className='ProfileAffiliateGroup'>
        <SimpleCell className='CellProfileStats__HeaderText' after={<Icon24ShareOutline onClick={() => shareLink('ref=' + fetchedUserId)}/>} disabled>
          <span className='CellProfileStats__HeaderText__Text'>Поделиться ссылкой на gbg study</span>
        </SimpleCell>

        <Banner
          subheader='Поделитесь специальной ссылкой на наш сервис и начните зарабатывать вместе с нами!'
          actions={<Button mode='primary' onClick={() => goToPanelBonusSystem()}>Подробнее</Button>}
        />

        <SimpleCell className='CellProfileStats__HeaderText' disabled>
          <span className='CellProfileStats__HeaderText__Text'>Приглашенные пользователи</span>
          <span className='CellProfileStats__HeaderText__Count'>{invitedUsers.length}</span>
        </SimpleCell>

        <SimpleCell className='CellProfileStats__HeaderText'
                    after={balance > 0 && <Link onClick={() => handleClick(balance)}>Вывести</Link>} disabled>
          <span className='CellProfileStats__HeaderText__Text'>Баланс</span>
          <span className='CellProfileStats__HeaderText__Count'>{`${balance ? balance : 0} ₽`}</span>
        </SimpleCell>

        <SimpleCell className='CellProfileStats__HeaderText' disabled>
          <span className='CellProfileStats__HeaderText__Text'>На подтверждении</span>
          <span className='CellProfileStats__HeaderText__Count'>{`${frozen ? frozen : 0} ₽`}</span>
        </SimpleCell>

        {invitedUsers.length !== 0
          ? <CustomTabs>
              <CustomTabsItem name='invited' title='Пользователи' activeTab={activeTab} handleSetActiveTab={setActiveTab} />
              <CustomTabsItem name='history' title='История' activeTab={activeTab} handleSetActiveTab={setActiveTab} />
            </CustomTabs>
          : <></>
        }

        <List>
          {activeTab === 'invited'
            ? invitedUsers.map((user, index) => (
              index < 10 ? <AffiliateUserItem key={index} user={user} /> : ''
            ))
            : userHistory.map((history, index) => (
              index < 10 ? <AffiliateHistoryItem key={index} history={history} /> : ''
            ))
          }
        </List>
      </Group>
    </ModalPage>
  )
}

export default Index
