import React from 'react'
import {Div, Spinner} from "@vkontakte/vkui";

const MyMiniSpinner = () => {
  return (
    <Div><Spinner size='small'/></Div>
  )
}

export default MyMiniSpinner
