import store from '../store';

export const getMainReducer = (state = store) => state.mainReducer

export const getMiniCards = (state = store) => getMainReducer(state).miniCards
export const getBigCards = (state = store) => getMainReducer(state).bigCards

export const getPlaylist = (state = store) => getMainReducer(state).playlist
export const getUsefulFilesItems = (state = store) => getMainReducer(state).items
export const getSelectedCategory = (state = store) => getMainReducer(state).selectedCategory
export const getTests = (state = store) => getMainReducer(state).tests
export const getSelectedTest = (state = store) => getMainReducer(state).selectedTest
export const getCalculatorsList = (state = store) => getMainReducer(state).calculatorsList
export const getSelectedCategoryCalculators = (state = store) => getMainReducer(state).selectedCategoryCalculators
export const getSelectedCalculator = (state = store) => getMainReducer(state).selectedCalculator
export const getSelectedPlaylist = (state = store) => getMainReducer(state).selectedPlaylist
export const getSelectedVideo = (state = store) => getMainReducer(state).selectedVideo