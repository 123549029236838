import React from 'react'
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss'

import './PreviewImage.css'

const PreviewImage = ({ image, index, deleteImage }) => (
  <div className='PreviewImage'>
    <div className='InputImage' style={{ backgroundImage: `url(${image})` }} />
    <Icon24Dismiss className='RemoveImage' width={18} height={18} onClick={() => deleteImage(index)} />
  </div>
)

export default PreviewImage
