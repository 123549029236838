import React from 'react'
import {Div, Group, Panel} from '@vkontakte/vkui'

import classes from './VideoList.module.css'
import HeaderPanel from "../../../../components/HeaderPanel";
import VideoCard from "../../../../components/VideoCard";
import {useDispatch, useSelector} from "react-redux";
import {goToPanel} from "../../../../redux/reducers/views-reducer";
import VIEWS from "../../../panels";
import {setSelectedVideo} from "../../../../redux/reducers/main-reducer";
import {getSelectedPlaylist} from "../../../../redux/selectors/main-selectors";

const Index = ({id, backTo, header }) => {

  const selectedPlaylist = useSelector(getSelectedPlaylist)
  const playlist = useSelector( state => state.mainReducer.playlist[selectedPlaylist])

  const dispatch = useDispatch()

  const handleVideo = (id) => {
    dispatch(setSelectedVideo(id))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.VIDEO_PAGE))
  }

  return (
    <Panel id={id}>
      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>

      <Group className={classes.group}>
        <Div className={classes.cardGrid} style={{ paddingBottom: '30px' }} >
          {
            playlist.videos.map((video, index) => (<VideoCard key={index} video={video} openCard={() => handleVideo(index) }/>))
          }
        </Div>
      </Group>


    </Panel>
  )
}

export default Index
