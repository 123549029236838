import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getTasks} from "../redux/reducers/requests-reducer";

const useExecutorTasks = (idUser, tasks, offset, count) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTasks(idUser, offset, count, tasks))
  },[idUser])

}

export default useExecutorTasks