import React from 'react'
import {Group, Panel} from '@vkontakte/vkui'

import HeaderPanel from "../../../components/HeaderPanel";
import CellCategory from "../../../components/CellCategory";
import {setSelectedCategory} from "../../../redux/reducers/main-reducer";
import {goToPanel} from "../../../redux/reducers/views-reducer";
import VIEWS from "../../panels";
import {useDispatch} from "react-redux";

const Index = ({id, backTo, header, list }) => {

  const dispatch = useDispatch()

  const showCategory = (id) => {
    dispatch(setSelectedCategory(id))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.COMMENTS_LIST))
  }

  return (
    <Panel id={id}>
      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>
      <Group>
        {
          list.map( ( list, i ) => {
            return <CellCategory id={i}
                                 key={i}
                                 list={list}
                                 count={list.comments.length - 1}
                                 text={list.title}
                                 showCategory={showCategory}/>
          })
        }
      </Group>
    </Panel>
  )
}

export default Index
