import React, {useState,useEffect} from 'react'

import {
  Button,
  Div,
  FixedLayout,
  FormLayout,
  Group,
  Input,
  Panel,
  Progress,
  Radio,
  Alert,
  Checkbox,
  Caption,
  PopoutWrapper, withAdaptivity, ModalDismissButton
} from '@vkontakte/vkui'
import {useDispatch, useSelector} from "react-redux";
import {getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import showNativeGallery from "../../../lib/bridgeUtilits/showNativeGallery";

import Api, {APP_ID, BASE_URL} from "../../../api/api";
import {getSelectedTest} from "../../../redux/selectors/main-selectors";
import {goToPanel, returnMyTabs, setMyTabs} from "../../../redux/reducers/views-reducer";
import VIEWS from "../../panels";
import './Test.css'
import HeaderPanel from "../../../components/HeaderPanel";
import {Icon12Cake, Icon20ShareOutline, Icon28StoryAddOutline} from '@vkontakte/icons';
import shareLink from "../../../lib/bridgeUtilits/shareLink";
import {
  convertUserInfoProperties,
  getFullProfileUserData,
  MAIN_PROFILE_USER
} from "../../../redux/reducers/profile-reducer";
import ROLES from "../../../roles";
import {setSelectedUser} from "../../../redux/reducers/main-reducer";
import bridge from "@vkontakte/vk-bridge";
import Confetti from "react-confetti";
import MODALS from "../../../modals/modals";
import { Icon16SadFaceOutline } from '@vkontakte/icons';
import { Icon16NeutralFaceOutline } from '@vkontakte/icons';
import { Icon16Smile } from '@vkontakte/icons';

const Test = ({ id, header, backTo, setPopout, showModal}) => {

  const snackbar = useSelector(getProfileSnackbar)
  const [selectedQuestionId, setSelectedQuestionId] = useState(0)
  const selectedTestId = useSelector(getSelectedTest)
  const dispatch = useDispatch()

  const [questions, setQuestions] = useState([])

  function changeSlideIndex (index) {
    setSelectedQuestionId(slideIndex => {
      return slideIndex + index < 0 ? 0 : slideIndex + index
    })
  }

  const [ answers, setAnswers ] = useState({})

  const [ isConfetti, setIsConfetti ] = useState(false)

  const getResult = () => {
    Api.tests.sendAnswersTest(selectedTestId, JSON.stringify(answers)).then(data=> {

      data.data.data.userScores === data.data.data.maxScores && setIsConfetti(true)

      const result = data.data.data.userScores + '/' + data.data.data.maxScores

      const percent = data.data.data.userScores === 0 ? 0 : +data.data.data.userScores /+data.data.data.maxScores * 100

      const sadFaceIcon = <Icon16SadFaceOutline width={56} height={56}/>
      const neutralFaceIcon = <Icon16NeutralFaceOutline width={56} height={56}/>
      const smileIcon = <Icon16Smile width={56} height={56}/>

      const icon = percent < 25 ? sadFaceIcon : percent > 75 ? smileIcon : neutralFaceIcon
      const subheader = percent < 25
          ? 'Результат оставляет желать лучшего, но не растраивайтесь, можно будет опробовать свои силы вновь через неделю!'
          : percent > 75
              ? 'Отличный результат, вы вполне освоили данную тему'
              : 'Ну не плохо но и не отлично, попробуйте через неделю свои пройти тест вновь, все получится!'

      showModal(MODALS.CARD.INFORMATION_CARD, {header: result, subheader: subheader, icon: icon, customClose: goBack})
      // setPopout(<CustomPopout onClose={goBack} result={data.data.data}/>)
    })
  }

  useEffect(() => {
    Api.tests.getTestQuestions(selectedTestId).then(data => {
      data.data.data ? setQuestions(data.data.data) : goBack()
    })
  }, [])

  const goBack = () => {
    setPopout(null)
    backTo()
  }

  const popoutResult = <Alert actions={[{ title: 'Отмена', autoclose: true, mode: 'cancel' },
                                  { title: 'Завершить', autoclose: true, mode: 'default', action: getResult }]}
                        actionsLayout="horizontal"
                        onClose={() => setPopout(null)}
                        header="Завершение тестирования"
                        text="Вы уверены, что хотите завершить тест?"/>

  const testLink = 'test=' + selectedTestId

  const [ user, setUser ] = useState(null)

  const handleUser = (id) => {
    Api.users.getByUserGbgId(id).then((response) => {
      setUser(convertUserInfoProperties(response.data.data))
    })
  }

  useEffect(() => {
    if (user && user.role === ROLES.CONSULTANT) {
      dispatch(getFullProfileUserData(user.id, MAIN_PROFILE_USER, true))
      dispatch(setSelectedUser(user.id))
      dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.PROFILE))
    }
  }, [user])

  return (
    <Panel id={id}>

      <HeaderPanel withoutButtonBack>
        {header}
      </HeaderPanel>

      <Group>

        <Div className={'TestGroup__info'}>
          <Caption level="3" weight="semibold" caps>Автор теста: <span onClick={()=>handleUser(423)}>АЛЕКСАНДР</span></Caption>
          <Button onClick={()=>shareLink(testLink)} before={<Icon20ShareOutline/>} mode='outline'>Share</Button>
        </Div>
        <Div>
          <Progress value={100/(questions.length)*(selectedQuestionId+1)}/>
        </Div>

        <Div style={{ marginBottom: 100}}>
          { questions[selectedQuestionId] && <Question setAnswers={setAnswers} answers={answers} question={questions[selectedQuestionId]} number={selectedQuestionId}/> }
        </Div>

        <FixedLayout vertical='bottom' style={{paddingBottom: 12}}>
          <Div style={{display: 'flex'}}>
            { <Button size="m"
                      disabled={selectedQuestionId === 0 && 'disabled'}
                      stretched
                      style={{ marginRight: 8 }}
                      onClick={() => changeSlideIndex(-1)}>Назад</Button> }
            { <Button size="m"
                      disabled={questions.length - 1 === selectedQuestionId && 'disabled'}
                      stretched
                      onClick={() => changeSlideIndex(1)}>Дальше</Button> }
          </Div>
          <Div style={{ paddingTop: 0}}>
            <Button size="m" mode="commerce" stretched onClick={() => setPopout(popoutResult)}>Завешить</Button>
          </Div>
        </FixedLayout>

        {snackbar}
      </Group>
      { isConfetti && <Confetti
          width={document.documentElement.clientWidth}
          height={document.documentElement.clientHeight}
      /> }
    </Panel>
  )
}

export default Test

const Question = ({question, number, answers, setAnswers}) => {

  const questionType = question.type

  return (
      <Group mode='plain'>

        <Group mode='plain' style={{ paddingRight: 12 }}> { ( number + 1 ) + ') ' + question.questionText } </Group>

        { question.img && <Group mode='plain' style={{ paddingRight: 12 }}><div onClick={() => showNativeGallery([`${BASE_URL}/file/${question.img}`], 0)} className='Test__image' style={ { backgroundImage: `url(${BASE_URL}/file/${question.img})` }}/></Group> }

        { questionType === 'fill'
            && <Group mode='plain' style={{ paddingRight: 12 }}>
                <Input value={answers[question.id] || ''} onChange={event => {
                  const value = event.target.value.replace(",", ".")
                  setAnswers( answers => {
                    return { ...answers, [question.id]: value }
                  })}}/>
                </Group> }

          { questionType === 'single'
              &&  <Group mode='plain'>
                    <FormLayout>
                      {question.answers.map((answer,i) => <Radio checked={answer.id === answers[question.id]}  onChange={event => {
                        setAnswers( answers => {
                          return {...answers, [question.id]: +event.target.value}
                        })
                      }} key={answer.id} name="radio" value={answer.id}>{answer.text}</Radio>)}
                    </FormLayout>
                  </Group> }

        { questionType === 'multiple'
        &&  <Group mode='plain'>
          <FormLayout>
            {question.answers.map((answer,i) => <Checkbox checked={answers[question.id] ? answers[question.id].includes(answer.id) : false}  onChange={event => {
              setAnswers( answers => {
                const newAnswers = answers[question.id]
                  ? answers[question.id].includes(+event.target.value)
                    ? answers[question.id].filter(answer => answer !== +event.target.value)
                    : [...answers[question.id], +event.target.value]
                  : [+event.target.value]
                return {...answers, [question.id]: newAnswers}
              })
            }} key={answer.id} name="checkbox" value={answer.id}>{answer.text}</Checkbox>)}
          </FormLayout>
        </Group> }
      </Group>
  )
}

const CustomPopout = withAdaptivity(({ onClose, result }) => {

  const addHistory = () => {
    bridge.send("VKWebAppShowStoryBox",
        {
          background_type: 'image',
          url: "https://psv4.userapi.com/c505536/u86065323/docs/d50/79a7e4ed59c6/Bez_imeni-1.png?extra=ORAx1jhC5J4nF10W_h1PK5a5cDXe27sbugLjl1madv0rjXWRCjmua7pSGdjethD6ZPdQPrDJ7KcYvFPCBzTD1nUmtuecPqe4iYB8Vkwai2_mwTsgEA5pPwanv0MH5mzE7GiNpNpy1G-q1HQQ_GOOFq4",
          locked: true,
          stickers: [
            {
              sticker_type: 'renderable',
              sticker: {
                can_delete: false,
                content_type: 'image',
                url: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Dialog.png',
                clickable_zones: [
                  {
                    action_type: 'link',
                    action: {
                      link: `https://vk.com/app${APP_ID}`,
                      tooltip_text_key: 'tooltip_open_default',
                    },
                    clickable_area: [
                      {
                        "x": 17,
                        "y": 110
                      },
                      {
                        "x": 97,
                        "y": 110
                      },
                      {
                        "x": 97,
                        "y": 132
                      },
                      {
                        "x": 17,
                        "y": 132
                      },
                    ],
                  },
                ],
              },
            },
          ],
        }
    );
  }

  return (
      <PopoutWrapper onClick={onClose}>
        <div style={{ backgroundColor: "var(--background_content)", borderRadius: 8, position: "relative", padding: "12px" }}>
          <Caption style={{ paddingBottom: 12 }} level="1" weight="semibold" caps>Ваш результат: {result.userScores + '/' + result.maxScores}</Caption>
          {/*<Button onClick={addHistory} before={<Icon28StoryAddOutline width={24} height={24}/>} size="m">Поделиться в сторис</Button>*/}
          <ModalDismissButton onClick={onClose} />
        </div>
      </PopoutWrapper>
  )
}, {
  viewWidth: true
})
