import React from 'react'

import './SlideGallery.css'

const Index = ({ title, content }) => {
  return (
    <div className='SlideGallery'>
      <div className='SlideGallery__title'>
        {title}
      </div>
      <div className='SlideGallery__content'>
        {content}
      </div>
    </div>
  )
}

export default Index
