import React from 'react'
import {SimpleCell} from '@vkontakte/vkui'
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss'
import './CategoryItem.css'

const Index = (props) => {
  const { onSelect, category, isConsultant = false } = props
  return (
    <SimpleCell
      className='CategoryItem__SimpleCell'
      before={ category && category.img && <img className='CategoryItem__image' alt={category.name} src={'/img/' + category.img } /> }
      after={isConsultant && <Icon24Dismiss style={{ color: 'var(--attach_picker_tab_inactive_icon)' }} onClick={() => {}}/>}
      onClick={() => onSelect(category.id)}>
      {category && category.name}
    </SimpleCell>
  )
}

export default Index
