import React from 'react'
import {Panel, List, Button, Placeholder, PanelHeader, Group} from '@vkontakte/vkui'

import HeaderPanel from '../../../components/HeaderPanel'
import DialogListItem from './DilogListItem'
import Icon56RecentOutline from '@vkontakte/icons/dist/56/recent_outline';

import VIEWS from '../../panels'
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import {useSelector} from "react-redux";
import {getPopoutDialogs} from "../../../redux/selectors/chats-selectors";
import sortByDate from "../../../lib/sortByDate";

const Index = ({ id, header, backTo, dialogList, loadUpDialogs, chatPagePopout, goToPanel }) => {

  const copyDialogList = [...Object.values(dialogList)]
  const sortDialogList = sortByDate(copyDialogList, true)

  return (
    <Panel id={id}>

      <PanelHeader>
        {header}
      </PanelHeader>

      <Group>
        { chatPagePopout }

        <List>
          { sortDialogList && sortDialogList.map((user, i) => <DialogListItem key={i} user={user} goToPanel={goToPanel} /> )}
        </List>

        <Div>
          { sortDialogList.length !== 0
            ? <Button stretched size='m' mode='secondary' onClick={loadUpDialogs}>Догрузить</Button>
            : !chatPagePopout && <Placeholder icon={<Icon56RecentOutline/>}>Чаты отсутствуют</Placeholder> }
        </Div>

      </Group>

    </Panel>
  )
}

export default Index