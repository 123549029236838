import React, { useEffect, useState } from 'react'
import { ModalRoot } from '@vkontakte/vkui'

import MODALS, {
    ModalCategories,
    ModalTiming,
    ModalReviews,
    ModalRequests,
    ModalAffiliate,
    ModalRequestFilters,
    ModalCreateQuestion,
    ModalPaymentSettings,
    ModalProfileCategories,
    ModalProfilePortfolio,
    ModalProfileVideo,
    ModalCardTest,
    ModalCardInput,
    ModalCardInputNumberWithText,
    ModalCardProInfo,
    ModalCardProInfoAboutOfSystem,
    ModalCardTerms,
    ModalCardLevelExecutorInfo,
    ModalProgressAbout,
    ModalCardAskAdmin, ModalCardCreateChat, ModalCardAddInMessage, ModalCardAddMethodPaid, ModalInformationCard
} from './modals'

import Api from '../api/api'

const Index = ({ activeModal, data, showModal, onClose }) => {


    // const customClose = () => {
    //     console.log(data)
    //     onClose()
    // }

  useEffect(() => {
    Api.categories.getByParentId(0, (response) => {
      setCategories(response)
    })
  }, [showModal])

  //region Select category
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  const selectCategory = (categoryId = 0) => {
    if (selectedCategory === null || selectedCategory.id !== categoryId) {
      setSelectedCategory(categories.find(category => category.id === categoryId))
    }
  }

  useEffect(() => {
    if (selectedCategory !== null) {
      Api.categories.getByParentId(selectedCategory.id, (response) => {
        setSubCategories(response)
        showModal(MODALS.PAGE.SUBCATEGORIES)
      })
    }
  }, [selectedCategory, showModal])
  //endregion

  //region Select subCategory
  const [subcategories, setSubCategories] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)

  const selectSubCategory = (categoryId) => {
    if (selectedSubCategory === null || selectedSubCategory.id !== categoryId) {
      setSelectedSubCategory(subcategories.find(category => category.id === categoryId))
    }

    showModal(null)
  }
  //endregion

  return (
    <ModalRoot activeModal={activeModal} onClose={onClose}>
      <ModalCategories id={MODALS.PAGE.CATEGORIES} onClose={onClose} categories={categories} selectCategory={selectCategory} />
      <ModalCategories id={MODALS.PAGE.SUBCATEGORIES} onClose={onClose} categories={subcategories} selectCategory={selectSubCategory} />
      <ModalTiming id={MODALS.PAGE.TIMING} onClose={onClose} />
      <ModalReviews id={MODALS.PAGE.REVIEWS} onClose={onClose} reviews={data} />
      <ModalRequests id={MODALS.PAGE.REQUESTS} onClose={onClose} requests={data} />
      <ModalAffiliate id={MODALS.PAGE.AFFILIATE} onClose={onClose} affiliate={data} />
      <ModalProfileCategories id={MODALS.PAGE.PROFILE_CATEGORIES} onClose={onClose} categories={data} />
      <ModalProfilePortfolio id={MODALS.PAGE.PROFILE_PORTFOLIO} onClose={onClose} portfolio={data} />
      <ModalProfileVideo id={MODALS.PAGE.PROFILE_VIDEO} onClose={onClose} videos={data} />
      <ModalRequestFilters id={MODALS.PAGE.REQUEST_FILTERS} onClose={onClose} showModalPage={showModal} category={selectedSubCategory} />
      <ModalPaymentSettings id={MODALS.PAGE.PAYMENT_SETTINGS} onClose={onClose} showModalPage={showModal} bill={data} />
      <ModalCreateQuestion id={MODALS.PAGE.CREATE_QUESTION} onClose={onClose} showModalPage={showModal} />

      <ModalCardAddInMessage id={MODALS.CARD.ADD_IN_MESSAGE} onClose={onClose} data={data} value={data} showModal={showModal} />
      <ModalCardInput id={MODALS.CARD.CALC_INPUT_DATA} onClose={onClose} value={data} />
      <ModalCardInputNumberWithText id={MODALS.CARD.CALC_INPUT_NUMBER_WITH_TEXT} onClose={onClose} value={data} />
      <ModalCardCreateChat id={MODALS.CARD.CREATE_CHAT} onClose={onClose} data={data} />
      <ModalCardTest id={MODALS.CARD.CARD_TEST} onClose={onClose} course={data} goTest={() => {}}/>
      <ModalCardProInfo id={MODALS.CARD.PROFILE_PRO_INFO} onClose={onClose} showModal={showModal} />
      <ModalCardProInfoAboutOfSystem id={MODALS.CARD.PROFILE_PRO_INFO_ABOUT_OF_SYSTEM} onClose={onClose} showModal={showModal} />
      <ModalCardTerms id={MODALS.CARD.TERMS_COOPERATION} onClose={onClose} />
      <ModalCardLevelExecutorInfo id={MODALS.CARD.LEVEL_EXECUTOR_INFO} onClose={onClose} />
      <ModalCardAskAdmin id={MODALS.CARD.ASK_ADMIN} onClose={onClose} />
      <ModalCardAddMethodPaid id={MODALS.CARD.ADD_METHOD_PAID} onClose={onClose} />
      <ModalProgressAbout id={MODALS.CARD.PROGRESS_ABOUT} data={data} onClose={onClose} />
      <ModalInformationCard id={MODALS.CARD.INFORMATION_CARD} data={data} onClose={onClose} />
    </ModalRoot>
  )
}

export default Index
