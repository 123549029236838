import React, {useEffect, useState} from 'react'
import {FormLayout, Input, Textarea, Button, Div, Group, Header, InfoRow, FormItem} from '@vkontakte/vkui'
import Api from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {
  CURRENT_USER,
  setSnackbar,
  setUserDataProperties
} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar"
import Icon28DeleteOutline from '@vkontakte/icons/dist/28/delete_outline'
import MODALS from "../../../modals/modals";
import {getCurrentUserRole} from "../../../redux/selectors/profile-selectors";
import ROLES from "../../../roles";

const ChangeData = ({ profile, showModal }) => {

  const dispatch = useDispatch()

  const [ nickname, setNickname ] = useState(profile.nickname)
  const [ about, setAbout ] = useState(profile.about)
  const [ city, setCity ] = useState(profile.city)
  const [ methodPaid, setMethodPaid ] = useState(profile.methodPaid)
  const [ isChange, setIsChange ] = useState(false)
  const currentUserRole = useSelector(getCurrentUserRole)

  useEffect(() => {
    setMethodPaid(profile.methodPaid)
  },[profile.methodPaid])

  useEffect(() => {
    setIsChange( nickname !== profile.nickname || about !== profile.about || city !== profile.city )
  },[profile, nickname, about, city])

  const changeInfo = (callback, value) => {
    callback(value)
  }

  const cancelChangeInfo = () => {
    setNickname(profile.nickname)
    setAbout(profile.about)
    setCity(profile.city)
  }

  const sendRequest = () => {
    if (nickname.length < 4 || nickname.length >= 32) {
      dispatch(setSnackbar(<MySnackbar message={'Длина никнейма от 4 до 32 символов'}/>))
    } else if (about && about.length >= 500) {
      dispatch(setSnackbar(<MySnackbar message={'Длина описания до 500 символов'}/>))
    } else if (city && ( city.length < 2 || city.length >= 64)) {
      dispatch(setSnackbar(<MySnackbar message={'Длина города от 2 до 64 символов'}/>))
    } else {
      Api.profile.settingInfo(nickname, about, city).then( r => {
        if ( r.data.data === 'User info updated') {
          dispatch(setSnackbar(<MySnackbar message={'Данные обновлены'}/>))
          dispatch(setUserDataProperties(CURRENT_USER,'nickname', nickname))
          dispatch(setUserDataProperties(CURRENT_USER,'about', about || null))
          dispatch(setUserDataProperties(CURRENT_USER,'city', city))
          setIsChange(false)
        } else if ( r.data.error === 'Name exists' ) {
          dispatch(setSnackbar(<MySnackbar message={'Данный никнейм занят, попробуйте придумать другой'}/>))
        }  else if ( r.data.error === 'Name incorrect' || r.data.error === 'About incorrect' || r.data.error === 'City incorrect' ) {
          dispatch(setSnackbar(<MySnackbar message={'Вы используете запрещенные символы, давай че нибудь нормальное пиши :['}/>))
        } else (
          dispatch(setSnackbar(<MySnackbar message={'Что-то пошло не так, попробуйте использовать символы попроще и без смайликов'}/>)))
      })
    }
  }

  const addMethodPaid = () => {
    showModal(MODALS.CARD.ADD_METHOD_PAID)
  }

  const deleteMethodPaid = (id) => {
    dispatch(setSnackbar(null))
    Api.profile.deleteMethodPaid(id).then( ({data}) => {
      if (data.data === 'Method paid deleted') {
        dispatch(setUserDataProperties(CURRENT_USER, 'methodPaid', methodPaid.filter( methodPaid => methodPaid.id !== id )))
        dispatch(setSnackbar(<MySnackbar message={'Карта удалена'}/>))
      } else {
        const info = 'Карта не удалена, попробуйте позже'
        dispatch(setSnackbar(<MySnackbar message={info}/>))
      }
    })
  }

  return (
    <FormLayout>
      <FormItem top='Никнейм' bottom="Только буковки и циферки">
        <Input type='text' value={nickname} onChange={e => changeInfo(setNickname, e.target.value)} name='name'/>
      </FormItem>
      <FormItem top='О себе'>
        <Textarea value={about || ''} onChange={e => changeInfo(setAbout, e.target.value)} name='about'/>
      </FormItem>
      <FormItem top='Город'>
        <Input type='text' value={city} onChange={e => changeInfo(setCity, e.target.value)} name='city' />
      </FormItem>
      {
        currentUserRole === ROLES.CONSULTANT && <Group>
          <Header mode="secondary">Способы оплат</Header>

          { methodPaid && methodPaid.map((methodPaid,i) =>
            <Div key={i}>
              <InfoRow header={methodPaid.name}>
                <div style={{display: 'flex'}}>
                  {methodPaid.number.replace(/\s/g, '').replace(/(\d)(?=(\d{4})+(\D|$))/g, '$1 ')}
                  <Icon28DeleteOutline onClick={() => deleteMethodPaid(methodPaid.id)} width={20} height={20} style={{marginLeft: 12}}/>
                </div>
              </InfoRow>
            </Div>
          ) }
          <Div style={{display: 'flex'}}>
            { ( methodPaid === null || methodPaid.length < 3 ) && <Button stretched mode="outline" onClick={addMethodPaid}>Добавить</Button> }
          </Div>
        </Group>
      }
      { isChange && <Div style={{display: 'flex', marginTop: 24}}>
        <Button size='m' stretched  mode='secondary' onClick={cancelChangeInfo} style={{ marginRight: 8 }}>Отменить</Button>
        <Button size='m' stretched mode='primary' onClick={sendRequest}>Сохранить</Button>
      </Div> }
    </FormLayout>
  )
}

export default ChangeData
