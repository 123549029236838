import store from '../store';
import ROLES from "../../roles";

export const getProfileReducer = (state = store) => state.profileReducer

export const getCurrentUser = (state = store) => getProfileReducer(state).currentUser

export const getCurrentUserId = (state = store) => getCurrentUser(state).id
export const getCurrentUserVkId = (state = store) => getCurrentUser(state).vkId
export const getCurrentUserNickname = (state = store) => getCurrentUser(state).nickname
export const getCurrentUserRole = (state = store) => getCurrentUser(state).role
export const getCurrentUserNameRole = (state = store) => getCurrentUser(state).role === 1 ? ROLES.CONSULTANT : ROLES.USER
export const getCurrentUserBills = (state = store) => getCurrentUser(state).bills
export const getCurrentUserTop = (state = store) => getCurrentUser(state).top
export const getCurrentUserLevel = (state = store) => getCurrentUser(state).revenue
export const getCurrentUserCommissionPercent = (state = store) => getCurrentUserLevel(state).commissionPercent

export const getCurrentUserProfileInfo = (state = store) => getProfileReducer(state).profileInfo

export const getTutorsProfileUser = (state = store) => getProfileReducer(state).tutorsProfileUser
export const getChatProfileUser = (state = store) => getProfileReducer(state).chatProfileUser
export const getRequestsProfileUser = (state = store) => getProfileReducer(state).requestsProfileUser
export const getMainProfileUser = (state = store) => getProfileReducer(state).mainProfileUser

export const getIsShowModalProfile = (state = store) => getProfileReducer(state).isShowModalProfile

export const getProfileProgress = (state = store) => getProfileReducer(state).progress

export const getProfileSnackbar = (state = store) => getProfileReducer(state).snackbar

export const getProfileTemporaryUser = (state = store) => getProfileReducer(state).requestsUser