import React, {useEffect, useState} from 'react'
import {
  Button,
  Div, FormItem,
  FormLayout, Group,
  Panel,
  Placeholder,
  SimpleCell,
  Textarea
} from '@vkontakte/vkui'

import AvatarOfLetter from "../../components/AvatarOfLetter/AvatarOfLetter";
import Icon16Fire from "@vkontakte/icons/dist/16/fire";
import Api from "../../api/api";
import './Feedback.css'
import ROLES from "../../roles";

import Icon28Favorite from '@vkontakte/icons/dist/28/favorite';
import {useDispatch, useSelector} from "react-redux";
import {getFullProfileUserData, MAIN_PROFILE_USER, setSnackbar} from "../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../components/MySnackbar";
import {getProfileSnackbar} from "../../redux/selectors/profile-selectors";
import Icon56RecentOutline from "@vkontakte/icons/dist/56/recent_outline";
import MyMiniSpinner from "../../components/MyMiniSpinner/MyMiniSpinner";
import {goToPanel} from "../../redux/reducers/views-reducer";
import VIEWS from "../panels";
import HeaderPanel from "../../components/HeaderPanel";


const Feedback = ({ id, header, backTo, profile}) => {

  const { avatar, consultations, role, online, requests } = profile

  const dispatch = useDispatch()

  const snackbar = useSelector(getProfileSnackbar)

  const [ reviewsAccess, setReviewsAccess ] = useState(false)
  const [ isFetching, setIsFetching ] = useState(false)

  const [ comment, setComment ] = useState('')
  const [ stars, setStars ] = useState([0,0,0,0,0])

  const [ srcAvatar, setSrcAvatar ] = useState(null)

  useEffect(() => {
    setSrcAvatar(Api.avatars.getUrlByImgName(avatar && avatar.img))
  }, [avatar])

  useEffect(() => {
    setReviewsAccess(false)
    profile.id && Api.users.checkReviewAvailable(profile.id).then( ({ data }) => {
      if (data.data === 'OK') {
        setReviewsAccess(true)
      }
      setIsFetching(true)
    } )
  },[profile.id, snackbar])

  const description = role === ROLES.CONSULTANT ? consultations && `Консультаций проведено: ${consultations}` : requests && `Заявок: ${requests && requests.length}`

  const ratings = role === ROLES.CONSULTANT ? [
    { title: 'Приятное общение' },
    { title: 'Скорость ответа' },
    { title: 'Выполнение в срок' },
    { title: 'Качество оформления' },
    { title: 'Цена' }
  ] : [
    { title: 'Приятное общение' },
    { title: 'Скорость ответа' },
  ]

  const sendFeedback = () => {
    Api.users.sendReview(profile.id, comment, ...stars).then(r => {
      if (r.data.data === 'Review sended') {
        dispatch(setSnackbar(<MySnackbar message={'Ваш отзыв опубликован'}/>))
      } else {
        dispatch(setSnackbar(<MySnackbar message={'Вы не можете оставить отзыв об этом пользователе'}/>))
      }
    })
    setComment('')
    setStars([0,0,0,0,0])
  }

  const changeStarsFinal = (ratingIndex, starsIndex) => {
    setStars(stars => stars.map( (star, i) =>  ratingIndex === i ? starsIndex + 1 : star))
  }

  const showProfile = () => {
    dispatch(getFullProfileUserData(profile.id, MAIN_PROFILE_USER, true))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.PROFILE))
  }

  return (
    <Panel id={id}>

      <HeaderPanel panel={VIEWS.MAIN.PANELS.FEEDBACK} backTo={backTo}>
        {header}
      </HeaderPanel>
      <Group>
        {
          profile.id && <SimpleCell description={description}
                                    onClick={showProfile}
                                    before={<AvatarOfLetter id={profile.id} srcAvatar={srcAvatar} name={profile.nickname} online={online}/>}
                                    className={'FeedbackUser'}
          >
            { profile.nickname }
            { profile.top && <Icon16Fire className='IconExecutorFire' width={14} height={14}/> }
          </SimpleCell>
        }

        { isFetching && profile.id && reviewsAccess && ratings.map((rating, i) => <RatingStars key={i} rating={rating} ratingIndex={i} changeStarsFinal={changeStarsFinal}/>)}
        { isFetching && profile.id && reviewsAccess && <FormLayout>
          <FormItem
          >
            <Textarea value={comment} onChange={e => setComment(e.target.value)}  name='about' top='Комментарий' />

          </FormItem>
          <Div>
            { ( stars.filter( star => star !== 0 ).length !== 0 )
              ? <Button size='l' stretched mode='primary' onClick={sendFeedback}>Опубликовать</Button>
              : <Placeholder>Нужно поставить хотя бы одну оценку</Placeholder>
            }
          </Div>
        </FormLayout> }

        { isFetching && profile.id && !reviewsAccess && <Placeholder icon={<Icon56RecentOutline/>}>Вы пока не можете оставить отзыв о днанном пользователе либо вы уже публиковали</Placeholder>}
        { !isFetching && <MyMiniSpinner/> }

        { snackbar }
      </Group>
    </Panel>
  )
}

const RatingStars = ({rating, ratingIndex, changeStarsFinal}) => {

  const [ stars, setStars ] = useState([false,false,false,false,false])

  const changeStars = (index) => {
    setStars(stars.map((star, i) => i <= index ))
    changeStarsFinal(ratingIndex, index)
  }

  return (
    <Div>
      <div className={'FeedbackUserStarsTitle'}>{rating.title}</div>

      <div className={'FeedbackUserStars'}>
        { stars.map((star,starsIndex) => <Icon28Favorite className={'FeedbackUserStar'}
                                                key={starsIndex} width={28} height={28}
                                                style={{ color: star ? 'var(--accent)' : 'var(--background_keyboard)' }}
                                                onClick={() => changeStars(starsIndex)}
        />)}
      </div>
    </Div>
  )
}

export default Feedback
