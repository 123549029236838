import React, {useEffect} from "react"
import bridge from "@vkontakte/vk-bridge"
import {useSelector} from "react-redux";
import {getCurrentUserRole} from "../redux/selectors/profile-selectors";
import ROLES from "../roles";
import {getGlobalNotification} from "../redux/selectors/global-selectors";

function useNotificationInfo() {

  const isCurrentUserExecutor = useSelector(getCurrentUserRole) === ROLES.CONSULTANT
  const hasNotification = useSelector(getGlobalNotification)

  useEffect(() => {
    isCurrentUserExecutor && !hasNotification && bridge.send("VKWebAppAllowNotifications")
  },[isCurrentUserExecutor])
}

export default useNotificationInfo