import {Div, Spinner} from "@vkontakte/vkui";
import React from "react";
import ROLES from "../../roles";

const SET_LIST_DIALOGS = "SET_LIST_DIALOGS"
const SET_SELECTED_USER_ID = "SET_SELECTED_USER_ID"
const SET_CHAT_PAGE_POPOUT = "SET_CHAT_PAGE_POPOUT"

let initialState = {
  dialogsList : {},
  selectedUserId: null,
  chatPagePopout: <Div><Spinner size='small'/></Div>
}

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_USER_ID:
      return {...state, selectedUserId: action.idUser}
    case SET_LIST_DIALOGS:
      const dialogs = action.dialogsList.reduce((acc, n) => (acc[ 'user_' + n.id] = n, acc), {})
      return {...state, dialogsList: {...state.dialogsList, ...dialogs}}
    case SET_CHAT_PAGE_POPOUT:
      return {...state, chatPagePopout: action.popout}
    default:
      return state
  }
}

export const setDialogsList = (dialogsList) => ({
  type: SET_LIST_DIALOGS,
  dialogsList
})

export const setSelectedUserId = (idUser) => ({
  type: SET_SELECTED_USER_ID,
  idUser,
})

export const setChatPagePopout = (popout) => ({
  type: SET_CHAT_PAGE_POPOUT,
  popout
})

export const convertDialogList = (dialogList) => {
  return dialogList && dialogList.map( dialog => {
    return {
      id: +dialog.idInterlocutor,
      avatar: {
        img: dialog.avatar && dialog.avatar
      },
      nickname: dialog.name,
      online: dialog.online === 1,
      role: +dialog.executor === 1 ? ROLES.CONSULTANT : ROLES.USER,
      top: dialog.top === 1,
      methodPaid: dialog['method-paid'] && dialog['method-paid'].map((methodPaid) => { return { id: methodPaid.id, name: methodPaid.name, number: methodPaid.text }}),
      getUserId: +dialog.idUserGet,
      sendUserId: +dialog.idUserSend,
      message: dialog.text,
      sticker: dialog.sticker,
      type: dialog.type,
      datetime: dialog.datetime,
    }
  })
}

export const convertProfileUser = (user) => {
  return {
    id: user.id,
    avatar: user.avatar,
    nickname: user.nickname,
    online: user.online,
    role: user.role,
    top: user.top,
    methodPaid: user.methodPaid
  }
}

export const convertMessages = (messages) => {
  return messages && messages.map( message => {
    return {
      id: message.id,
      text: message.text,
      type: message.type,
      files: message.file,
      sticker: message.sticker,
      getUserId: message.idUserGet,
      sendUserId: message.idUserSend,
      datetime: message.datetime
    }
  })
}

export default chatsReducer