import React from 'react'

import {Caption, Card, CardScroll, Div, Group, HorizontalScroll, Link, SimpleCell} from "@vkontakte/vkui";
import VIEWS from "../../panels/panels";
import formatBigNumber from "../../lib/formatBigNumber";
import getPointProgress from "../../lib/getPointProgress";
import {useSelector} from "react-redux";
import {getProfileProgress} from "../../redux/selectors/profile-selectors";
import MODALS from "../../modals/modals";

const GroupProfileProgress = ({profile, isMyProfile, goTo, showModal}) => {

  const affiliateUsersCount = profile.affiliate && profile.affiliate.invitedUsers.length
  const userRevenue = profile.revenue && profile.revenue.value
  const reviewsCount = profile.reviews && profile.reviews.length
  const consultations = profile.consultations
  const portfolioCount = profile.portfolio && profile.portfolio.length

  const progress = useSelector(getProfileProgress)

  const counts = [affiliateUsersCount, userRevenue, reviewsCount, consultations, portfolioCount]

  const selfProgress = getPointProgress(progress, counts)

  const withoutProgressText = isMyProfile ? 'Здесь пока у вас пусто...' : 'У этого пользователя пока нет достижений'

  return (
    <Group mode='plain'>
      <SimpleCell className='CellProfileStats__HeaderText' disabled after={isMyProfile && <Link onClick={() => goTo(VIEWS.PROFILE.PANELS.PROGRESS)}>Показать</Link>}>
        <span className='CellProfileStats__HeaderText__Text'>Достижения</span>
      </SimpleCell>
      <HorizontalScroll>
        <Div style={{ display: 'flex' }}>
          { progress.map((value, index) => (
            selfProgress[index].point > 0 && (isMyProfile || index !== 1) && <div key={index} className={'CellProfileProgress'} onClick={() => showModal(MODALS.CARD.PROGRESS_ABOUT,value)} >
              <div className={'CellProfileProgress__Images'} >
                <img src={`/img/progress/${value.url}`} />
                <span className={'CellProfileProgress__Text'}>{formatBigNumber(value.points[selfProgress[index].point - 1])}</span>
              </div>
              <div className={'CellProfileProgress__Label'} >
                {value.label}
              </div>
            </div>
          ))}
        </Div>
      </HorizontalScroll>
      { selfProgress.filter(point => point.point !== 0).length === 0 && <Div><Caption level="4" weight="bold" caps>{withoutProgressText}</Caption></Div>}
    </Group>
  )
}

export default GroupProfileProgress