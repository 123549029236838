import React, {useEffect, useState} from 'react'
import bridge from '@vkontakte/vk-bridge'
import {FormLayout, Group, ModalPage} from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
import Api from '../../../api/api'

import './ProfilePortfolio.css'
import showNativeGallery from "../../../lib/bridgeUtilits/showNativeGallery";

const Index = ({ id, onClose, portfolio }) => {

  const imagesSrc = portfolio.map(portfolio => Api.avatars.getUrlByImgName(portfolio.img))
  const indexImageShow = null

  useEffect(() => {
    if (indexImageShow || indexImageShow === 0) {
      bridge.send("VKWebAppShowImages", {
        images: imagesSrc,
        start_index: indexImageShow
      })
    }
  },[indexImageShow])

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Примеры работ' modalBack={onClose} /> )} dynamicContentHeight>
      <FormLayout>
        <Group className='ProfilePortfolioGroup'>
          {portfolio.map((portfolio, i) => (
            <div key={i} className='ProfilePortfolio' onClick={() => showNativeGallery([Api.avatars.getUrlByImgName(portfolio.img)])}>
              <img src={Api.avatars.getUrlByImgName(portfolio.img)} alt='portfolio' />
            </div>
          ))}
        </Group>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
