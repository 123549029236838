import store from '../store';

export const getViewsReducer = (state = store) => state.viewsReducer

export const getMyTabs = (state = store) => getViewsReducer(state).myTabs

export const getActiveStory = (state = store) => getViewsReducer(state).epic.activePanel
export const getActiveView = (state = store) => getViewsReducer(state).viewId
export const getModal = (state = store) => getViewsReducer(state).modal

export const getMainViews = (state = store) => getViewsReducer(state).main
export const getProfileViews = (state = store) => getViewsReducer(state).profile
export const getTutorsViews = (state = store) => getViewsReducer(state).tutors
export const getRequestViews = (state = store) => getViewsReducer(state).requests

export const getChat = (state = store) => getViewsReducer(state).chat
