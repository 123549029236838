import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline'

import './AddInMessage.css'
import MODALS from "../../modals";

const Index = ({ id, onClose, showModal, data }) => {
  return (
    <ModalCard
      id={id}
      onClose={onClose}
      actionsLayout={"vertical"}
      actions={[
        <Button key={'bill'} mode='primary' onClick={() => showModal(MODALS.CARD.CALC_INPUT_DATA, {func: data.sendBill})}>Счет</Button>,
        <Button key={'file'} mode='primary' onClick={() => onClose()}>Файл</Button>,
        <Button key={'photo'} mode='primary' onClick={() => onClose()}>Фото</Button>,
      ]}
    >
      <div className='TermsCooperation-Title'>
        Добавить
      </div>
      <div className='TermsCooperation-Text'>
        Выберите, что нужно отправить
      </div>
    </ModalCard>
  )
}

export default Index
