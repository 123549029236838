import React, {useEffect, useState} from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'

import './ProInfoAboutOfSystem.css'
import MODALS from "../../modals";
import {useSelector} from "react-redux";
import Icon28InfoOutline from "@vkontakte/icons/dist/28/info_outline";
import Api from "../../../api/api";

const Index = ({ id, onClose, showModal }) => {

  const [ progress, setProgress ] = useState([])

  useEffect(() => {
    Api.profile.getRevenueStatuses().then(r => setProgress(r.data.data))
  },[])

  function moneyFormat(n) {
    return parseFloat(n).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")
  }

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      icon={<Icon28InfoOutline width={56} height={56} />}
      actions={[
          <Button key="join" size="l" mode="primary" onClick={() => showModal(MODALS.CARD.PROFILE_PRO_INFO)}>
            Назад
          </Button>
        ]}
    >
      <div className='TermsCooperation-Title'>
        Статусы консультантов
      </div>
      <div className='TermsCooperation-Text'>
        Статус меняется в зависимости от вашего  суммарного оборота:<br/><br/>
        {
          progress.map( (level,i) => {
          return <div key={i}>{level.status} до {moneyFormat(level.to)} ₽<br/></div>
        })
        }
        и выше...
        <br/><br/>
        Чтобы получить 🔥 - нужно работать минимум 2 месяца в сервисе и выполнить от 10 заявок,
        а так же не получать отрицательных отзывов и вовремя оплачивать комиссию.
        Если все условия выполнены - напишите нашему администратору
        <br/><br/>
        Каждый новый статус и 🔥 уменьшает процент комиссии, на 2% и 4% соответственно
      </div>
    </ModalCard>
  )
}

export default Index
