import React from 'react'
import {Group, Panel} from '@vkontakte/vkui'

// import classes from './VideoPage.module.css'
import HeaderPanel from "../../../components/HeaderPanel";
import CellProfileStats from "../../../components/CellProfileStats";
import {goToPanel} from "../../../redux/reducers/views-reducer";
import VIEWS from "../../panels";
import {useDispatch} from "react-redux";
import {setSelectedPlaylist} from "../../../redux/reducers/main-reducer";

const Index = ({id, backTo, header, list }) => {

  const dispatch = useDispatch()

  const handlePlaylist = (id) => {
    dispatch(setSelectedPlaylist(id))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.VIDEO_LIST))
  }

  return (
    <Panel id={id}>
      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>
      <Group>
        {
          list.map( ( list, index ) => {
            let text, count
            if (header === 'Видеоуроки') {
              text = list.info.name
              count = list.videos.length
            } else if (header === 'Материалы') {
              text = list.title
              count = list.comments.length
            }
            return <CellProfileStats key={index}
                                     data={{text: text, count:count}}
                                     selectedStat={() => handlePlaylist(index)} />
          })
        }
      </Group>

    </Panel>
  )
}

export default Index
