import Api from "../api/api";

async function getUserByBridgeInfo(userBridgeInfo, ref, searchParams) {

  const userGbgInfo = await Api.users.getByUserVkId(userBridgeInfo.id)

  let currentUser = userGbgInfo.data.data

  const userParams = searchParams.current

  if (userGbgInfo.data.error === 'User not found') {
    await Api.users.addUser(userBridgeInfo.id, userBridgeInfo.first_name, userBridgeInfo.last_name, ref)
    await Api.tokens.setJwtToken(userParams).then(token => {
      localStorage.setItem('JWT', token.data)
      window.location.reload();
    })
    // const afterRefreshTokenUserGbgInfo = await Api.users.getByUserVkId(userBridgeInfo.id)
    // currentUser = afterRefreshTokenUserGbgInfo.data.data
  }

  return currentUser
}

export default getUserByBridgeInfo