import React, {useEffect, useRef, useState} from 'react'
import { Button, Group, SimpleCell, InfoRow, Progress, RichCell } from '@vkontakte/vkui'
import Icon16Fire from '@vkontakte/icons/dist/16/fire'
import Icon28MoneyTransferOutline from '@vkontakte/icons/dist/28/money_transfer_outline'
import Icon28FavoriteOutline from '@vkontakte/icons/dist/28/favorite_outline'

import './ProfileItem.css'

import Api from '../../../../api/api'
import VIEWS from '../../../panels'
import MODALS from '../../../../modals/modals'
import AvatarOfLetter from "../../../../components/AvatarOfLetter/AvatarOfLetter";
import {useDispatch, useSelector} from "react-redux";
import Icon28ShareOutline from "@vkontakte/icons/dist/28/share_outline";
import Icon28MessageOutline from "@vkontakte/icons/dist/28/message_outline";
import {
  setSnackbar,
} from "../../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../../components/MySnackbar";
import parseQueryString from "../../../../lib/parseQueryString";
import {
  getCurrentUserBills,
  getCurrentUserRole,getIsShowModalProfile,
} from "../../../../redux/selectors/profile-selectors";
import formatMoney from "../../../../lib/formatMoney";
import shareLink from "../../../../lib/bridgeUtilits/shareLink";
import getExecutorLevel from "../../../../lib/getExecutorLevel";
import ROLES from "../../../../roles";
import roundBill from "../../../../lib/mathUtilits/roundBill";
import showFeedbackPage from "../../../../lib/showFeedbackPage";

const Index = ({ profile, goTo, showModal, isCurrentUserProfile }) => {

  const { id, avatar, about, city, role, revenue, online } = profile

  const dispatch = useDispatch()

  const hashParams = useRef(parseQueryString(window.location.hash))

  const isCurrentProfileConsultant = role === ROLES.CONSULTANT
  
  const currentUserRole = useSelector(getCurrentUserRole)
  const currentUserBills = useSelector(getCurrentUserBills)
  const isCurrentUserUser = currentUserRole === ROLES.USER
  const isCurrentUserVip = revenue && revenue.status === 'VIP'

  const nextLevel = revenue && getExecutorLevel(revenue.value).value

  const isShowModalProfile = useSelector(getIsShowModalProfile)

  const [ srcAvatar, setSrcAvatar ] = useState(null)
  const [ sumCommission, setSumCommission ] = useState(0)
  const [ showFeedback, setShowFeedback ] = useState(false)

  useEffect(() => {
    setSrcAvatar(Api.avatars.getUrlByImgName(avatar && avatar.img))
  }, [avatar])

  useEffect(() => {
    currentUserBills && isCurrentUserProfile &&
    setSumCommission(roundBill(currentUserBills.map(bill => bill.price * bill.commissionPercent)
      .reduce((sum, current) => sum + roundBill(current), 0)))
  }, [currentUserBills])

  useEffect(() => {
    if (hashParams.current['create_chat'] && !isShowModalProfile.includes(MODALS.CARD.CREATE_CHAT)) {
      if (isCurrentProfileConsultant && isCurrentUserUser) {
        showModal(MODALS.CARD.CREATE_CHAT, {profile})
      }
    }
  }, [role, profile])

  useEffect(() => {
    profile.id && Api.users.checkReviewAvailable(profile.id).then( ({ data }) => {
      if (data.data === 'OK') {
        setShowFeedback(true)
      }
    } )
  },[profile.id])

  const writeToUser = () => {
    if (isCurrentProfileConsultant && isCurrentUserUser) {
      showModal(MODALS.CARD.CREATE_CHAT, { profile })
    } else {
      dispatch(setSnackbar(<MySnackbar message={'Вы не можете связаться с данным пользователем'}/>))
    }
  }

  const sendFeedback = () => {
    showFeedbackPage(id, dispatch)
  }

  return (
    <>
      <Group mode='plain' className='ProfileHeader'>
        <SimpleCell
          disabled
          description={city}
          before={<AvatarOfLetter id={id} srcAvatar={srcAvatar} name={profile.nickname} online={online}/>}
          after={isCurrentProfileConsultant && <Button className='ProfileHeader-Btn'
                                              mode='tertiary'
                                              onClick={() => (isCurrentUserProfile) ? showModal(MODALS.CARD.PROFILE_PRO_INFO) : showModal(MODALS.CARD.LEVEL_EXECUTOR_INFO)}
                                              before={profile.top && <Icon16Fire style={{color: '#f05c44'}}/>}>
            {revenue && revenue.status}
          </Button>}>
          {profile.nickname}
        </SimpleCell>

        {!isCurrentUserProfile &&
        <div className='ExecutorsProfileItem__Actions'>
          <Button mode='outline'
                  onClick={() => shareLink('user=' + id)}
                  before={<Icon28ShareOutline width={16} height={16}/>}>
            Поделиться
          </Button>
          {isCurrentProfileConsultant &&
          <Button before={<Icon28MessageOutline width={16} height={16}/>} onClick={writeToUser}>Связаться</Button>}
        </div>
        }
        { showFeedback && <div className='ExecutorsProfileItem__Actions'>
            <Button before={<Icon28FavoriteOutline width={16} height={16}/>} onClick={sendFeedback} mode={'commerce'}>Оставить отзыв</Button>
          </div> }

        { isCurrentProfileConsultant && isCurrentUserProfile &&
        <>
          <InfoRow
            className='ProfileHeader-Status'
            header={
              <div className='ProfileHeader-StatusText'>
                Заработано
                { revenue && isCurrentUserVip
                  ? <span>{formatMoney(revenue.value)} ₽</span>
                  : revenue && <span>{formatMoney(revenue.value)} ₽ из {formatMoney(nextLevel)} ₽</span>}
              </div>
            }>
            {!isCurrentUserVip && revenue
              ? <Progress value={ revenue.value / nextLevel * 100}/>
              : isCurrentUserVip ? <Progress value={100}/> : <Progress value={0}/>}
          </InfoRow>
          <RichCell disabled
                    className='ProfileHeader-Commission'
                    before={<Icon28MoneyTransferOutline style={{color: 'var(--accent)'}}/>}
                    caption={sumCommission.toFixed(1) + ' ₽'}
                    after={sumCommission !== 0 && <Button onClick={() => goTo(VIEWS.PROFILE.PANELS.COMMISSION)}>Оплатить</Button>}>
            Комиссия
          </RichCell>
        </>
        }

        <div className='ProfileHeader__About'>
          <div className='ProfileHeader__About--Text'>О СЕБЕ</div>
          {about ? about : 'Информация отсутствует'}
        </div>
      </Group>
    </>
  )
}

export default Index
