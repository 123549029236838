import React from 'react'
import 'moment/locale/ru'
import { Card } from '@vkontakte/vkui'

import './MyCard.css'
import {goToPanel} from "../../redux/reducers/views-reducer";
import VIEWS from "../../panels/panels";
import {useDispatch} from "react-redux";

const Index = ({card, func}) => {

  const {title, image, info, about, panel} = card

  const dispatch = useDispatch()
  const callback = func ? func : () => dispatch(goToPanel(VIEWS.MAIN.ID, panel))

  return (
    <Card size='l' mode='shadow' className='MyCard'>
      <div className='MyCard__card'  onClick={() => callback(card)}>
        { image && <div className='MyCard__image' style={ { backgroundImage: `url(${image})` }}/> }
        <div className='MyCard__content'>
          <div className='MyCard__date'>
            {info}
          </div>
          <div className='MyCard__title'>{title}</div>
          <div className='MyCard__text'>
            {about}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Index
