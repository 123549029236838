import React, {useState} from 'react'
import 'moment/locale/ru'
import {SimpleCell} from '@vkontakte/vkui'

import './ProfileItemForList.css'
import Api from "../../api/api";
import AvatarOfLetter from "../AvatarOfLetter/AvatarOfLetter";
import Icon16Fire from "@vkontakte/icons/dist/16/fire";

const Index = ({ profile, func = null, icon = null, count, text, disabled }) => {

  const srcAvatar = useState(Api.avatars.getUrlByImgName(profile.avatar && profile.avatar.img))

  const description = ( count || count === 0 ) && text + count
  return (
    <SimpleCell
      before={<AvatarOfLetter id={profile.id} srcAvatar={srcAvatar[0]} name={profile.nickname} online={profile.online}/>}
      after={icon && icon}
      onClick={() => func && func()}
      description={description}
      className='ProfileItemForList'
      disabled={disabled}
    >
      { profile.nickname
        ? profile.nickname
        : 'User' + profile.id }
      { profile.top && <Icon16Fire className='IconExecutorFire' width={14} height={14}/> }
    </SimpleCell>
  )
}

export default Index
