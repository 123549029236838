import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'

const ProgressAbout = ({ id, onClose, data }) => {

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      header={data.label}
      subheader={data.description}
      icon={<img style={{ width: 56, height: 56 }} src={`/img/progress/${data.url}`} />}
      actions={[
        <Button key={'ok'} onClick={() => onClose()} mode='primary'>Оки</Button>
      ]}
    />
  )
}

export default ProgressAbout
