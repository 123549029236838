import React  from 'react'
import { FormLayout, Group, ModalPage } from '@vkontakte/vkui'

import CategoryItem from '../../../components/CategoryItem'
import ModalHeader from '../../../components/ModalHeader'

const Index = (props) => {
  const { id, onClose, categories, selectCategory } = props
  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Выбор направления' modalBack={onClose} /> )}>
      <FormLayout>
        <Group>
          { categories.map((category) => (
            <CategoryItem key={category.id} category={category} onSelect={selectCategory} />
          ))}
        </Group>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
