import React from 'react'
import { Panel, Header, FormLayout, Radio, Headline, Input } from '@vkontakte/vkui'

import Icon24MoreHorizontal from '@vkontakte/icons/dist/24/more_horizontal'
import HeaderPanel from '../../../components/HeaderPanel'
import './TestPanel.css'

import VIEWS from '../../panels'

const Index = ({ id, header, backTo }) => {

  return (
    <Panel id={id}>
      <HeaderPanel panel={id === 'test' ? VIEWS.MAIN.PANELS.HOME : VIEWS.USEFUL.PANELS.MAIN} backTo={backTo}>
        {header}
      </HeaderPanel>

      <Header subtitle='11 класс' aside={<Icon24MoreHorizontal />}>
        Математика
      </Header>

      <FormLayout>
        <div>
          <Headline className='TestPanel_Title' weight='semibold'>
            1) Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, quos sed!
          </Headline>
          <Radio name='radio' value='1'>47°</Radio>
          <Radio name='radio' value='2'>67°</Radio>
          <Radio name='radio' value='3'>53°</Radio>
        </div>

        <div>
          <Headline className='TestPanel_Title' weight='semibold'>
            2) Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, quos sed!
          </Headline>
          <Input type='text' defaultValue={0} />
        </div>
      </FormLayout>
    </Panel>
  )
}

export default Index
