import React, {useState} from 'react'
import classes from './AudioTrack.module.css'

import Icon28Play from '@vkontakte/icons/dist/28/play';
import Icon28Pause from '@vkontakte/icons/dist/28/pause';
import {BASE_URL} from "../../api/api";

const AudioTrack = ({ id, file }) => {

  const [ activeElement, setActiveElement ] = useState('play')

  const handleClick = (id) => {
    if (activeElement === 'play' ) {
      document.getElementById(id).play()
      setActiveElement('pause')
    } else {
      document.getElementById(id).pause()
      setActiveElement('play')
    }
  }

  return (
    <div className={classes.parent}>
      <span>Голосовое:</span>
      <audio id={id} src={`${BASE_URL}/file/${file}`} type="audio/mpeg" preload='none'/>
      <div>

        <button className={classes.buttonAudioPlayer} onClick={() => handleClick(id)}>
          {
            activeElement === 'play' ? <Icon28Play width={20} height={20}/> : < Icon28Pause width={20} height={20}/>
          }
        </button>
      </div>
    </div>
  )
}

export default AudioTrack
