import {goToPanel} from "../redux/reducers/views-reducer";
import VIEWS from "../panels/panels";
import Api from "../api/api";
import {
  convertPropertyRequests,
  convertUserInfoProperties,
  MAIN_PROFILE_USER,
  setUserData,
  setUserDataProperties
} from "../redux/reducers/profile-reducer";

const showFeedbackPage = (id, dispatch) => {
  dispatch(goToPanel(VIEWS.EPIC.ID, VIEWS.EPIC.PANELS.MAIN))
  dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.FEEDBACK))
  Api.users.getByUserGbgId(id).then(user => {
    dispatch(setUserData(MAIN_PROFILE_USER, convertUserInfoProperties(user.data.data)))
    user.data.data.role === 'user' && Api.users.getByUserTasks(id)
      .then(response => dispatch(setUserDataProperties(MAIN_PROFILE_USER, 'requests', convertPropertyRequests(response.data.data))))

  })
}


export default showFeedbackPage