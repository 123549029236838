import React from 'react'
import { Tabs } from '@vkontakte/vkui'

// import './CustomTabs.css'

const Index = ({ children }) => (
  <Tabs className='Custom__Tabs'>
    {children}
  </Tabs>
)

export default Index
