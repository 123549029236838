import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline'

import './LevelExecutorInfo.css'

const Index = ({ id, onClose }) => {
  return (
    <ModalCard
      id={id}
      onClose={onClose}
      icon={<Icon28InfoOutline width={56} height={56} />}
      actions={[
        <Button key={'ok'} mode='primary' onClick={() => onClose()}>Понятно</Button>
      ]}
    >
      <div className='TermsCooperation-Title'>
        Статусы консультантов
      </div>
      <div className='TermsCooperation-Text'>
        Наши консультанты прошли собеседования<br/>
        и все они хорошие, но есть еще дополнительная<br/>
        градация от проделанной ими работы.<br/><br/>
        Статус может быть: НОВИЧОК, MIDDLE, ПРОФИ или VIP.<br/><br/>
        А так же может сиять 🔥, это тоже очень круто,<br/>
        он есть не у всех :)
      </div>
    </ModalCard>
  )
}

export default Index
