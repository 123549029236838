import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'

import classes from './AskAdmin.module.css'


const Index = ({ id, onClose }) => {

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      header={'Связь с админом'}
      actions={[
        <Button key={'ok'} mode='primary' onClick={() => onClose()}>Окей</Button>
      ]}
    >
      <div className={classes.textText}>
        По всем вопросам пишите сюда - <a href="https://vk.com/gibelgauz" target={'_blank'} rel='noopener noreferrer'>Гбг</a>
      </div>
    </ModalCard>
  )
}

export default Index
