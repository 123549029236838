import Api from "../api/api";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {convertDialogList, setChatPagePopout, setDialogsList} from "../redux/reducers/chats-reducer";

const useDialogsList = (offset, dialogsLimit, currentUserId, chatPagePopout) => {

  const dispatch = useDispatch()

  useEffect(() => {
    let mounted = true;

    const getDialogsList = () => {
      Api.users.getDialogsList(currentUserId, offset, dialogsLimit).then((response) => {
        response.data.data && dispatch(setDialogsList(convertDialogList(response.data.data)))
        if(mounted && chatPagePopout){
          dispatch(setChatPagePopout(null))
        }
      })
    }

    getDialogsList()

  },[currentUserId])
}

export default useDialogsList