const MODALS = {
  PAGE: {
    CATEGORIES: 'categories',
    SUBCATEGORIES: 'subcategories',
    TIMING: 'timing',
    REVIEWS: 'profile-reviews',
    REQUESTS: 'profile-requests',
    AFFILIATE: 'profile-affiliate',
    REQUEST_FILTERS: 'request-filters',
    PAYMENT_SETTINGS: 'payment-settings',
    CREATE_QUESTION: 'create-question',
    PROFILE_CATEGORIES: 'profile-categories',
    PROFILE_PORTFOLIO: 'profile-portfolio',
    PROFILE_VIDEO: 'profile-video',
  },
  CARD: {
    CALC_INPUT_DATA: 'calc-input-data',
    CALC_INPUT_NUMBER_WITH_TEXT: 'calc-input-number-with-text',
    CREATE_CHAT: 'create-chat',
    CARD_TEST: 'test',
    PROFILE_PRO_INFO: 'profile-pro-info',
    PROFILE_PRO_INFO_ABOUT_OF_SYSTEM: 'profile-pro-info-about-of-system',
    TERMS_COOPERATION: 'terms-cooperation',
    LEVEL_EXECUTOR_INFO: 'level-executor-info',
    ASK_ADMIN: 'ask-admin',
    ADD_IN_MESSAGE: 'add-in-message',
    ADD_METHOD_PAID: 'add-method-paid',
    PROGRESS_ABOUT: 'progress-about',
    INFORMATION_CARD: 'information-card'
  }
}

export default MODALS
/**
 * Page
 */
export { default as ModalCategories } from './ModalPage/Categories'
export { default as ModalTiming } from './ModalPage/Timing'
export { default as ModalReviews } from './ModalPage/Reviews'
export { default as ModalRequests } from './ModalPage/Requests'
export { default as ModalAffiliate } from './ModalPage/Affiliate'
export { default as ModalRequestFilters } from './ModalPage/RequestFilters'
export { default as ModalPaymentSettings } from './ModalPage/PaymentSettings'
export { default as ModalCreateQuestion } from './ModalPage/CreateQuestion'
export { default as ModalProfileCategories } from './ModalPage/ProfileCategories'
export { default as ModalProfilePortfolio } from './ModalPage/ProfilePortfolio'
export { default as ModalProfileVideo } from './ModalPage/ProfileVideo'
/**
 * Card
 */
export { default as ModalCardAddInMessage } from './ModalCard/AddInMessage'
export { default as ModalCardTest } from './ModalCard/CardTest'
export { default as ModalCardCreateChat } from './ModalCard/CreateChat'
export { default as ModalCardInput } from './ModalCard/InputData'
export { default as ModalCardInputNumberWithText } from './ModalCard/InputNumberWithText'
export { default as ModalCardProInfo } from './ModalCard/ProInfo'
export { default as ModalCardProInfoAboutOfSystem } from './ModalCard/ProInfoAboutOfSystem'
export { default as ModalCardTerms } from './ModalCard/TermsCooperation'
export { default as ModalCardLevelExecutorInfo } from './ModalCard/LevelExecutorInfo'
export { default as ModalCardAskAdmin } from './ModalCard/AskAdmin'
export { default as ModalCardAddMethodPaid } from './ModalCard/AddMethodPaid'
export { default as ModalProgressAbout } from './ModalCard/ProgressAbout'
export { default as ModalInformationCard } from './ModalCard/InformationCard/InformationCard'
