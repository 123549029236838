import React, { useState }  from 'react'
import {
  Div,
  FormLayout,
  Group,
  Select,
  ModalPage,
  Input,
  Button,
  Radio,
  FormLayoutGroup,
  FormItem
} from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
// import CustomTabsItem from '../../../components/CustomTabs/CustomTabsItem'
import CustomTabs from '../../../components/CustomTabs'
import './Timing.css'
import {useDispatch} from "react-redux";
import {setSelectedTiming} from "../../../redux/reducers/tutors-reducer";

const Index = (props) => {
  const { id, onClose } = props

  const [activeTab, setActiveTab] = useState('now')

  const dispatch = useDispatch()

  const handleChangeTime = (value) => {
    dispatch(setSelectedTiming(value))
  }

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Выбор сроков' modalBack={onClose} /> )}>
      <Group className='TimingPage'>

        <FormLayout>
          <FormLayoutGroup>
            <FormItem top='Выберите сроки'>
              <Radio name='timing' onClick={() => {setActiveTab('now')}} defaultChecked>Сейчас</Radio>
              <Radio name='timing' onClick={() => {setActiveTab('date')}} >Выбрать срок</Radio>
              <Radio name='timing' onClick={() => {setActiveTab('online')}} >Забронировать дату и время</Radio>
            </FormItem>
          </FormLayoutGroup>
        </FormLayout>

        <CustomTabs>
          {/*<CustomTabsItem name='now' title='Сейчас' activeTab={activeTab} handleSetActiveTab={setActiveTab} />*/}
          {/*<CustomTabsItem name='date' title='Дата' activeTab={activeTab} handleSetActiveTab={setActiveTab} />*/}
          {/*<CustomTabsItem name='online' title='Онлайн' activeTab={activeTab} handleSetActiveTab={setActiveTab} />*/}
        </CustomTabs>

        <FormLayout>
          { activeTab === 'now'
            ? (<Select top='В течение' placeholder='Выберите время' onChange={e => handleChangeTime(e.target.value)}>
                <option value='В течение 30 минут'>30-ти минут</option>
                <option value='В течение часа'>Часа</option>
                <option value='В течение 2х часов'>2-х часов</option>
              </Select>)
            : activeTab === 'date'
              ? (<>
                  <div className='FormLayout__row-top'>Дата</div>
                  <Input type='date' placeholder='Выберите дату' onChange={e => handleChangeTime(e.target.value)} />
                </>)
              : (<>
                  <div className='FormLayout__row-top'>Онлайн</div>
                  <Input type='datetime-local' placeholder='Выберите дату и время' onChange={e => handleChangeTime(e.target.value)} />
                </>)
          }
        </FormLayout>
        <Div>
          <Button size="xl" onClick={onClose}>Готово</Button>
        </Div>
      </Group>
    </ModalPage>
  )
}

export default Index
