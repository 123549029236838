import React from 'react'
import {PanelHeader, PanelHeaderBack, PanelHeaderButton} from '@vkontakte/vkui'
import Icon28SettingsOutline from "@vkontakte/icons/dist/28/settings_outline";
import VIEWS from "../panels/panels";

const HeaderPanel = ({ children, backTo, panel, isMyProfile, goTo, withoutButtonBack, panelBack}) => {

  const buttonSetting = <Icon28SettingsOutline style={{ padding: 10}} onClick={() => goTo(VIEWS.PROFILE.PANELS.SETTINGS)} />
  const leftIcon = isMyProfile ? <PanelHeaderButton>{buttonSetting}</PanelHeaderButton> : panelBack ? <PanelHeaderBack onClick={() => backTo(panelBack)}/> : <PanelHeaderBack onClick={() => backTo(panel)}/>

  return (
    <PanelHeader left={!withoutButtonBack && leftIcon}>
      {children}
    </PanelHeader>
  )
}

export default HeaderPanel