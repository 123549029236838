import React, { useState } from 'react'
import { Panel, Select, Button, FormLayout, Textarea, Div } from '@vkontakte/vkui'

import HeaderPanel from '../../../components/HeaderPanel'
import VIEWS from '../../panels'

const Index = (props) => {
  const { id, header, backTo } = props
  const [typeAssistance, setTypeAssistance] = useState('')
  const [comment, setComment] = useState('Спасибо большое за помощь и проведение репетиторства, наконец-то разобрался с этой математикой')

  const onChangeType = (value) => setTypeAssistance(value)
  const onChangeComment = (value) => setComment(value)

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.CHAT.PANELS.DIALOG} backTo={backTo}>
        {header}
      </HeaderPanel>

      <FormLayout>
        <Select value={typeAssistance} top='Вид помощи' onChange={e => onChangeType(e.target.value)}>
          <option value='type1'>Консультация</option>
          <option value='text2'>Репетиторство</option>
        </Select>

        <Textarea value={comment} placeholder='Введите комментарий' onChange={e => onChangeComment(e.target.value)} name='comment' top='Комментарий' />
      </FormLayout>

      <Div>
        <Button size='xl' mode='primary' onClick={() => backTo(VIEWS.CHAT.PANELS.DIALOG)}>Опубликовать отзыв</Button>
      </Div>
    </Panel>
  )
}

export default Index
