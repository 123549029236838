import React from 'react'
import { RichCell } from '@vkontakte/vkui'

import Icon16CheckCircle from '@vkontakte/icons/dist/16/check_circle'
import Icon16CancelCircleOutline from '@vkontakte/icons/dist/16/cancel_circle_outline'
import Icon24LinkCircle from '@vkontakte/icons/dist/24/link_circle'
import moment from 'moment'

const AffiliateHistoryItem = ({ history }) => {
  const { type, userId, datetime, change } = history

  return (
    <RichCell
      className='AffiliateItem'
      before={type === 'withdraw'
        ? <Icon24LinkCircle />
        : change > 0
          ? <Icon16CheckCircle width={24} height={24} />
          : <Icon16CancelCircleOutline width={24} height={24} />
      }
      text={userId}
      caption={moment(datetime).format('L') + ' в ' + moment(datetime).format('LT')}
      after={`${change} ₽`}
    >
      {type === 'withdraw' ? 'Вывод средств' : change > 0 ? 'Зачисление' : 'Отмена'}
    </RichCell>
  )
}

export default AffiliateHistoryItem
