import React, {useEffect, useState} from 'react'
import {
  Panel,
  PanelHeader,
  PanelHeaderButton,
  SimpleCell,
  Cell, Placeholder, Button, Group
} from '@vkontakte/vkui'

import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back"
import AvatarOfLetter from "../../components/AvatarOfLetter/AvatarOfLetter"
import Icon16Fire from "@vkontakte/icons/dist/16/fire";
import './AllFeedback.css'
import Icon28CommentOutline from '@vkontakte/icons/dist/28/comment_outline'

import {useDispatch} from "react-redux";
import Api from "../../api/api";
import showFeedbackPage from "../../lib/showFeedbackPage";
import Icon56MessageReadOutline from "@vkontakte/icons/dist/56/message_read_outline";
import HeaderPanel from "../../components/HeaderPanel";
import VIEWS from "../panels";

const AllFeedback = ({ id, header, backTo}) => {

  const dispatch = useDispatch()

  const [ listReviews, setListReviews ] = useState([])

  useEffect(() => {
    Api.profile.getListReviewsAvailable().then( ({data}) => {

      data.data && setListReviews(data.data.map( review => {
        return {
          id: +review.id,
          nickname: review.name,
          top: review.top === 'y',
          online: +review.online === 1,
          avatar: { img: review.avatar && review.avatar.imgName }
        }
      }))
    })
  },[])

  return (
    <Panel id={id}>

      <HeaderPanel panel={VIEWS.MAIN.PANELS.ALL_FEEDBACK} backTo={backTo}>
        {header}
      </HeaderPanel>

      <Group>
        { listReviews.length > 0 && <Cell disabled description="О которых вы можете оставить отзыв">Пользователи:</Cell> }


        { listReviews.length > 0 && listReviews.map( (user, i) => {
          const srcAvatar = Api.avatars.getUrlByImgName(user.avatar && user.avatar.img)
          return <SimpleCell
                   key={i}
                   description={user.consultations && 'Консультаций проведено: ' + user.consultations}
                   onClick={() => showFeedbackPage(user.id, dispatch)}
                   before={<AvatarOfLetter id={user.id} srcAvatar={srcAvatar} name={user.nickname} online={user.online}/>}
                   className={'FeedbackUser'}
                   after={<Icon28CommentOutline/>}
                 >
                   { user.nickname }
                   { user.top && <Icon16Fire className='IconExecutorFire' width={14} height={14}/> }
                 </SimpleCell>
          })
        }
        { listReviews.length === 0 && <Placeholder
          icon={<Icon56MessageReadOutline />}
          action={<Button size="l" mode="tertiary" onClick={backTo}>Вернуть в главное меню</Button>}
          stretched
        >
          Все отзывы уже оставлены :) <br/> Спасибо!
        </Placeholder> }
        { listReviews.length > 0 && <Placeholder>Вы можете оставлять отзывы только о том, с кем сотрудничали</Placeholder> }
      </Group>

    </Panel>
  )
}

export default AllFeedback
