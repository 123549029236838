import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {goToPanel, setViewId} from "../redux/reducers/views-reducer";
import VIEWS from "../panels/panels";
import {
  getFullProfileUserData,
  MAIN_PROFILE_USER,
} from "../redux/reducers/profile-reducer";
import parseQueryString from "../lib/parseQueryString";
import getVkStorage from "../lib/bridgeUtilits/getVkStorage";
import {getCurrentUserId, getCurrentUserVkId} from "../redux/selectors/profile-selectors";
import {getActiveStory} from "../redux/selectors/view-selectors";
import showFeedbackPage from "../lib/showFeedbackPage";
import {setSelectedTest} from "../redux/reducers/main-reducer";
import {setNotification} from "../redux/reducers/global-reducer";

function useParams() {

  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)
  const currentUserVkId = useSelector(getCurrentUserVkId)

  const viewEpicPanel = useSelector(getActiveStory)

  const queryParams = useRef(parseQueryString(window.location.search))
  const hashParams = useRef(parseQueryString(window.location.hash))

  useEffect(() => {
    dispatch(setNotification(+queryParams.current.vk_are_notifications_enabled === 1))
  },[])

  const showProfile = (id) => {
    dispatch(goToPanel(VIEWS.EPIC.ID, VIEWS.EPIC.PANELS.MAIN))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.PROFILE))
    dispatch(getFullProfileUserData(id, MAIN_PROFILE_USER, true))
  }

  const showFeedback = (id) => {
    showFeedbackPage(id,dispatch)
  }

  const showTest = (id) => {
    dispatch(goToPanel(VIEWS.EPIC.ID, VIEWS.EPIC.PANELS.MAIN))
    dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.TEST))
    dispatch(setSelectedTest(id))
  }

  const goToEpicPanel = (panel) => {
    viewEpicPanel !== panel && dispatch(goToPanel(VIEWS.EPIC.ID, panel))
  }

  useEffect(() => {
    if (currentUserId) {

      const wasIntro = getVkStorage(currentUserVkId)
      !wasIntro && dispatch(setViewId(VIEWS.INTRO.ID))

      const [ createChat, main, user, tutors, chat, requests, profile, ref, payBills, setting, feedback, tests, test ] =
        [ 'create_chat', 'main', 'user', 'tutors', 'chat', 'requests', 'profile', 'ref', 'payBills', 'setting', 'feedback', 'tests', 'test' ]

      if (hashParams.current[createChat]) {
        showProfile(hashParams.current[createChat])
      } else if (hashParams.current[user]) {
        showProfile(hashParams.current[user])
      } else if (hashParams.current[test]) {
        showTest(hashParams.current[test])
      } else if (hashParams.current[feedback]) {
        showFeedback(hashParams.current[feedback])
      } else if (hashParams.current.page) {
        switch (hashParams.current.page) {
          case tutors:
            goToEpicPanel(VIEWS.EPIC.PANELS.RELATIONSHIP)
            dispatch(goToPanel(tutors, VIEWS.TUTORS.PANELS.CATEGORIES))
            break
          case chat:
            goToEpicPanel(VIEWS.EPIC.PANELS.CHAT)
            dispatch(goToPanel(chat, VIEWS.CHAT.PANELS.DIALOG_LIST))
            break
          case requests:
            goToEpicPanel(VIEWS.EPIC.PANELS.REQUESTS)
            dispatch(goToPanel(requests, VIEWS.REQUESTS.PANELS.REQUESTS_LIST))
            break
          case profile:
            goToEpicPanel(VIEWS.EPIC.PANELS.PROFILE)
            dispatch(goToPanel(profile, VIEWS.PROFILE.PANELS.PROFILE_INFO))
            break
          case setting:
            goToEpicPanel(VIEWS.EPIC.PANELS.PROFILE)
            dispatch(goToPanel(profile, VIEWS.PROFILE.PANELS.SETTINGS))
            break
          case tests:
            goToEpicPanel(VIEWS.EPIC.PANELS.MAIN)
            dispatch(goToPanel(main, VIEWS.MAIN.PANELS.TESTS))
            break
          case payBills:
            goToEpicPanel(VIEWS.EPIC.PANELS.PROFILE)
            dispatch(goToPanel(profile, VIEWS.PROFILE.PANELS.COMMISSION))
            break
          case ref:
            goToEpicPanel(VIEWS.EPIC.PANELS.MAIN)
            dispatch(goToPanel(main, VIEWS.MAIN.PANELS.ABOUT_BONUS_SYSTEM))
            break
          default:
            goToEpicPanel(VIEWS.EPIC.PANELS.MAIN)
        }
      } else {
        goToEpicPanel(VIEWS.EPIC.PANELS.MAIN)
      }
    }


    // if (hashParams.current.ref) {
    //   console.log('По партнерочке пришел, надо добавить юзера')
    // }
  },[hashParams, currentUserId])

}

export default useParams