import React, {useEffect, useState} from 'react'
import {
  Panel,
  PanelHeader,
  Group,
  HorizontalScroll,
  CardGrid,
  Div, useAppearance,
} from '@vkontakte/vkui'

import BannerItem from '../../../components/BannerItem'
import MyCard from '../../../components/MyCard'
import MiniCardItem from './MiniCardItem'
import './Main.css'

import {useSelector} from "react-redux";
import {getCurrentUserRole, getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import {getBigCards, getMiniCards} from "../../../redux/selectors/main-selectors";
import Api from "../../../api/api";

const Index = ({ id, header, showModal }) => {

  const currentUserRole = useSelector(getCurrentUserRole)

  const miniCards = useSelector(getMiniCards)
  const cards = useSelector(getBigCards)

  const snackbar = useSelector(getProfileSnackbar)

  const [ isListReviews, setIsListReviews ] = useState(false)

  useEffect(() => {
    Api.profile.getListReviewsAvailable().then( ({data}) => {
      data.data && setIsListReviews(true)
    })
  },[])

  const appearance = useAppearance()

  return (
    <Panel id={id}>
      <PanelHeader separator={false}>
        {header}
      </PanelHeader>
      <Group>

        <HorizontalScroll showArrows={false}>
          <Div style={{ display: 'flex' }}>
            {miniCards.filter(item => item.role.includes(currentUserRole)).map(card => <MiniCardItem key={card.id} title={card.title} image={card.image} panel={card.panel} modal={card.modal} epic={card.epic} showModal={showModal}/>)}
          </Div>
        </HorizontalScroll>

        <Group mode="plain">
            <CardGrid >
              <BannerItem id={2} color='blue' size={'s'} isLike={true}/>
              { isListReviews && <BannerItem id={1} size={'s'}/> }
            </CardGrid>
        </Group>

        <Div>
          {
            cards.map(card => <MyCard card={card} key={card.id}/>)
          }
        </Div>
        <Div style={{ opacity: 0.1}}>{appearance}</Div>
        { snackbar }
      </Group>
    </Panel>
  )
}

export default Index
