import React from 'react'
import {Panel, Group} from '@vkontakte/vkui'
import CategoryItem from '../../../components/CategoryItem'
import HeaderPanel from '../../../components/HeaderPanel'

import VIEWS from '../../panels'

const Index = (props) => {
  const { id, header, categories, selectCategory, backTo, withoutButtonBack } = props

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.TUTORS.PANELS.CATEGORIES} backTo={backTo} withoutButtonBack={withoutButtonBack} >
        {header}
      </HeaderPanel>

      <Group>
        {categories && categories.map((category) => (
          <CategoryItem key={category.id} category={category} onSelect={selectCategory} />
        ))}
      </Group>
    </Panel>
  )
}

export default Index
