import React, {useState} from 'react'
import { Panel, Gallery, Button } from '@vkontakte/vkui'

import './IntroGallery.css'
import SlideGallery from './SlideGallery/index'
import SlideConsultant from './SlideGallery/SlideConsultant'
import VIEWS from '../panels'
import {setViewId} from "../../redux/reducers/views-reducer";
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {getCurrentUserVkId} from "../../redux/selectors/profile-selectors";

const Index = ({ id }) => {

  const [slideIndex, setSlideIndex] = useState(0)
  const userId = useSelector(getCurrentUserVkId)

  const dispatch = useDispatch()
  const scheme = useSelector(state => state.globalReducer.scheme )

  const bulletsColor = scheme === 'space_gray' ? 'light' : 'dark'
  const logoUrl = scheme === 'space_gray' ? '/img/logo-text-dark.png' : '/img/logo-text.png'

  function changeSlideIndex (index) {
    if ( index === 2 ) {
      if(userId) {
        bridge.send('VKWebAppStorageSet', {
          key : JSON.stringify(userId),
          value : JSON.stringify(userId)
        })
      }
      dispatch(setViewId(VIEWS.EPIC.ID))
    } else {
      setSlideIndex(index + 1)
    }
  }

  return (
    <Panel id={id} className='GallerySlide'>
      <img className='GalleryLogo' src={logoUrl} alt='gbg study logo'/>

      <Gallery slideWidth='100%'
               slideIndex={slideIndex}
               onChange={slideIndex => setSlideIndex(slideIndex)}
               bullets={bulletsColor}
      >
        <SlideGallery
          title={<>ПРОВЕРЕННЫЕ <span>РЕПЕТИТОРЫ</span> И <span>КОНСУЛЬТАНТЫ</span></>}
          content={
            <>
              <SlideConsultant image='slide-1-avatar-1.png' />
              <SlideConsultant image='slide-1-avatar-2.png' />
              <SlideConsultant image='slide-1-avatar-3.png' />
            </>
          }
        />

        <SlideGallery
          title={<>БЕСПЛАТНЫЕ <span>ВИДЕОУРОКИ</span> и <span>КУРСЫ</span></>}
          content={<img src='/img/slider/slide-2.png' alt='slide-2' />}
        />

        <SlideGallery
          title={<>БЕЗ <span>РЕГИСТРАЦИИ</span> И <span>СМС</span> :)</>}
          // content={
          //   <div className='AddQuestionsToTest-Card' style={{ background: 'linear-gradient(180deg, #282828 0%, #000000 100%)' }}>
          //     <div className='Card-Title'>
          //       <span className='Card-Title Text'>Математика ( 1 курс )</span>
          //     </div>
          //     <div className='Card-subhead'>{`Пройдите тест, оцените \nсвои знания`}</div>
          //     <Button size='l' mode='overlay_outline' onClick={() => {}}>Пройти</Button>
          //   </div>
          // }
        />
      </Gallery>

      <Button className='GalleryButton' size='xl' onClick={() => changeSlideIndex(slideIndex)}>
        ОКИ
      </Button>
    </Panel>
  )
}

export default Index
