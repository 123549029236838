import React from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import './ReviewItem.css'
import {useSelector} from "react-redux";
import {getCurrentUserRole} from "../../../redux/selectors/profile-selectors";
import ROLES from "../../../roles";

const ReviewItem = ({ review }) => {

  const currentUserRole = useSelector(getCurrentUserRole)

  const { type, name, text, datetime, stars } = review
  const { onTime, prof, speed, talking, design } = stars

  const starConsultantName = [
    { name: 'Выполнение в срок', stars: onTime },
    { name: 'Цена', stars: prof },
    { name: 'Скорость ответа', stars: speed },
    { name: 'Приятное общение', stars: talking },
    { name: 'Качество оформления', stars: design },
  ]
  const starUserName = [
    { name: 'Скорость ответа', stars: speed },
    { name: 'Приятное общение', stars: talking },
  ]

  const countStar = [1, 2, 3, 4, 5]

  const userSender = name ? name : 'Без имени'

  return (
    <div className='ReviewItem'>
      <div className='ReviewItemTitle'>Задача</div>
      <div className='ReviewItemName'>
        {userSender ? userSender : 'User'}
        {countStar.map(count => (
          <div className={`Icon ${count <= type ? 'fill' : ''}`} key={count} />
        ))}
      </div>
      { currentUserRole === ROLES.CONSULTANT && starConsultantName.map( (item, i) => item.stars > 0 && <div key={i} className='ReviewItemText'>
        {item.name}: {item.stars}
      </div>) }
      { currentUserRole === ROLES.USER && starUserName.map( (item, i) => item.stars > 0 && <div key={i} className='ReviewItemText'>
        {item.name}: {item.stars}
      </div>) }
      {
        text && <div className='ReviewItemText'>
          Отзыв: {text}
        </div>
      }
      <div className='ReviewItemDate'>
        {moment(datetime).format('LL')}
      </div>
    </div>
  )
}

export default ReviewItem
