import React from 'react'
import { Checkbox, Separator } from '@vkontakte/vkui'
import Icon28SettingsOutline from '@vkontakte/icons/dist/28/settings_outline'

import './MyCheckbox.css'
import MODALS from '../../../modals/modals'
import roundBill from "../../../lib/mathUtilits/roundBill";

const MyCheckbox = ({ bill, showSettings, checked, handleCheckbox }) => {

  const handleSettings = (e) => {
    e.preventDefault()
    showSettings(MODALS.PAGE.PAYMENT_SETTINGS, bill)
  }

  return (
    <>
      <Checkbox className='CommissionCheckbox' onChange={() => handleCheckbox(bill.id)} checked={checked.includes(bill.id)}>
        <div className='left'>
          Заявка: { bill.taskId }
        </div>
        <div className='right'>
          { bill.price } ₽ → { roundBill(bill.price * bill.commissionPercent) } ₽
          <Icon28SettingsOutline
            className='CommissionCheckbox__icon'
            width={20}
            height={20}
            onClick={ e => handleSettings(e)} />
        </div>
      </Checkbox>

      <Separator style={{ marginTop: 0 }}/>
    </>
  )
}

export default MyCheckbox
