import React from 'react'
import {View} from '@vkontakte/vkui'

import RequestsList from './RequestsList'
import RequestPage from './RequestPage'

import VIEWS from '../panels'
import {useDispatch, useSelector} from "react-redux";
import useExecutorTasks from "../../useEffects/useExecutorTasks";
import Profile from "../Profile/ProfileInfo";
import {goBackPanel, goToPanel} from "../../redux/reducers/views-reducer";
import {loadUpTasks, setSelectRequest} from "../../redux/reducers/requests-reducer";
import {
  getCurrentUserId,
  getRequestsProfileUser
} from "../../redux/selectors/profile-selectors";
import {getRequestViews} from "../../redux/selectors/view-selectors";
import {getRequestsTasks, getSelectRequest} from "../../redux/selectors/request-selectors";

const Index = ({ id, showModal }) => {

  const dispatch = useDispatch()

  const { activePanel, history } = useSelector(getRequestViews)

  const currentUserId = useSelector(getCurrentUserId)
  const requestsProfileUser = useSelector(getRequestsProfileUser)

  const tasks = useSelector(getRequestsTasks)
  const selectRequest = useSelector(getSelectRequest)

  const [ offset, count ] = [ 0, 10 ]

  useExecutorTasks(currentUserId, tasks, offset, count)

  const goRequestPage = (request, panelId) => {
    dispatch(setSelectRequest(request))
    dispatch(goToPanel(VIEWS.REQUESTS.ID, panelId))
  }

  const goTo = (panel) => {
    dispatch(goToPanel(VIEWS.REQUESTS.ID, panel))
  }

  const goBack = () => {
    dispatch(goBackPanel(VIEWS.REQUESTS.ID, history[history.length - 2], history.length))
  }

  const loadUpTasksFun = () => {
    dispatch(loadUpTasks(currentUserId, count, tasks))
  }

  return (
      <View id={id} activePanel={activePanel} onSwipeBack={goBack} history={history}>

        <RequestsList id={VIEWS.REQUESTS.PANELS.REQUESTS_LIST}
                      header='Заявки'
                      selectRequest={goRequestPage}
                      showModal={showModal}
                      requests={tasks}
                      loadUpTasks={loadUpTasksFun}
        />

        <RequestPage id={VIEWS.REQUESTS.PANELS.REQUEST_PAGE}
                     header={selectRequest !== null ? selectRequest.idCategory : 'Заявка'}
                     request={selectRequest}
                     isConsultant={true}
                     backTo={goBack}
                     goTo={goTo}
                     showModal={showModal}/>

        <Profile id={VIEWS.REQUESTS.PANELS.PROFILE}
                 profile={requestsProfileUser}
                 showModal={showModal}
                 header='Профиль'
                 panelBack={VIEWS.REQUESTS.PANELS.PROFILE}
                 backTo={goBack} />
      </View>
  )
}

export default Index
