import React from "react"
import './AvatarOfLetter.css'
import {Avatar} from "@vkontakte/vkui";
import numberToColor from "../../lib/numberToColor";
import {useSelector} from "react-redux";

const AvatarOfLetter = ({id, srcAvatar, name, size = 48, online = false}) => {

  const scheme = useSelector(state => state.globalReducer.scheme )

  let userName = name

  if (!name) {
    userName = 'USER'
  }

  const idUser = id ? id : 1

  let firstLetterOfName = userName.substr(0, 2).toUpperCase()
  const gradient = numberToColor(Number(idUser.toString().slice(-2)))

  return (
      srcAvatar
        ? <Avatar size={size} src={srcAvatar} >{ online && <div className={'userInfoAvatarIsOnline' + scheme}/>}</Avatar>
        : <Avatar size={size} >
            <div className={"userInfoAvatar" + size} style={{  backgroundImage: gradient }}> { firstLetterOfName }
              { online && <div className={'userInfoAvatarIsOnline' + scheme}/> }
            </div>
          </Avatar>
  )
}

export default AvatarOfLetter