import React, {useState} from 'react'
import {Input, ModalCard, Div, Text, Button} from '@vkontakte/vkui'

const InputNumberWithText = (props) => {
  const { id, onClose, value } = props

  const [ number, setNumber ] = useState(0)
  const [ text, setText ] = useState('')

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      header='Оцените заявку'
      actions={[
        <Button key={'ok'} mode='primary' onClick={() => {
          value.func({number: +number, text: text})
          onClose()
        }}>Готово</Button>
      ]}
    >
      <Div>
        <Text weight="regular" style={{ marginBottom: 16 }}>Введите стоимость:</Text>
        <Input defaultValue={number} onChange={e => setNumber(e.target.value)}/>
      </Div>
      <Div>
        <Text weight="regular" style={{ marginBottom: 16 }}>Укажите комментарий (по желанию):</Text>
        <Input defaultValue={text} onChange={e => setText(e.target.value)}/>
      </Div>
    </ModalCard>
  )
}

export default InputNumberWithText
