const getPointProgress = (progress, counts) => {
  const progressPoints = progress.map((item,i) => {
    const arrayProgress = item.points.filter(point => counts[i] >= point)
    const maxProgress = arrayProgress[arrayProgress.length - 1]
    const indexMaxProgress = item.points.indexOf(maxProgress)
    return {id: i + 1, point: indexMaxProgress + 1}
  })

  return progressPoints
}

export default getPointProgress