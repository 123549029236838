import React from 'react'
import {View} from '@vkontakte/vkui'

import ProfileInfo from './ProfileInfo'
import Settings from './Settings'
import VIEWS from '../panels'
import Commission from "./Commission";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../redux/selectors/profile-selectors";
import {getProfileViews} from "../../redux/selectors/view-selectors";
import {goBackPanel, goToPanel} from "../../redux/reducers/views-reducer";
import {setSnackbar} from "../../redux/reducers/profile-reducer";
import Progress from "./Progress/Progress";

const Index = ({id, showModal}) => {

  const dispatch = useDispatch()

  const currentUser = useSelector(getCurrentUser)
  const { activePanel, history } = useSelector(getProfileViews)

  const goTo = (panel) => {
    dispatch(setSnackbar(null))
    dispatch(goToPanel(VIEWS.PROFILE.ID, panel))
  }

  const goBack = () => {
    dispatch(setSnackbar(null))
    dispatch(goBackPanel(VIEWS.PROFILE.ID, history[history.length - 2], history.length))
  }

  return (
      <View id={id} activePanel={activePanel} onSwipeBack={goBack} history={history}>
        <ProfileInfo id={VIEWS.PROFILE.PANELS.PROFILE_INFO} header='Профиль' profile={currentUser} goTo={goTo} showModal={showModal} isMyProfile={true} />
        <Settings showModal={showModal} id={VIEWS.PROFILE.PANELS.SETTINGS} header='Настройки' profile={currentUser} backTo={goBack} selectCity={goTo} />
        <Commission id={VIEWS.PROFILE.PANELS.COMMISSION} header='Комиссия' backTo={goBack} showModal={showModal}  />
        <Progress id={VIEWS.PROFILE.PANELS.PROGRESS} header={'Достижения'} backTo={goBack} currentUser={currentUser} />
      </View>
  )
}

export default Index
