const getExecutorLevel = (sum) => {
  const progress = {
    beginner: { name: 'НОВИЧОК', value: 15000 },
    middle: { name: 'MIDDLE', value: 50000 },
    profi: { name: 'ПРОФИ', value: 100000 },
    vip: { name: 'VIP', value: 1000000 },
  }

  if(sum < progress.beginner.value) {
    return progress.beginner
  } else if (sum < progress.middle.value) {
    return progress.middle
  } else if (sum < progress.profi.value) {
    return progress.profi
  } else if (sum > progress.profi.value){
    return progress.vip
  } else {
    return progress.beginner
  }}

export default getExecutorLevel