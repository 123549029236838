import React, { useState } from 'react'
import { View } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'

import Main from './Main'
import TestPanel from '../MainPanel/TestPanel'
import CalcPanel from './CalcPanel'
import VIEWS from '../panels'

const Index = ({ id, showModal }) => {
  const [activePanel, setActivePanel] = useState(VIEWS.USEFUL.PANELS.MAIN)
  const [history, setHistory] = useState([VIEWS.USEFUL.PANELS.MAIN])

  function goToPage (name) {
    setActivePanel(name)
    setHistory([...history, name])
  }

  const goBack = () => {
    if (history.length === 1) {
      bridge.send('VKWebAppClose', {'status': 'success'})
    } else if (history.length > 1) {
      history.pop()
      setActivePanel(history[history.length - 1])
    }
  }

  return (
      <View id={id} activePanel={activePanel} onSwipeBack={goBack} history={history}>
        <Main id={VIEWS.USEFUL.PANELS.MAIN} header='Полезное' changePanel={goToPage} />
        <TestPanel id={VIEWS.USEFUL.PANELS.TEST} header='Тест' backTo={goBack} />
        <CalcPanel id={VIEWS.USEFUL.PANELS.CALC} header='GBG study' backTo={goBack} inputAnswer={showModal} data={[]} />
      </View>
  )
}

export default Index
