import React, {useEffect, useState} from 'react'
import {Panel, Placeholder, Button, Group, Header, Cell, List, Div} from '@vkontakte/vkui'

import classes from './AboutBonusSystem.module.css'
import HeaderPanel from "../../../components/HeaderPanel";

import Icon24UserAddOutline from '@vkontakte/icons/dist/24/user_add_outline';
import Icon24ShareOutline from '@vkontakte/icons/dist/24/share_outline';
import {useSelector} from "react-redux";
import shareLink from "../../../lib/bridgeUtilits/shareLink";
import ProfileItemForList from "../../../components/ProfileItemForList";
import Api from "../../../api/api";
import {getCurrentUserId} from "../../../redux/selectors/profile-selectors";

const Index = ({id, backTo, header}) => {

  const refCode = useSelector(getCurrentUserId)

  const [ users, setUsers ] = useState([])

  const limitUser = 10

  useEffect(() => {
    const correctResponse = (users) => {
      return users && users.map( user => {
        return {
          id: user.idUser,
          avatar: {
            img: user.avatar && user.avatar.img_name
          },
          nickname: user.name,
          sum: user.sumIncome,
          top: user.top === 'y'
        }
      })
    }
    Api.app.getPartners(0, limitUser).then((r) => {
      setUsers(correctResponse(r.data.data))
    })
  },[])

  return (
    <Panel id={id}>
      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>

      <Group>

        <Placeholder
          icon={<Icon24UserAddOutline width={50} height={50} style={{ color: '#3F8AE0'}}/>}
          header="Приглашайте друзей"
          action={<Button size="s" before={<Icon24ShareOutline style={{ color: '--accent'}} />} onClick={() => shareLink('ref=' + refCode) }>Поделиться</Button>}
          className={classes.placeholder}
        >
          <span>С каждой оплаченной консультации получайте 10% на свой счет! Вывод средств осуществляется от 100 ₽</span>
        </Placeholder>

        <Group mode="plain" header={<Header mode="primary">Топ наших партнеров:</Header>}>
          <List style={{ marginBottom: '30px' }}>
            {users && users.map((user,i) => (
              i < limitUser && user.sum && <ProfileItemForList
                key={i}
                profile={user}
                count={user.sum + ' ₽'}
                text={'Доход: '}
                disabled
              />
            ))}
          </List>
        </Group>

        <Group mode="plain"
          header={<Header mode="secondary">Партнерская программа GBG STUDY</Header>}
          description="В нашем проекте работают только проверенные консультанты, которые прошли собеседование. Мы контролируем качество услуг, соблюдение честности в спорных ситуациях между консультантами и заказчиками, а также занимаемся привлечением аудитории. За это мы получаем доход в проекте. Этим доходом мы и будем делиться с вами, когда вы пригласите пользователя, который закажет что-то у нас или обратится к репетитору."
        >
          <Div>1) Рекомендуй наш сервис</Div>
          <Div>2) Делись специальной ссылкой</Div>
          <Div>3) Получай пассивный доход</Div>
        </Group>

      </Group>
    </Panel>
  )
}

export default Index
