import React from 'react'

import {setSnackbar} from "../redux/reducers/profile-reducer"
import {Snackbar} from "@vkontakte/vkui"
import {useDispatch} from "react-redux"

import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline'
import Icon28LinkCircleOutline from '@vkontakte/icons/dist/28/link_circle_outline'

export const MySnackbar = ({message, link = null}) => {

  const dispatch = useDispatch()

  const clickToSnackbar = () => {
    dispatch(setSnackbar(null))
  }

  return (
    <Snackbar layout='vertical'
              before={!link && <Icon28InfoOutline fill={'var(--text_link)'}/>}
              after={link && <a href={link} target={'_blank'} rel='noopener noreferrer'><Icon28LinkCircleOutline fill={'var(--text_link)'} onClick={() => clickToSnackbar()}/></a>}
              onClose={() => clickToSnackbar()}
              duration={5000} >
      { link ? <a href={link} target={'_blank'} style={{textDecoration: 'none', color: 'var(--text_link)'}} rel='noopener noreferrer'>{message}</a> : message }
    </Snackbar>
  )
}