import React from 'react'
import {Panel, PanelHeader, CardGrid, Group, Button, Div} from '@vkontakte/vkui'

import {useSelector} from "react-redux";
import RequestCard from '../../../components/RequestCard'
import sortByDate from "../../../lib/sortByDate";
import {getRequestsPagePopout} from "../../../redux/selectors/request-selectors";

const Index = ({ id, header, requests, selectRequest, loadUpTasks }) => {

  const requestsPagePopout = useSelector(getRequestsPagePopout)
  const sortRequests = sortByDate([...requests])

  return (
    <Panel id={id}>
      <PanelHeader>{header}</PanelHeader>
      {requestsPagePopout}
      <Group>
        { sortRequests.length > 0 && sortRequests.map((request, index) => (
          <RequestCard key={index} request={request} selectRequest={selectRequest} isConsultant/> ))}
        { !requestsPagePopout && sortRequests.length === 0 && 'Заявки отсутствуют' }
        { !requestsPagePopout && sortRequests.length !== 0 && <Div><Button size='l' mode='primary' stretched onClick={loadUpTasks}>Догрузить</Button></Div> }
      </Group>
    </Panel>
  )
}

export default Index
