import React, {useEffect} from 'react'
import {Epic} from '@vkontakte/vkui'
import VIEWS from './panels'
import {useDispatch} from "react-redux";

import Chat from './Chat'
import MyTabBar from '../components/MyTabBar'
import MainPanel from './MainPanel'
import Profile from "./Profile";
import Requests from './Requests'
import Tutors from './Tutors'
import UseFul from './UseFul'

import {CURRENT_USER, getProfileUserData} from "../redux/reducers/profile-reducer";

const EpicPanel = ({showModal, currentUserId, activeStory}) => {

  const dispatch = useDispatch()

  useEffect(() => { currentUserId && dispatch(getProfileUserData(currentUserId,CURRENT_USER)) },[currentUserId])

  return (
    <Epic activeStory={activeStory} tabbar={<MyTabBar activeStory={activeStory} />} >
      <MainPanel id={VIEWS.EPIC.PANELS.MAIN} showModal={showModal} />
      <Tutors id={VIEWS.EPIC.PANELS.RELATIONSHIP} showModal={showModal} />
      <Requests id={VIEWS.EPIC.PANELS.REQUESTS} showModal={showModal} />
      <Chat id={VIEWS.EPIC.PANELS.CHAT} showModal={showModal} />
      <UseFul id={VIEWS.EPIC.PANELS.USEFUL} showModal={showModal} />
      <Profile id={VIEWS.EPIC.PANELS.PROFILE} showModal={showModal} />
    </Epic>
  )
}

export default EpicPanel
