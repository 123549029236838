import React from 'react'
import { Link, SimpleCell } from '@vkontakte/vkui'

const Index = ({id, list, showCategory, text, count}) => {

  const after = count !==0 && <Link onClick={() => showCategory(id)}>Показать</Link>

  return (
    <SimpleCell className='CellProfileStats__HeaderText' disabled after={after}>
      {text} <span className='count'>{count}</span>
    </SimpleCell>
  )
}

export default Index
