import React, {useState, useRef} from 'react'

import {
  Button, Cell, Checkbox,
  Div,
  File,
  FormItem,
  FormLayout,
  Group,
  Input,
  Panel, Radio,
} from '@vkontakte/vkui'
import {useSelector} from "react-redux";
import {getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import HeaderPanel from "../../../components/HeaderPanel";
import Icon28DocumentOutline from "@vkontakte/icons/dist/28/document_outline";
import './AddQuestionsToTest.css'
import Api from "../../../api/api";

const AddQuestionsToTest = ({ id, header, backTo}) => {

  const snackbar = useSelector(getProfileSnackbar)

  const defaultQuestion = { text: '', type: 'fill', img: '', answers: [{text: 'Ответ', isCorrect: 1}] }

  const [questions, setQuestions] = useState([defaultQuestion])

  const addQuestionsToTest = () => {
    Api.tests.addQuestions(22, JSON.stringify(questions)).then(data => {
      // console.log(data)
    })
  }

  return (
    <Panel id={id}>

      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>

      <Group separator='hide'>

        <FormLayout>
          {
            questions && questions.map((value,i) => {
              return <Question key={i} number={i} questions={questions} setQuestions={setQuestions}/>
            })
          }
        </FormLayout>

        <Div style={{display: 'flex'}}>
          <Button size='s'
                  mode='commerce'
                  stretched
                  style={{ marginRight: 8 }}
                  onClick={() => setQuestions(questions => [...questions, defaultQuestion])}>
            Добавить вопрос
          </Button>
          <Button size='s'
                  mode='destructive'
                  stretched
                  onClick={() => setQuestions(questions => questions.filter( (question, i) => i !== questions.length - 1 ))}>
            Удалить вопрос
          </Button>
        </Div>
        <Div>
          <Button size='m' onClick={addQuestionsToTest} stretched>Готово</Button>
        </Div>

      </Group>

      {snackbar}
    </Panel>
  )
}

const Question = ({questions, setQuestions, number}) => {

  const questionType = [
    { key: 'fill', text: 'Ввод ответа вручную'},
    { key: 'single', text: 'Выбор единого правильно ответа'},
    { key: 'multiple', text: 'Выбор нескольких правильных ответов'},
  ]

  const answersCount = 4

  const fileInput  = useRef(null)

  const attachFiles = () => {
    const files = Object.values(fileInput.current.files)
    for (const file of files) {
      const reader = new FileReader()
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
        reader.onloadend = () => changeQuestionImage(reader.result)
      }
    }
  }

  const changeQuestionType = (value) => {
    setQuestions(questions => questions.map((question,i) => i !== number ? question : {...question, type: value, answers: [ {text: 'Ответ', isCorrect: 1} ]}))
  }

  const changeQuestionText = (value) => {
    setQuestions(questions => questions.map((question,i) => i !== number ? question : {...question, text: value}))
  }

  const changeQuestionFill = (value) => {
    setQuestions(questions => questions.map((question,i) => i !== number ? question : {...question, answers: [ {text: value, isCorrect: 1} ]}))
  }

  const changeQuestionImage = (img) => {
    // console.log('dsd')
    setQuestions( questions => questions.map((question,i) => i !== number ? question : {...question, img: img}))
  }

  const selectedAnswer = (answerNumber) => {
    questions[number].type === 'single' && setQuestions( questions => questions.map((question,i) => i !== number
      ? question
      : {...question, answers: question.answers.map( (answer, i) => i === answerNumber
          ? {text: answer.text, isCorrect: 1}
          : {text: answer.text, isCorrect: 0}
        )}
      ))
    questions[number].type === 'multiple' && setQuestions( questions => questions.map((question,i) => i !== number
      ? question
      : {...question, answers: question.answers.map( (answer, i) => i === answerNumber
          ? {text: answer.text, isCorrect: answer.isCorrect === 1 ? 0 : 1}
          : {...answer}
        )}
    ))
  }

  const changeAnswer = (answerNumber, value) => {
    setQuestions( questions => questions.map((question,i) => i !== number
      ? question
      : {...question, answers: question.answers.map( (answer, i) => i === answerNumber
          ? {...answer, text: value}
          : {...answer}
        )}
      ))
  }

  const addAnswer = () => {
    questions[number].answers.length < answersCount && setQuestions( questions => questions.map((question,i) => i !== number ? question : {...question, answers: [...question.answers, {text: 'Ответ', isCorrect: 0}]}))
  }

  return (
    <>
      <Group mode='plain'>

        <FormItem top={number + 1 + ') Вопрос'}>
          <Input name='question' type="text" value={questions[number].question} onChange={e => changeQuestionText(e.target.value)}/>
        </FormItem>

        { questions[number].img && <Cell removable onRemove={() => changeQuestionImage('')}>
          <div style={{ width: 300, height: 150, backgroundImage: `url(${questions[number].img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} />
        </Cell> }

        { !questions[number].img && <FormItem top='Добавить картинку'>
          <File getRef={fileInput}
                accept='image/*'
                onChange={() => attachFiles(number)}
                before={<Icon28DocumentOutline width={16} height={16} />} mode='secondary' controlSize='m'>
            ПРИКРЕПИТЬ
          </File>
        </FormItem> }

        <FormItem top='Тип вопроса'>
          { questionType.map(type => (
            <Radio checked={questions[number].type === type.key} name={"questionType" + number} onChange={ e => changeQuestionType(e.target.value)} key={type.key} value={type.key}>{type.text}</Radio>
          ))}
        </FormItem>

        {
          questions[number].type === 'fill' && <FormItem top='Введите верный ответ на вопрос'>
            <Input name='answer' type="text" value={questions[number].answers.text} onChange={e => changeQuestionFill(e.target.value)}/>
          </FormItem>
        }

        {
          questions[number].type === 'single' && <FormItem top='Введите ответы и выберите верный'>
              { questions[number].answers.map((answer,i) => (
                <Radio checked={questions[number].answers[i].isCorrect === 1}
                       onChange={() => selectedAnswer(i)}
                       key={'singleAnswer' + i}
                       name={'singleAnswer' + number}
                       value={i}>
                  <FormItem style={{ padding: 0}}>
                    <Input name={'singleAnswer' + i} type="text" value={answer.text} onChange={ e => changeAnswer(i, e.target.value) }/>
                  </FormItem>
                </Radio>
              ))}
            { questions[number].answers.length < answersCount && <Button onClick={addAnswer} style={{ marginTop: 12}} mode='overlay_outline' stretched>Добавить ответ</Button> }
          </FormItem>
        }

        {
          questions[number].type === 'multiple' && <FormItem top='Введите ответы и выберите верные'>
            { questions[number].answers.map((answer,i) => (
              <Checkbox checked={questions[number].answers[i].isCorrect === 1}
                        onChange={() => selectedAnswer(i)}
                        className={'AddQuestionsToTest__Checkbox'}
                        key={'multipleAnswer' + i}
                        name={'multipleAnswer' + number}>
                <FormItem style={{ padding: 0}}>
                  <Input name={'multipleAnswer' + i} type="text" value={answer.text} onChange={ e => changeAnswer(i, e.target.value) }/>
                </FormItem>
              </Checkbox>
            ))}
            { questions[number].answers.length < answersCount && <Button onClick={addAnswer} style={{ marginTop: 12}} mode='overlay_outline' stretched>Добавить ответ</Button> }
          </FormItem>
        }

      </Group>
    </>
  )
}

export default AddQuestionsToTest