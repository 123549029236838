import React, {useEffect, useState} from 'react'
import {Panel, Cell, Switch, Select, Header, Group, FormLayout, FormLayoutGroup, FormItem} from '@vkontakte/vkui'

import HeaderPanel from '../../../components/HeaderPanel'
import MyCheckbox from './MyCheckbox'
import './Commission.css'

import VIEWS from '../../panels'
import ButtonFixedBottom from '../../../components/ButtonFixedBottom'
import {useDispatch, useSelector} from 'react-redux';
import payToYandexLink from '../../../lib/payToYandexLink';
import {convertPropertyBills, CURRENT_USER, setUserDataProperties} from '../../../redux/reducers/profile-reducer';

import Icon24Copy from '@vkontakte/icons/dist/24/copy'
import Api from '../../../api/api';
import {
  getCurrentUser,
  getCurrentUserId,
  getCurrentUserNickname,
  getProfileSnackbar
} from '../../../redux/selectors/profile-selectors';
import bridgeCopyText from "../../../lib/bridgeUtilits/bridgeCopyText";
import roundBill from "../../../lib/mathUtilits/roundBill";
import Div from "@vkontakte/vkui/dist/components/Div/Div";

const Index = (props) => {

  const dispatch = useDispatch()

  const userId = useSelector(getCurrentUserId)
  const nickname = useSelector(getCurrentUserNickname)
  const snackbar = useSelector(getProfileSnackbar)
  const { id, bills, methodPaid } = useSelector(getCurrentUser)

  const [ copiedCard, setCopiedCard ] = useState(methodPaid[0].number)
  const [ checked, setChecked ] = useState(bills ? bills.map( bill => bill.id ) : [])

  const price = bills ? bills.filter( bill => checked.includes(bill.id) ).map( bill => bill.price ).reduce( (sum, current) => sum + current, 0) : 0
  const commission = bills ? roundBill(bills.filter( bill => checked.includes(bill.id) )
    .map( bill => bill.price * bill.commissionPercent )
    .reduce( (sum, current) => sum + roundBill(current), 0)) : 0

  useEffect(() => {
    const getByUserCommission = () => {
      Api.users.getByUserBills(id)
        .then(response => dispatch(setUserDataProperties(CURRENT_USER, 'bills', convertPropertyBills(response.data.data))))
    }

    getByUserCommission()

    const timer = setInterval(getByUserCommission, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const copyCard = (value) => {
    const text = value || copiedCard
    text && setCopiedCard(text)
    text && bridgeCopyText(text, dispatch)
  }

  const handleCheckbox = (id) => {
    setChecked(checked => {
      return checked.includes(id) ? checked.filter(checked => checked !== id) : [...checked, id]
    })
  }

  const handleRadio = () => {
    if ( bills.length === checked.length ) {
      setChecked([])
    } else {
      setChecked(bills.map( bill => bill.id ))
    }
  }

  const pay = () => {
    setChecked([])
    props.backTo(VIEWS.PROFILE.PANELS.PROFILE_INFO)
  }

  return (
    <Panel id={props.id}>
      <HeaderPanel panel={VIEWS.PROFILE.PANELS.PROFILE_INFO} backTo={props.backTo}>
        {props.header}
      </HeaderPanel>

      <FormLayout>
        <FormLayoutGroup>
            <FormItem top='Копирование вашей карты'>
              <Select placeholder="Не выбрана"
                      name='category'
                      value={copiedCard}
                      onChange={e => copyCard(e.target.value)}
                      options={methodPaid.map(card => ({ label: card.number, value: card.number }))}
              />
            </FormItem>

          <Cell after={ <Switch onChange={handleRadio}/> }> Выделение счетов </Cell>

          { bills && bills.map(bill => <MyCheckbox key={bill.id}
                                                   bill={bill}
                                                   showSettings={props.showModal}
                                                   checked={checked}
                                                   handleCheckbox={handleCheckbox} />) }

        </FormLayoutGroup>
      </FormLayout>

      <Cell className='CommissionSum' indicator={`${price} ₽ → ${commission} ₽`}>Сумма</Cell>

      <Cell className='CommissionSum Total' indicator={`${commission} ₽`}>Итого к оплате</Cell>

      { commission !== 0 &&
          <a href={payToYandexLink(id, checked, commission, nickname)} target='_blank' className='urlCommissionButton'>
            <ButtonFixedBottom text='Оплатить' handleClick={pay} />
          </a> }

      { snackbar }

    </Panel>
  )
}

export default Index
