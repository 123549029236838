import React from 'react'

import View from '@vkontakte/vkui/dist/components/View/View'

import VIEWS from './panels'
import IntroGallery from './IntroGallery/index.js'

const Intro = ({ id }) => (
  <View id={id} activePanel={VIEWS.INTRO.PANELS.SLIDER}>
    <IntroGallery id={VIEWS.INTRO.PANELS.SLIDER} />
  </View>
)

export default Intro
