import React from 'react'
import { TabsItem } from '@vkontakte/vkui'

const CustomTabsItem = ({ name, title, activeTab, handleSetActiveTab }) => (
  <TabsItem onClick={() => handleSetActiveTab(name)} selected={activeTab === name}>
    {title}
  </TabsItem>
)

export default CustomTabsItem
