import React from 'react'
import { List, ModalPage } from '@vkontakte/vkui'

import ReviewItem from './ReviewItem.js'
import ModalHeader from '../../../components/ModalHeader'

const Index = ({ id, onClose, reviews }) => {

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Отзывы' modalBack={onClose} /> )}>
      <List style={{ padding: '12px 28px 30px' }}>
        { reviews && reviews.map((review, i) => <ReviewItem review={review} key={i} />) }
      </List>
    </ModalPage>
  )
}

export default Index
