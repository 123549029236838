const numberToColor = (num) => {

  //https://webkul.github.io/coolhue/?2.0
  //https://webgradients.com/

  const gradients = {
    0: 'linear-gradient(60deg, #96deda 0%, #50c9c3 100%)',
    1: 'linear-gradient(to top, #4481eb 0%, #04befe 100%)',
    2: 'linear-gradient( 135deg, #FEB692 10%, #EA5455 100%)',
    3: 'linear-gradient( 135deg, #CE9FFC 10%, #7367F0 100%)',
    4: 'linear-gradient( 135deg, #90F7EC 10%, #32CCBC 100%)',
    5: 'linear-gradient( 135deg, #FFF6B7 10%, #F6416C 100%)',
    6: 'linear-gradient( 135deg, #81FBB8 10%, #28C76F 100%)',
    7: 'linear-gradient( 135deg, #E2B0FF 10%, #9F44D3 100%)',
    8: 'linear-gradient( 135deg, #F97794 10%, #623AA2 100%)',
    9: 'linear-gradient( 135deg, #FCCF31 10%, #F55555 100%)',
    10: 'linear-gradient( 135deg, #F761A1 10%, #8C1BAB 100%)',
    11: 'linear-gradient( 135deg, #43CBFF 10%, #9708CC 100%)',
    12: 'linear-gradient( 135deg, #5EFCE8 10%, #736EFE 100%)',
    13: 'linear-gradient( 135deg, #FAD7A1 10%, #E96D71 100%)',
    14: 'linear-gradient(to right, #ff758c 0%, #ff7eb3 100%)',
    15: 'linear-gradient( 135deg, #A0FE65 10%, #FA016D 100%)',
    16: 'linear-gradient( 135deg, #FFDB01 10%, #0E197D 100%)',
    17: 'linear-gradient( 135deg, #FEC163 10%, #DE4313 100%)',
    18: 'linear-gradient( 135deg, #92FFC0 10%, #002661 100%)',
    19: 'linear-gradient( 135deg, #EEAD92 10%, #6018DC 100%)',
    20: 'linear-gradient( 135deg, #F6CEEC 10%, #D939CD 100%)',
    21: 'linear-gradient( 135deg, #52E5E7 10%, #130CB7 100%)',
    22: 'linear-gradient( 135deg, #F1CA74 10%, #A64DB6 100%)',
    23: 'linear-gradient( 135deg, #E8D07A 10%, #5312D6 100%)',
    24: 'linear-gradient( 135deg, #EECE13 10%, #B210FF 100%)',
    25: 'linear-gradient( 135deg, #79F1A4 10%, #0E5CAD 100%)',
    26: 'linear-gradient( 135deg, #FDD819 10%, #E80505 100%)',
    27: 'linear-gradient( 135deg, #FFF3B0 10%, #CA26FF 100%)',
    28: 'linear-gradient(to top, #5f72bd 0%, #9b23ea 100%)',
    29: 'linear-gradient( 135deg, #F05F57 10%, #360940 100%)',
    30: 'linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%)',
    31: 'linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%)',
    32: 'linear-gradient( 135deg, #97ABFF 10%, #123597 100%)',
    33: 'linear-gradient( 135deg, #F5CBFF 10%, #C346C2 100%)',
    34: 'linear-gradient( 135deg, #FFF720 10%, #3CD500 100%)',
    35: 'linear-gradient( 135deg, #FF6FD8 10%, #3813C2 100%)',
    36: 'linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%)',
    37: 'linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%)',
    38: 'linear-gradient( 135deg, #C2FFD8 10%, #465EFB 100%)',
    39: 'linear-gradient( 135deg, #FD6585 10%, #0D25B9 100%)',
    40: 'linear-gradient( 135deg, #FD6E6A 10%, #FFC600 100%)',
    41: 'linear-gradient( 135deg, #65FDF0 10%, #1D6FA3 100%)',
    42: 'linear-gradient( 135deg, #6B73FF 10%, #000DFF 100%)',
    43: 'linear-gradient( 135deg, #FF7AF5 10%, #513162 100%)',
    44: 'linear-gradient(to top, #09203f 0%, #537895 100%)',
    45: 'linear-gradient( 135deg, #FFE985 10%, #FA742B 100%)',
    46: 'linear-gradient( 135deg, #FFA6B7 10%, #1E2AD2 100%)',
    47: 'linear-gradient( 135deg, #FFAA85 10%, #B3315F 100%)',
    48: 'linear-gradient( 135deg, #72EDF2 10%, #5151E5 100%)',
    49: 'linear-gradient( 135deg, #FF9D6C 10%, #BB4E75 100%)',
    50: 'linear-gradient( 135deg, #F6D242 10%, #FF52E5 100%)',
    51: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
    52: 'linear-gradient( 135deg, #3B2667 10%, #BC78EC 100%)',
    53: 'linear-gradient( 135deg, #70F570 10%, #49C628 100%)',
    54: 'linear-gradient( 135deg, #3C8CE7 10%, #00EAFF 100%)',
    55: 'linear-gradient( 135deg, #FAB2FF 10%, #1904E5 100%)',
    56: 'linear-gradient( 135deg, #81FFEF 10%, #F067B4 100%)',
    57: 'linear-gradient( 135deg, #FFA8A8 10%, #FCFF00 100%)',
    58: 'linear-gradient(to top, #c79081 0%, #dfa579 100%)',
    59: 'linear-gradient( 135deg, #FF96F9 10%, #C32BAC 100%)',
    60: 'linear-gradient(45deg, #874da2 0%, #c43a30 100%)',
    61: 'linear-gradient(to top, #e8198b 0%, #c7eafd 100%)',
    62: 'linear-gradient(to top, #209cff 0%, #68e0cf 100%)',
    63: 'linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)',
    64: 'linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%)',
    65: 'linear-gradient(to top, #cc208e 0%, #6713d2 100%)',
    66: 'linear-gradient(-45deg, #FFC796 0%, #FF6B95 100%)',
    67: 'linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%)',
    68: 'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
    69: 'linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%)',
    70: 'linear-gradient(to top, #b224ef 0%, #7579ff 100%)',
    71: 'linear-gradient(to top, #007adf 0%, #00ecbc 100%)',
    72: 'linear-gradient(-225deg, #A8BFFF 0%, #884D80 100%)',
    73: 'linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)',
    74: 'linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%)',
    75: 'linear-gradient(-225deg, #65379B 0%, #886AEA 53%, #6457C6 100%)',
    76: 'linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%)',
    77: 'linear-gradient(-225deg, #7742B2 0%, #F180FF 52%, #FD8BD9 100%)',
    78: 'linear-gradient(-225deg, #3D4E81 0%, #5753C9 48%, #6E7FF3 100%)',
  }

  while (!(num in gradients)) {
    num -= 10
  }

  return gradients[num]

};

export default numberToColor