import React from 'react'
import { Text, Div } from '@vkontakte/vkui'

import './MiniCardItem.css'
import {useDispatch} from "react-redux";
import {goToPanel, setActivePanel} from "../../../redux/reducers/views-reducer";
import VIEWS from "../../panels";

const MiniCardItem = (props) => {

  const { title, image, panel, modal, epic, showModal } = props

  const dispatch = useDispatch()

  const goTo = () => {
    if (panel) {
      dispatch(goToPanel(VIEWS.MAIN.ID, panel))
    } else if (modal) {
      showModal(modal)
    } else if (epic) {
      dispatch(setActivePanel(VIEWS.EPIC.ID, epic))
    }
  }

  return (
      <div className='MainCardItem' onClick={() => goTo()} >
        <img className='MainCardItemImage' src={'/img/main/cards/' + image} alt={title} />
        <Text weight='regular' style={{ fontSize: '14px' }}>{title}</Text>
      </div>
  )
}

export default MiniCardItem
