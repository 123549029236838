import React from 'react'
import { RichCell } from '@vkontakte/vkui'

import Icon24UserAddOutline from '@vkontakte/icons/dist/24/user_add_outline'

const AffiliateUserItem = ({ user }) => {

  const name = user && user.name ? user.name : 'Без имени'

  return (
    <RichCell
      className='AffiliateItem'
      before={<Icon24UserAddOutline />}
      text={name}
      disabled
    >
      Новый пользователь
    </RichCell>
  )
}

export default AffiliateUserItem
