import React from 'react'
import { ActionSheet, ActionSheetItem, IOS, platform } from '@vkontakte/vkui'
import Icon28Profile from '@vkontakte/icons/dist/28/profile'
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline'
// import Icon28VideocamOutline from '@vkontakte/icons/dist/28/videocam_outline'
// import Icon28CommentOutline from '@vkontakte/icons/dist/28/comment_outline'
import Icon28ShareOutline from '@vkontakte/icons/dist/28/share_outline'
import Icon28CopyOutline from '@vkontakte/icons/dist/28/copy_outline'
// import Icon28ChatsOutline from '@vkontakte/icons/dist/28/chats_outline'
import Icon28MoneyCircleOutline from '@vkontakte/icons/dist/28/money_circle_outline'

import ACTIONS from './actionsChat'
import ROLES from './../../roles'
import Icon28CommentOutline from "@vkontakte/icons/dist/28/comment_outline";

const osName = platform()

const items = [
  { id: 1, name: ACTIONS.OPEN_PROFILE, text: 'Открыть профиль', icon: <Icon28Profile />, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN ] },
  { id: 2, name: ACTIONS.TERMS_COOPERATION, text: 'Условия сотрудничества', icon: <Icon28InfoOutline />, role: [ROLES.USER, ROLES.CONSULTANT, ROLES.USER_ADMIN, ROLES.CONSULTANT_ADMIN] },
  // { id: 3, name: ACTIONS.TUTORING, text: 'Запланировать репетиторство', icon: <Icon28VideocamOutline />, role: [ROLES.USER] },
  // { id: 3, name: ACTIONS.RECOMMENDATION, text: 'Попросить рекомендацию', icon: <Icon28ChatsOutline />, role: [ROLES.CONSULTANT] },
  { id: 4, name: ACTIONS.FEEDBACK, text: 'Оставить отзыв', icon: <Icon28CommentOutline />, role: [ROLES.USER, ROLES.CONSULTANT] },
  { id: 5, name: ACTIONS.SHARE, text: 'Поделиться', icon: <Icon28ShareOutline />, role: [ROLES.USER, ROLES.CONSULTANT] },
  // { id: 6, name: ACTIONS.COPY_LINK, text: 'Скопировать ссылку', icon: <Icon28CopyOutline />, role: [ROLES.USER, ROLES.CONSULTANT] },
]

const ActionSheets = ({ userRole, setPopout, goTo }) => {

  return (
    <ActionSheet onClose={() => setPopout(null)}>
      {items.filter(item => item.role.includes(userRole)).map(item => (
        <ActionSheetItem key={item.id} autoclose before={item.icon} onClick={() => goTo(item.name)}>
          {item.text}
        </ActionSheetItem>
      ))}
      {osName === IOS && <ActionSheetItem autoclose mode='cancel'>Отменить</ActionSheetItem>}
    </ActionSheet>
  )
}

export default ActionSheets
