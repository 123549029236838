import React, {useState, useRef} from 'react'

import {
  Button,
  Div,
  File,
  FormItem,
  FormLayout,
  Group,
  Input,
  Panel,
  Select,
  SimpleCell,
  Textarea
} from '@vkontakte/vkui'
import {useDispatch, useSelector} from "react-redux";
import {getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import HeaderPanel from "../../../components/HeaderPanel";
import Icon28DocumentOutline from "@vkontakte/icons/dist/28/document_outline";
import Api from "../../../api/api";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import {goToPanel} from "../../../redux/reducers/views-reducer";
import VIEWS from "../../panels";

const CreateTest = ({ id, header, backTo}) => {

  const snackbar = useSelector(getProfileSnackbar)

  const [name, setName] = useState('')
  const [about, setAbout] = useState('')
  const [category, setCategory] = useState('')
  const [image, setImage] = useState('')

  const fileInput  = useRef(null)
  const dispatch = useDispatch()

  const attachFiles = () => {
    const files = Object.values(fileInput.current.files)

    for (const file of files) {
      const reader = new FileReader()
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
        reader.onloadend = () => setImage(reader.result)
      }
    }
  }

  const createTestRequest = () => {
    const isAllData = !!(name && about && category && image)
    isAllData && Api.tests.addTest(name,about,+category,image).then( data => {
      data.data.data === 'Test added' && dispatch(setSnackbar(<MySnackbar message={'Тест создан'}/>))
    })
    !isAllData && dispatch(setSnackbar(<MySnackbar message={'Не заполнены все поля или не добавлено изображение'}/>))
  }

  return (
    <Panel id={id}>

      <HeaderPanel backTo={backTo}>
        { header }
      </HeaderPanel>

      <Group separator='hide'>

        <Div>
          <Button size='m' onClick={()=>dispatch(goToPanel(VIEWS.MAIN.ID, VIEWS.MAIN.PANELS.ADD_QUESTIONS_TO_TEST))} stretched>Добавить вопросы</Button>
        </Div>

        <SimpleCell disabled>
          Го создавать
        </SimpleCell>
        <FormLayout>
          <FormItem top='Категория'>
            <Select placeholder="Не выбрана"
                    name='category'
                    value={category}
                    onChange={(e) => setCategory(+e.target.value)}
                    options={[1,2,3,4,5].map(category => ({ label: category, value: category }))}
            />
          </FormItem>
          <FormItem top='Название теста'>
            <Input name='name' type="text" value={name} onChange={(e) => setName(e.target.value)}/>
          </FormItem>
          <FormItem top='Описание теста'>
            <Textarea name='about' type="text" value={about} onChange={(e) => setAbout(e.target.value)}/>
          </FormItem>
          <FormItem top='Добавить картинку'>
            <File getRef={fileInput}
                  accept='image/*'
                  onChange={attachFiles}
                  before={<Icon28DocumentOutline width={16} height={16} />} mode='secondary' controlSize='m'>
              ПРИКРЕПИТЬ
            </File>
          </FormItem>
        </FormLayout>

        <Div>
          <Button size='m' onClick={createTestRequest} stretched>Создать тест</Button>
        </Div>

      </Group>

      {snackbar}
    </Panel>
  )
}

export default CreateTest
