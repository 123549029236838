import Api from "../../api/api";
import ROLES from "../../roles";
import MODALS from "../../modals/modals";

export const CURRENT_USER = 'currentUser'
export const MAIN_PROFILE_USER = 'mainProfileUser'
export const TUTORS_PROFILE_USER = 'tutorsProfileUser'
export const REQUESTS_PROFILE_USER = 'requestsProfileUser'
export const CHAT_PROFILE_USER = 'chatProfileUser'

const SET_FETCHED_USER = "SET_FETCHED_USER";
const SET_USER_DATA = "SET_USER_DATA";
const SET_ROLE = "SET_ROLE";
const SET_SNACKBAR = "SET_SNACKBAR";
const SET_IS_SHOW_MODAL_PROFILE = "SET_IS_SHOW_MODAL_PROFILE";

let initialState = {
  currentUser: {
  },

  profileInfo: [
    { text: 'Проконсультировал', role: ROLES.CONSULTANT, dontShowBtn: true },
    { text: 'Категории', showModal: MODALS.PAGE.PROFILE_CATEGORIES, role: ROLES.CONSULTANT },
    { text: 'Отзывы', showModal: MODALS.PAGE.REVIEWS },
    { text: 'Партнерка', showModal: MODALS.PAGE.AFFILIATE },
    { text: 'Примеры работ', showModal: MODALS.PAGE.PROFILE_PORTFOLIO, role: ROLES.CONSULTANT },
    { text: 'Видеоуроки', showModal: MODALS.PAGE.PROFILE_VIDEO, role: ROLES.CONSULTANT },
    { text: 'Заявки', showModal: MODALS.PAGE.REQUESTS, role: ROLES.USER },
  ],

  progress : [
    { id: 1, role: ROLES.ALL, label: 'Партнерка', description: 'Количество приглашенных пользователей', points: [1, 25, 100, 500, 1000], url: 'affiliates.svg',  },
    { id: 2, role: [ROLES.CONSULTANT], label: 'Доход', description: 'Сумарный доход', points: [1000, 10000, 50000, 100000, 1000000], url: 'money.svg' },
    { id: 3, role: ROLES.ALL, label: 'Отзывы', description: 'Количество отзывов оставленных пользователями', points: [1, 25, 100, 500, 1000], url: 'reviews.svg' },
    { id: 4, role: [ROLES.CONSULTANT], label: 'Консультации', description: 'Количество проведенных/полученных консультаций', points: [1, 25, 100, 500, 1000], url: 'consultations.svg' },
    { id: 5, role: [ROLES.CONSULTANT], label: 'Портфолио', description: 'Количество работ в портфолио', points: [1, 25, 100, 500, 1000], url: 'portfolio.svg' },
  ],

  mainProfileUser: {},
  tutorsProfileUser: {},
  requestsProfileUser: {},
  chatProfileUser: {},

  showDialogWith: '',
  snackbar: null,
  isShowModalProfile: [],
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FETCHED_USER: return { ...state, [action.whichUser]: { ...state[action.whichUser], ...action.data } }
    case SET_SNACKBAR: return { ...state, snackbar: action.snackbar }
    case SET_IS_SHOW_MODAL_PROFILE: return { ...state, isShowModalProfile: [...state.isShowModalProfile, action.modalId] }
    case SET_USER_DATA:
      if (action.data || action.data === 0 || action.data === null ) {
        return {
          ...state,
          [action.whichUser]: {
            ...state[action.whichUser],
            [action.nameData]: action.data
          }
        }
      }
      return state
    default:
      return state;
  }
};

export const setUserData = (whichUser, data) => ({ type: SET_FETCHED_USER, whichUser, data })

export const setUserDataProperties = (whichUser, nameData, data) => ({ type: SET_USER_DATA, whichUser, nameData, data })

export const setUserRole = (whichUser, role) => ({ type: SET_ROLE, whichUser, role })

export const setSnackbar = (snackbar) => ({ type: SET_SNACKBAR, snackbar })

export const setIsShowModalProfile = (modalId) => ({ type: SET_IS_SHOW_MODAL_PROFILE, modalId })

export const getFullProfileUserData = (id, whichUser, isSafe = false) => (dispatch) => {
  Api.users.getByUserGbgId(id).then(user => {
    dispatch(setUserData(whichUser, convertUserInfoProperties(user.data.data)))
  })
  dispatch(getProfileUserData(id, whichUser, isSafe))
}

export const getProfileUserData = (id, whichUser, isSafe = false) => (dispatch) => {
  const getSafeInfo = () => {
    Api.users.getByUserPartner(id).then( response => dispatch(setUserDataProperties(whichUser, 'affiliate', convertPropertyAffiliate(response.data.data))))
    Api.users.getByUserCategories(id).then( response => dispatch(setUserDataProperties(whichUser, 'categories', convertPropertyCategories(response.data.data))))
    Api.users.getByUserPortfolio(id).then( response => dispatch(setUserDataProperties(whichUser, 'portfolio', convertPropertyPortfolio(response.data.data))))
    Api.users.getByUserReviews(id).then( response => dispatch(setUserDataProperties(whichUser, 'reviews', convertPropertyReviews(response.data.data))))
    Api.users.getByUserVideo(id).then( response => dispatch(setUserDataProperties(whichUser, 'video', convertPropertyVideo(response.data.data))))
    Api.users.getExecutorRevenue(id).then( response => dispatch(setUserDataProperties(whichUser, 'revenue', convertPropertyRevenue(response.data.data))))
    Api.users.getByUserTasks(id).then( response => dispatch(setUserDataProperties(whichUser, 'requests', convertPropertyRequests(response.data.data))))
  }
  const getPrivateInfo = () => {
    Api.users.getByUserBills(id).then( response => dispatch(setUserDataProperties(whichUser, 'bills', convertPropertyBills(response.data.data))))
  }
  if (isSafe) {
    getSafeInfo()
  } else {
    getSafeInfo()
    getPrivateInfo()
  }
}

export const convertUserInfoProperties = (user) => {
  return user && {
    id: user.id,
    nickname: user.name,
    about: user.about,
    avatar: {
      img: user.avatar && user.avatar.imgName
    },
    city: user.city,
    consultations: user.taskDone,
    commissionPercent: user.commissionPercent,
    online: user.online === 1,
    role: user.role === 'executor' ? ROLES.CONSULTANT : ROLES.USER,
    top: user.top === 'y',
    methodPaid: user.methodPaid && user.methodPaid.map((methodPaid) => { return { id: +methodPaid.id, name: methodPaid.name, number: methodPaid.text }}),
  }
}

const convertPropertyAffiliate = (affiliate) => {
  return affiliate ? {
    balance: affiliate.amount,
    frozen: affiliate.sumNotDoneBill,
    userHistory: affiliate.user_account_history && affiliate.user_account_history.map((item) => { return { change: item.change, datetime: item.datetime, userId: item.idUser, type: item.type }}),
    invitedUsers: affiliate.user_invited && affiliate.user_invited.map( user => { return { userId: user.id_new_user, name: user.new_user_info && user.new_user_info.name}})
  } : {
    balance: 0,
    frozen: 0,
    userHistory: [],
    invitedUsers: []
  }
}

const convertPropertyCategories = (categories) => {
  return categories ? categories.map( category => { return { id: category.idCategory, name: category.category_info && category.category_info.name }}) : []
}

const convertPropertyPortfolio = (portfolio) => {
  return portfolio ? portfolio.map( portfolio => { return { img: portfolio.img_name, title: portfolio.title }}) : []
}

export const convertPropertyBills = (bills) => {
  return bills && bills.map( bill => {
    return {
      commissionPercent: bill['commission-percent'] / 100,
      datetime: bill.datetime,
      id: bill.id,
      taskId: bill.idTask,
      price: bill.price
    }
  })
}

const convertPropertyReviews = (reviews) => {
  return reviews ? reviews.map( review => {
    return {
      senderId: review.idSender,
      name: review.nameSender,
      datetime: review.datetime,
      text: review.text,
      type: review.type,
      stars: {
        onTime: review.onTime,
        prof: review.prof,
        speed: review.speed,
        talking: review.talking,
        design: review.design,
      }
    }
  }) : []
}

export const convertPropertyRequests = (requests) => {
  return requests ? requests.map( request => {
    return {
      id: request.id,
      category: { id: request.idCategory, name: request.category && request.category.name },
      desc: request.desc,
      datetime: request.date,
      taskFiles: request.taskFile && request.taskFile.map( file => { return { fileName: file.fileName, fileSize: file.fileSize }}),
      timeLimit: request.timeLimit }
  }) : []
}

const convertPropertyVideo = (videos) => {
  return videos ? videos.map( video => {
    return {
      link: video.link
    }
  }) : []
}

const convertPropertyRevenue = (revenue) => {
  return revenue && {
    value: +revenue.executorRevenue,
    status: revenue.status,
    commissionPercent: revenue.commissionPercent
  }
}

export default profileReducer;
