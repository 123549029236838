import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { RichCell } from '@vkontakte/vkui'

import './DialogListItem.css'

import Api from '../../../api/api'
import AvatarOfLetter from "../../../components/AvatarOfLetter/AvatarOfLetter";
import {useDispatch} from "react-redux";
import {
  CHAT_PROFILE_USER, getFullProfileUserData,
  getProfileUserData,
  setUserData
} from "../../../redux/reducers/profile-reducer";
import VIEWS from "../../panels";
import {convertProfileUser, setSelectedUserId} from "../../../redux/reducers/chats-reducer";

const DialogListItem = ({ user, goToPanel }) => {

  const dispatch = useDispatch()

  const showChat = () => {
    dispatch(setUserData(CHAT_PROFILE_USER, convertProfileUser(user)))
    dispatch(getFullProfileUserData(user.id, CHAT_PROFILE_USER, true))
    dispatch(setSelectedUserId(user.id))
    goToPanel(VIEWS.CHAT.PANELS.DIALOG)
  }

  const [ srcAvatar, setSrcAvatar ] = useState('')

  useEffect(() => {
    user.avatar && setSrcAvatar(Api.avatars.getUrlByImgName(user.avatar.img))
  },[user])

  const DescriptionMessage = () => (
    <>
      <span className='LastMessage'>
        { user.message && user.message + ' · ' }
        { user.sticker && 'Стикер' }
        { user.type === 'file' && 'Файл' }
      </span>
      <span className='Time'>
        { user.datetime && moment(user.datetime).startOf('minute').fromNow()}
      </span>
    </>
  )

  return (
    <RichCell
      className='DialogListItem'
      before={<AvatarOfLetter id={user.id} srcAvatar={srcAvatar} name={user.nickname} size={56} online={user.online}/>}
      caption={<DescriptionMessage/>}
      onClick={showChat}
    >
      { user.nickname ? user.nickname : 'User' + user.id }
    </RichCell>
  )
}

export default DialogListItem
