import React from 'react'
import { Group, ModalPage } from '@vkontakte/vkui'

import RequestCard from '../../../components/RequestCard'
import ModalHeader from '../../../components/ModalHeader'

const Index = ({ id, onClose, requests }) => {

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Заявки' modalBack={onClose} /> )}>
      <Group separator='hide'>
        <div style={{ paddingBottom: '30px' }}>
          {requests && requests.map((request, index) => (
            <RequestCard request={request} key={index} selectRequest={() => {}} />
          ))}
        </div>
      </Group>
    </ModalPage>
  )
}

export default Index
