import React from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import { Button, Card, Counter } from '@vkontakte/vkui'
import './RequestCard.css'
import VIEWS from '../../panels/panels'
import Div from "@vkontakte/vkui/dist/components/Div/Div";

const Index = ({ request, selectRequest }) => {

  const { category, desc, datetime, timeLimit, taskFiles } = request

  return (
    <Div style={{paddingBottom: 0, paddingTop: 0}}>
      <Card mode='shadow'>
        <div className='RequestCard' onClick={() => selectRequest(request, VIEWS.REQUESTS.PANELS.REQUEST_PAGE)}>
          <div className='RequestCard__content'>
            <div className='RequestCard__date'>
              { moment(datetime).format('L') + ' в ' + moment(datetime).format('LT') + (timeLimit ? ' · ' + timeLimit : ' · Сроки не указаны') }
            </div>
            <div className='RequestCard__title'>{ category.name ? category.name : 'Без названия'}</div>
            <div className='RequestCard__text'>{desc}</div>
            { taskFiles.length !== 0 && taskFiles && <Button mode='tertiary' after={<Counter size="s">{taskFiles.length}</Counter>}>Прикрепленные файлы</Button> }
          </div>
          <div className='RequestCard__button'>
          </div>
        </div>
      </Card>
    </Div>
  )
}

export default Index
