import React, {useEffect, useState} from 'react'
import { IOS, PanelHeader, PanelHeaderContent, PanelHeaderButton, platform } from '@vkontakte/vkui'

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown'
import './DialogHeader.css'

import VIEWS from '../../../panels'
import Api from '../../../../api/api'
import AvatarOfLetter from "../../../../components/AvatarOfLetter/AvatarOfLetter";

const Index = ({ chatProfileUser, backTo, actionSheet }) => {

  const osName = platform()
  const [ srcAvatar, setSrcAvatar ] = useState('')

  useEffect(() => {
    chatProfileUser.avatar && setSrcAvatar(Api.avatars.getUrlByImgName(chatProfileUser.avatar.img))
  },[])

  return (
      <PanelHeader
        left={backTo && <PanelHeaderButton onClick={() => backTo(VIEWS.CHAT.PANELS.DIALOG_LIST)}>
          {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
        </PanelHeaderButton>}
      >
        <PanelHeaderContent
          className='DialogHeader_Content'
          onClick={() => actionSheet()}
          status={chatProfileUser.online ? 'online' : `не в сети`}
          before={<AvatarOfLetter id={chatProfileUser.id} srcAvatar={srcAvatar} name={chatProfileUser.nickname} size={36} online={chatProfileUser.online}/>}
        >
          <div className='DialogHeader_User'>
            <div className='DialogHeader_UserName'>
              { chatProfileUser.nickname ? chatProfileUser.nickname : 'User' + chatProfileUser.id }
            </div>
            <Icon16Dropdown className='DialogHeader_Dropdown' />
          </div>
        </PanelHeaderContent>
      </PanelHeader>
    )
}

export default Index
