import axios from 'axios'
import parseQueryString from "../lib/parseQueryString";

export const APP_ID = 7427506

export const BASE_URL = 'https://gbg-study.ru'
// export const BASE_URL = 'https://debug.gbg-study.ru'
const BASE_API_URL = BASE_URL + '/api/v2'

const BASE_AVATAR_URL = BASE_URL + '/template/img/portfolio'

const token = localStorage.getItem('JWT') || false

const userParams = parseQueryString(window.location.search)

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: `Bearer ${token}`
  }
})

api.interceptors.response.use( async response => {
  let editResponse = {...response}

  if (response.data.error === 'Wrong number of segments' || response.data.error === 'Expired token' || response.data.error === 'Token invalid' || response.data.error === 'Signature verification failed') {
    await Api.tokens.setJwtToken(userParams).then(token => {
      api.defaults.headers.Authorization = `Bearer ${token.data}`
      localStorage.setItem('JWT', token.data)
    })
    await api.request({...response.config, headers: { Authorization: `Bearer ${localStorage.getItem('JWT')}`} })
      .then(r => editResponse = r)
  }
  return editResponse
},  error => {
  return Promise.reject(error)
})

class Api {
  static tokens = {
    setJwtToken: (userParams) => api.post('/setJwtToken', {userParams} ),
  }
  static categories = {
    get: (callback) => {
      api
        .get('/categories')
        .then(response => {
          callback(response.data)
        })
    },
    getCategoryInfo: (idCategory, callback) => {
      api
        .get('/categoryInfo', { params: { idCategory } })
        .then(response => {
          callback(response.data)
        })
    },
    getByParentId: (idParent, callback) => {
      api
        .get('/categories', { params: { idParent } })
        .then(response => {
          callback(response.data)
        })
    },
  }

  static executors = {
    getByCategoryId: (idCategory, callback) => {
      api
        .get('/executors', { params: { idCategory } })
        .then(response => {
          callback(response.data)
        })
    },
  }

  static avatars = {
    getUrlByImgName: (imgName) => {
      if (imgName) {
        return BASE_AVATAR_URL + '/' + imgName
      }
    },
    getUrlByImgUser: (imgName) => {
      if (imgName) {
        return BASE_AVATAR_URL + '/' + imgName
      }
    },
  }

  static tests = {
    getCategoriesWithTests: () => api.get('/categoriesWithTests', { params: {} }),
    getTestsByCategory: (idCategory,published) => api.get('/testsByCategory', { params: {idCategory, published} }),
    getTestQuestions: (idTest) => api.get('/testQuestions', { params: {idTest} }),
    sendAnswersTest: (idTest,answers) => api.get('/sendAnswers', { params: {idTest, answers} }),
    addTest: (testName, about, idCategory, img) => (
      api.post('/addTest', {testName, about, idCategory, img} )
    ),
    addQuestions: (idTest, questions) => (
      api.post('/addQuestionsToTest', {idTest, questions} )
    )
  }

  static tasks = {
    sendTask: (idCategory, timeLimit, desc, files) => (
      api.post('/sendTask', {idCategory, timeLimit, desc, files} )
    ),
  }

  static profile = {
    settingInfo: (name, about, city) => api.get('/updateUserInfo', { params: {name, about, city} }),
    getRevenueStatuses: () => api.get('/revenueStatuses', { params: {} }),
    addMethodPaid: (name, number) => api.get('/setMethodPaid', { params: {name, number} }),
    deleteMethodPaid: (idMethod) => api.get('/delMethodPaid', { params: {idMethod} }),
    getListReviewsAvailable: () => api.get('/listReviewAvailable', { params: {} }),
    payThroughPartnerProgram: (idInvoice) => api.get('/payThroughPartnerProgram', { params: {idInvoice} }),
  }

  static dialogs = {
    createChat: (idUser, idExecutor) => api.get('/dialogStart', { params: { idUser, idExecutor } }),
    sendMessageInOffice: (idUser, idTunnel, message) => api.get('/sendMessageInOffice', { params: { idUser, idTunnel, message } }),
    sendBill: (idUserSend, idUserGet, priceInvoice) => api.get('/sendInvoice', { params: { idUserSend, idUserGet, priceInvoice } }),
  }

  static users = {
    addUser: (idChat, firstName, lastName, ref) => (
      api.post('/addUser', { idChat, firstName, lastName, ref })
    ),
    sendMessage: ( idUserSend, idUserGet, type, text, file, caption, sticker) => (
      api.post('/sendMessage',  { idUserSend, idUserGet, type, text, file, caption, sticker  } )
    ),

    getByUserGbgId: (idUser) => api.get('/userInfoById', { params: { idUser } }),
    getByUserVkId: (idUser) => api.get('/selfInfoByVkId', { params: { idUser } }),
    getByUserCategories: (idUser) => api.get('/userCategories', { params: { idUser } }),
    getDialogsList: (idUser, offset, limit) => api.get('/userDialogs', { params: {idUser, offset, limit}}),
    getByUserMessages: (idUser, idUserInterlocutor, offset, limit) => api.get('/userDialog', { params: { idUser, idUserInterlocutor, offset, limit } }),
    getByUserPartner: (idUser) => api.get('/userPartner', { params: { idUser } }),
    getByUserBills: (idUser) => api.get('/notDoneBills', { params: { idUser } }),
    getByUserPortfolio: (idUser) => api.get('/userPortfolio', { params: { idUser } }),
    getByUserReviews: (idUser) => api.get('/userReviews', { params: { idUser } }),
    getByCategoryTask: (idCategory, offset, limit) => api.get('/tasksByCategory', { params: { idCategory, offset, limit } }),
    getByUserTasks: (idUser) => api.get('/userTasks', { params: { idUser } }),
    getExecutorRevenue: (idUser) => api.get('/executorRevenue', { params: { idUser } }),
    getExecutorTasks: (idUser, offset, limit) => api.get('/executorTasks', { params: { idUser, offset, limit } }),
    getByUserVideo: (idUser) => api.get('/userVideo', { params: { idUser } }),

    checkReviewAvailable: (idUserGet) => api.get('/checkReviewAvailable', { params: { idUserGet } }),
    sendReview: (idUserGet, text, talking, speed, onTime, design, prof) => api.get('/sendReview', { params: { idUserGet, text, talking, speed, onTime, design, prof } }),

    setPriceToTask: (idTask, price, message) => api.get('/setPriceToTask', { params: { idTask, price, message} }),
  }

  static app = {
    getPartners: (offset, limit) => api.get('/partnersWithIncome', {params: {offset, limit}})
  }
}

export default Api