import React from 'react'
import { Panel, Group, List } from '@vkontakte/vkui'

import VIEWS from '../../panels'
import HeaderPanel from '../../../components/HeaderPanel'
import ProfileItemForList from "../../../components/ProfileItemForList";
import Icon28MessageOutline from "@vkontakte/icons/dist/28/message_outline";
import ButtonFixedBottom from "../../../components/ButtonFixedBottom";

const Index = (props) => {
  const { id, header, backTo, executors, selectExecutor, publishTo } = props
  const iconMessageSend = <Icon28MessageOutline />

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.TUTORS.PANELS.SUB_CATEGORIES} backTo={backTo}>
        {header}
      </HeaderPanel>

      <Group>
        <List style={{ marginBottom: '70px' }}>
          {executors && executors.sort((a, b) => a.consultations < b.consultations ? 1 : -1).map(executor => (
            <ProfileItemForList
              key={executor.id}
              profile={executor}
              func={() => selectExecutor(VIEWS.TUTORS.PANELS.PROFILE, executor)}
              icon={iconMessageSend}
              count={executor.consultations}
              text={'Консультаций проведено: '}
            />
          ))}
        </List>
      </Group>

      {/*<ButtonFixedBottom*/}
      {/*  text='Опубликовать заявку'*/}
      {/*  mode='secondary'*/}
      {/*  handleClick={() => publishTo(VIEWS.TUTORS.PANELS.PUBLISH_REQUEST)}*/}
      {/*/>*/}
    </Panel>
  )
}

export default Index
