import React from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import {CardScroll, Card, SimpleCell, HorizontalScroll, Div} from '@vkontakte/vkui'

import Icon28DocumentOutline from '@vkontakte/icons/dist/28/document_outline'

import './Comment.css'
import getReadableFileSize from "../../lib/getReadableFileSize";
import showNativeGallery from "../../lib/bridgeUtilits/showNativeGallery";
import {Icon56DownloadSquareOutline} from "@vkontakte/icons";

const Index = ({comment}) => {

  const {text, date} = comment

  return (
    <Card size='l' mode='outline'>
      <div className='CommentCard'>
        <div className='CommentCard__content'>
          <div className='CommentCard__date'>
            {moment(date*1000).format('L') + ' в ' + moment(date*1000).format('LT')}
          </div>
          <div className='CommentCard__text'>
            {text}
          </div>

        </div>
      </div>
      { comment.attachments && comment.attachments.map( (file,i) => {
          return file.doc.type !== 4 && <Document key={i} size={file.doc.size} url={file.doc.url} title={file.doc.title}/>
      })}
      <HorizontalScroll className={'CommentCard__cardScroll'}>
        <div style={{ display: 'flex' }}>
        { comment.attachments && comment.attachments.map( (file,i) => {
          return file.doc.type === 4 && <Image key={i} url={file.doc.preview.photo.sizes.filter( size => size.type === 'o')[0].src} urlSmall={file.doc.preview.photo.sizes[0].src}/>
        })}
        </div>
      </HorizontalScroll>
    </Card>
  )
}

const Document = ({size, url, title}) => {
  return <SimpleCell
    description={getReadableFileSize(size) || 'Размер неизвестен'}
    before={<Icon28DocumentOutline />}
    disabled
    after={<a href={url} download target="_blank" rel="noopener noreferrer"><Icon56DownloadSquareOutline fill={'var(--text_link)'} width={28} height={28}/></a>}>
    {title}
  </SimpleCell>
}

const Image = ({url, urlSmall}) => {
  return <div style={{ marginLeft: 12}}>
      <div onClick={() => showNativeGallery([url])} style={{ width: 144, height: 96, background: `no-repeat url(${urlSmall})`, backgroundSize: '100%'}} />
    </div>
}

export default Index
