import React from 'react'
import {Button, ModalCard} from '@vkontakte/vkui'

import './InformationCard.css'

const InformationCard = ({ id, onClose, data }) => {

  const customClose = () => {
    onClose()
    data.customClose()
  }


  return (
    <ModalCard
      id={id}
      onClose={customClose}
      header={data.header}
      subheader={data.subheader}
      actionsLayout={'horizontal'}
      icon={data.icon}
      actions={[
          <Button key="ok" size="l" mode="primary" onClick={customClose}>
            Ок
          </Button>
        ]}
    />
  )
}

export default InformationCard
