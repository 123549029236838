import React, {useState} from 'react'
import {Input, ModalCard, Div, FormLayout, Button} from '@vkontakte/vkui'
import './AddMethodPaid.css'
import MODALS from "../../modals"
import {useDispatch, useSelector} from "react-redux";
import {CURRENT_USER, setSnackbar, setUserDataProperties} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import Api from "../../../api/api";
import {getCurrentUser} from "../../../redux/selectors/profile-selectors";

const AddMethodPaid = ({ id, onClose, showModal }) => {

  const [ name, setName ] = useState('')
  const [ number, setNumber ] = useState('')
  const methodPaid = useSelector(getCurrentUser).methodPaid

  const dispatch = useDispatch()

  const addMethodPaid = () => {
    Api.profile.addMethodPaid(name, number.replace(/\s/g, '')).then(({data}) => {
      if (data.data) {
        dispatch(setSnackbar(<MySnackbar message={'Карта добавлена'}/>))
        dispatch(setUserDataProperties(CURRENT_USER, 'methodPaid', methodPaid ? [...methodPaid, { id: +data.data, name: name, number: number }] : [{ id: +data.data, name: name, number: number }]))
        onClose()
      } else if (data.error === 'Name characters incorrect') {
        dispatch(setSnackbar(<MySnackbar message={'Name characters incorrect'}/>))
        onClose()
      } else if (data.error === 'Name length incorrect') {
        dispatch(setSnackbar(<MySnackbar message={'Name length incorrect'}/>))
        onClose()
      } else if (data.error === 'Number length incorrect') {
        dispatch(setSnackbar(<MySnackbar message={'Number length incorrect'}/>))
        onClose()
      }
    })
  }

  return (
    <ModalCard
      id={id}
      onClose={onClose}
      actions={[
        <Button key={'cancel'} mode='secondary' onClick={() => onClose()}>Отмена</Button>,
        <Button key={'add'} mode='primary' onClick={() => addMethodPaid()}>Добавить</Button>
      ]}
    >
      <div className='ModalCardProInfo-Title'>
        { 'Добавить карту' }
      </div>
      <div className='ModalCardProInfo-Text'>
        <FormLayout>

        <Input name='name' type="text" value={name} top='Название' onChange={(e) => setName(e.target.value)}/>
        <Input name='number' type="text" value={number} top='Номер карты' onChange={(e) => setNumber(e.target.value)}/>
        </FormLayout>
      </div>
    </ModalCard>
  )
}

export default AddMethodPaid
