import React from 'react'
import Icon28FavoriteOutline from '@vkontakte/icons/dist/28/favorite_outline'

import './SlideConsultant.css'

const Index = ({ image }) => {
  return (
    <div className='SlideConsultant'>
      <div className='SlideConsultant__avatar'>
        <img src={`/img/slider/${image}`} alt={image} />
      </div>
      <div className='SlideConsultant__stars'>
        <Icon28FavoriteOutline width={21} height={21} />
        <Icon28FavoriteOutline width={21} height={21} />
        <Icon28FavoriteOutline width={21} height={21} />
      </div>
    </div>
  )
}

export default Index
