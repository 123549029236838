import React from 'react'
import { FormLayout, Group, ModalPage } from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
import './ProfileVideo.css'

const Index = ({ id, onClose, videos }) => {

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Видеоуроки' modalBack={onClose} /> )}>
      <FormLayout>
        <Group className='ProfileVideo'>
          <div className='Block' >
          {videos.map((video, i) => (
              <div className='VideoBlock' key={i}>
                <iframe src={video.link.replace('watch?v=', 'embed/')} title={video.link}/>
              </div>
          ))}
          </div>
        </Group>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
