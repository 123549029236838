import React, { useState } from 'react'
import { Button, FormLayout, ModalPage, Select} from '@vkontakte/vkui'

import ModalHeader from '../../../components/ModalHeader'
import './CreateQuestion.css'

const Index = ({ id, onClose }) => {
  const [question, setQuestion] = useState('')
  const [typeAnswer, setTypeAnswer] = useState('')
  const [numberAnswer, setNumberAnswer] = useState('')
  const [numberOtherAnswer, setNumberOtherAnswer] = useState('')

  const handleChangeQuestion = (e) => setQuestion(e.target.value)
  const handleChangeTypeAnswer = (e) => setTypeAnswer(e.target.value)
  const handleChangeNumberAnswer = (e) => setNumberAnswer(e.target.value)
  const handleChangeNumberOtherAnswer = (e) => setNumberOtherAnswer(e.target.value)

  return (
    <ModalPage id={id} onClose={onClose} className='CreateQuestion' header={(<ModalHeader title='Вопрос' modalBack={onClose} /> )}>
      <FormLayout>
        <Select value={question} top='Вопрос' placeholder='Текст' onChange={handleChangeQuestion}>
          <option value='text'>Текст</option>
          <option value='text2'>Текст 2</option>
          <option value='text3'>Текст 3</option>
        </Select>

        <Select value={typeAnswer} top='Тип ответа' placeholder='С выбором ответа' onChange={handleChangeTypeAnswer}>
          <option value='chooseAnswer'>С выбором ответа</option>
          <option value='chooseAnswer2'>С выбором ответа2</option>
          <option value='chooseAnswer3'>С выбором ответа3</option>
        </Select>

        <Select value={numberAnswer} top='Количество ответов' placeholder='Более одного' onChange={handleChangeNumberAnswer}>
          <option value='numberAnswerOne'>Два</option>
          <option value='numberAnswerTwo'>Три</option>
          <option value='numberAnswerThree'>Четрые</option>
        </Select>

        <Select value={numberOtherAnswer} top='Количество ответов' placeholder='Более одного' onChange={handleChangeNumberOtherAnswer}>
          <option value='numberAnswer_One'>1</option>
          <option value='numberAnswer_Two'>2</option>
          <option value='numberAnswer_Three'>3</option>
        </Select>

        <div className='CreateQuestion__button'>
          <Button size='xl' mode='primary' onClick={() => onClose()}>Показать результаты</Button>
        </div>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
