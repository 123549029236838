import React from 'react'
import { Panel, Header, FormLayout, Text, Button, Caption } from '@vkontakte/vkui'

import Icon28ShareOutline from '@vkontakte/icons/dist/28/share_outline'
import HeaderPanel from '../../../components/HeaderPanel'
import './CalcPanel.css'

import VIEWS from '../../panels'
import MODALS from '../../../modals/modals'

const Index = ({ id, header, backTo, inputAnswer }) => {
  const defaultValues = [
    { id: 0, data: 10 },
    { id: 1, data: -5 },
    { id: 2, data: 240 },
    { id: 3, data: 15 },
    { id: 4, data: 30 },
    { id: 5, data: -15 }
  ]

  // const [values, setValues] = useState(defaultValues)

  const changeAnswer = (value) => {
    inputAnswer(MODALS.CARD.CALC_INPUT_DATA, { input: value.data })
  }

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.USEFUL.PANELS.MAIN} backTo={backTo}>
        {header}
      </HeaderPanel>

      <Header aside={
        <Button mode='outline' before={<Icon28ShareOutline width={16} height={16} /> }>
          ПОДЕЛИТЬСЯ
        </Button>
      }>
        СИСТЕМА УРАВНЕНИЙ
      </Header>

      <FormLayout>
        <div>
          <Caption className='CalcPanel_Title' level='1' weight='regular'>
            Система из двух уравнений
          </Caption>

          <div className='equation'>
            <div>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[0])}>
                {defaultValues[0].data + ' x₁'}
              </Button>
              <span> + </span>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[1])}>
                {defaultValues[1].data + ' x₂'}
              </Button>
              <span> = </span>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[2])}>
                {defaultValues[2].data}
              </Button>
            </div>

            <div>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[3])}>
                {defaultValues[3].data + ' x₁'}
              </Button>
              <span> + </span>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[4])}>
                {defaultValues[4].data + ' x₂'}
              </Button>
              <span> = </span>
              <Button size='s' mode='secondary' onClick={() => changeAnswer(defaultValues[5])}>
                {defaultValues[5].data}
              </Button>
            </div>
          </div>
        </div>

        <div>
          <Caption className='CalcPanel_Title' level='1' weight='regular'>
            Результат
          </Caption>

          <Text className='CalcPanel_Result'>
            <div>
              x₁ = {19}
            </div>
            <div>
              x₂ = {10}
            </div>
          </Text>
        </div>
      </FormLayout>
    </Panel>
  )
}

export default Index
