import React, {useState, useRef, useEffect} from 'react'
import {Panel, FixedLayout, Button} from '@vkontakte/vkui'

import DialogHeader from './DialogHeader'
import DialogMessageItem from './DialogMessageItem'
import DialogWriteBar from './DialogWriteBar/index'

import './Dialog.css'
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../api/api";
import useMessages from "../../../useEffects/useMessages";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import {getCurrentUserId, getProfileSnackbar} from "../../../redux/selectors/profile-selectors";
import {setSnackbar} from "../../../redux/reducers/profile-reducer";
import {MySnackbar} from "../../../components/MySnackbar";
import {setMyTabs} from "../../../redux/reducers/views-reducer";

const Index = ({ id, chatProfileUser, backTo, actionSheet, messagesLimit, setMessagesLimit, showModal, sendBill }) => {

  const currentUserId = useSelector(getCurrentUserId)
  const chatProfileUserId = chatProfileUser.id
  const dispatch = useDispatch()

  const [messages, setMessages] = useState([])

  const profileSnackbar = useSelector(getProfileSnackbar)

  const messagesEndRef = useRef(null)

  useMessages(currentUserId, chatProfileUserId, 0, messagesLimit, setMessages)

  const changeMessagesLimit = (count) => {
    setMessagesLimit(messagesLimit => messagesLimit + count)
  }

  const sendMessage = (textMessage) => {
    Api.users.sendMessage( currentUserId, chatProfileUserId,'text',textMessage,'',null,'').then(({data: data}) => {
      data.error && dispatch(setSnackbar(<MySnackbar message={'Обмен личными контактами запрещен, общение ведется только в рамках этого чата. Если это ошибка - напишите администратору.'}/>))
    })
  }

  const sendImages = (files) => {
    Api.users.sendMessage( currentUserId, chatProfileUserId,'file', '', files,null,'')
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const audio = new Audio('https://audiokaif.ru/wp-content/uploads/2019/04/1-%D0%97%D0%B2%D1%83%D0%BA-%D1%83%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BD%D0%B0-iphone.mp3')

  useEffect(() => {
    const evtSource = new EventSource("https://debug.gbg-study.ru/api/v2/SSE?idUser=1&idInterlocutor=423")

    evtSource.onmessage = function(e) {
      const message = JSON.parse(e.data)
      setMessages(messages => [{...message, getUserId: message.idUserGet, sendUserId: message.idUserSend }, ...messages])
      currentUserId === message.idUserGet && audio.play()
    }
  },[])

  useEffect(scrollToBottom,[messages])

  return (
    <Panel id={id} className='MessagePanel'>
      <DialogHeader chatProfileUser={chatProfileUser} backTo={backTo} actionSheet={actionSheet} />

      <Div className='MessagePanel-btnAdd'>
        <Button stretched size='s' mode='secondary' onClick={() => changeMessagesLimit(20)}>Предыдущие сообщения</Button>
      </Div>

      <div id={'messages'} className='MessagesList'>
        { messages && [...messages].reverse().map( (message, index) => (
          <DialogMessageItem key={index} message={message}  currentUserId={currentUserId} chatProfileUser={chatProfileUser} chatProfileUserId={chatProfileUserId} />))
        }
        <div ref={messagesEndRef} />
      </div>

      {
        ( currentUserId === 1 || currentUserId === 2 || currentUserId === 443 || currentUserId === 418 || currentUserId === 423 || currentUserId === 7792 || currentUserId === 11639 ) &&
            <FixedLayout vertical='bottom'>
              <DialogWriteBar sendMessage={sendMessage} sendImages={sendImages} showModal={showModal} sendBill={sendBill}/>
            </FixedLayout>
      }

      { profileSnackbar }
    </Panel>
  )
}

export default Index
