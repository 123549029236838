const ROLES = {
  USER: 'User',
  CONSULTANT: 'Executor',
  ADMIN: 'Admin',
  USER_ADMIN: 'User/Admin',
  CONSULTANT_ADMIN: 'Executor/Admin',
  ALL: ['User','Executor','Admin','User/Admin','Executor/Admin']
}

export default ROLES
