import React from "react";
import {Panel, Group, Cell, Div, Card, CardScroll, Caption, HorizontalScroll, SimpleCell} from "@vkontakte/vkui";
import VIEWS from "../../panels";
import HeaderPanel from "../../../components/HeaderPanel";
import './Progress.css'
import {useSelector} from "react-redux";
import {getCurrentUserRole, getProfileProgress} from "../../../redux/selectors/profile-selectors";
import formatBigNumber from "../../../lib/formatBigNumber";
import getPointProgress from "../../../lib/getPointProgress";

const Progress = ({id, header, backTo, currentUser}) => {

  const currentUserRole = useSelector(getCurrentUserRole)

  const currentUserAffiliateUsersCount = currentUser.affiliate && currentUser.affiliate.invitedUsers.length
  const currentUserRevenue = currentUser.revenue && currentUser.revenue.value
  const currentUserReviewsCount = currentUser.reviews && currentUser.reviews.length
  const currentUserConsultations = currentUser.consultations
  const currentUserPortfolioCount = currentUser.portfolio && currentUser.portfolio.length

  const counts = [currentUserAffiliateUsersCount, currentUserRevenue, currentUserReviewsCount, currentUserConsultations, currentUserPortfolioCount]

  const progress = useSelector(getProfileProgress)

  const selfProgress = getPointProgress(progress, counts)

  return (
    <Panel id={id}>
      <HeaderPanel panel={VIEWS.PROFILE.PANELS.PROFILE_INFO} backTo={backTo}>
        {header}
      </HeaderPanel>

      { progress.filter(obj => obj.role.includes(currentUserRole)).map((item,index) => (
        <Group key={index} className={'GroupProgress'}>
          <SimpleCell disabled description={<span>{item.description}</span>}>
            {item.label}
          </SimpleCell>
          <HorizontalScroll>
            <Div style={{ display: 'flex' }}>
              { item.points.map((value, i) => (
                <Card key={i} className={'CellProfileProgress'} >
                  <div className={`CellProfileProgress__Images ${selfProgress[item.id - 1].point <= i ? 'Grayscale' : 'Contrast'}`} >
                    <img src={`/img/progress/${item.url}`} />
                    <span className={'CellProfileProgress__Text'}>{formatBigNumber(item.points[i])}</span>
                  </div>
                </Card>
              )) }
            </Div>
          </HorizontalScroll>
            <Div style={{ marginLeft: 12, paddingBottom: 0 }}>
              <Caption level="4" weight="bold" caps>У вас сейчас: {formatBigNumber(counts[item.id - 1]) || 0}</Caption>
            </Div>
        </Group>
      )) }
    </Panel>
  )
}

export default Progress

