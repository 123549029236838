import Api from '../api/api'
import {useEffect} from 'react'
import _ from 'underscore'
import {convertMessages} from "../redux/reducers/chats-reducer";

const useMessages = (currentUserId, chatProfileUserId, offset, limit, setMessages) => {

  useEffect(() => {
    const getByUserDialogs = () => {
      Api.users.getByUserMessages(currentUserId, chatProfileUserId, offset, limit).then((response) => {
        const rMessages = convertMessages(response.data.data)
          setMessages(messages => {
            if (_.isEqual(messages, rMessages)) {
              return messages
            } else {
              return rMessages
            }
          })
      })
    }

    getByUserDialogs()

  }, [limit])

}

export default useMessages