import React  from 'react'
import { FormLayout, Group, ModalPage } from '@vkontakte/vkui'

import CategoryItem from '../../../components/CategoryItem'
import ModalHeader from '../../../components/ModalHeader'
import './ProfileCategories.css'

const Index = ({ id, onClose, categories }) => {

  return (
    <ModalPage id={id} onClose={onClose} header={(<ModalHeader title='Категории' modalBack={onClose} /> )}>
      <FormLayout className='ProfileCategories'>
        <Group>
          {categories.map((category, i) => (
            <CategoryItem key={i} category={category} onSelect={() => {}} />
          ))}
        </Group>
      </FormLayout>
    </ModalPage>
  )
}

export default Index
